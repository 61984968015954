import InformationComponent from '../components';
import { connect } from 'react-redux';



const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = dispatch => ({
    
})

export default connect(mapStateToProps, mapDispatchToProps)(InformationComponent)
