import React from 'react';
import ChosenGift from './ChosenGift';
import PlaceOrder from './PlaceOrder';
import PriceDetails from './PriceDetails';
import profile from '../../../components/Layout/components/images/profile.svg';
import location from '../../../components/Layout/components/images/location-16.svg';
import gift from '../../../components/Layout/components/images/gift.svg';
import add from '../../../components/Layout/components/images/group-2.svg';
import { fetchToken, saveSessionStorage, getSessionStorage } from '../../../helpers';
import { axiosInstance } from '../../../config/axiosInstance';
import { ToastContainer, toast } from 'react-toastify';
import LoadingCircle from '../../../components/Loader';
import { withRouter } from 'react-router-dom';
import { routerNames } from '../../../config/config';
import { pushToDataLayer } from "../../../GS_GTM/gtmFunctions"
import '../../../index.css';
import './index.css';

class Details extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            selectedOccasions : [],
            totalPrice        : 0,
            fetchingData      : false,
            discount          : 0,
            applyDiscount     : false
        }
    }

    componentDidMount(){
        const arr = this.props.history.location.pathname.split('/');
        const pageName = arr[arr.length-1]
        const selectedOccasions = getSessionStorage('selectedOccasions');
        if(selectedOccasions && this.props.is_offer_enabled!==null){
            selectedOccasions.forEach(item => {
                const date = new Date(item.date.date);
                item.date.date = date;
                if(!item.name){
                    this.props.history.push(routerNames.DETAILS)
                }
            })
            this.setState({
                selectedOccasions : selectedOccasions,
            }, () => this.calculateTotalPrice())
        } else {
            if(pageName==='direct-payment'){
                this.paymentDetails();
            } else {
                this.props.history.push(routerNames.SELECT_RELATION)
            }
        }
    }

    paymentDetails = () => {
        this.setState({ fetchingData: true })
        fetchToken("submit",this.getOrderDetailAPI)
    }

    getOrderDetailAPI = () => {
        const params = new URLSearchParams(this.props.location.search)
        const id = params.get('id')
        const hash = params.get('hash')
        this.setState({ fetchingData: true })
        
        axiosInstance.post("orderDetail", {id: id, hash_key: hash, order_type: '1'}, {
            headers: {
                'Auth': localStorage.getItem('Token'),
                'Content-Type':'application/json'
            }
        })
        .then(res => {
            if(res.data && res.data.data){
                const data = []
                res.data.data.forEach((item,index) => {
                    let obj = {
                        relative : { id: index, relation: item.relation.split('-')[1] },
                        date : { date : new Date(item.date), status: true },
                        name: item.name,
                        occasion: item.occasion,
                        gift: item.gift,
                        order_id: item.order_id
                    }
                    if(item.address){
                        obj.address = { status: true, address : item.address}
                    }
                    if(item.message){
                        obj.message = { message: item.message, status: true }
                    }
                    data.push(obj)
                })
                saveSessionStorage('userInfo', { id: id, user_email: res.data.user_email })
                saveSessionStorage('selectedOccasions', data)
                this.setState({ 
                    selectedOccasions : data, 
                    fetchingData : false,
                    applyDiscount : res.data.is_offer_enabled
                }, () => this.calculateTotalPrice())
            } else {
                this.setState({ fetchingData: false })
                toast("Oops Something went wrong, Please try again");
            }
        })
        .catch(e => {
            this.setState({ fetching: false })
            toast("Oops Something went wrong, Please try again");
        })
    }

    calculateTotalPrice = () => {
        let total = 0;
        let discount = Number.MAX_SAFE_INTEGER;
        let allGiftsAdded = true;
        this.state.selectedOccasions.forEach(item => {
            if(item.gift){
                let giftPrice = 0;
                if(item.weightOfCake && item.gift.type==='cake' && item.gift.attributes && item.gift.attributes['10']){
                    //attribute id 10 is for weight of cake options
                    Object.keys(item.gift.attributes['10'].options).forEach(id => {
                        if(item.gift.attributes['10'].options[id].name===item.weightOfCake){
                            giftPrice = item.gift.attributes['10'].options[id].price
                        }
                    })
                } else { giftPrice = item.gift.price }
                

                
                giftPrice =  parseFloat(giftPrice);
                if(item.doubleFlowers || item.gift.doubleFlowers){
                    giftPrice = giftPrice + parseFloat(item.doubleFlowers || item.gift.doubleFlowers);
                }
                if(item.addVase || item.gift.addVase){
                    giftPrice = giftPrice + parseFloat(item.addVase || item.gift.addVase);
                }
                if(item.eggless || item.gift.eggless){
                    giftPrice = giftPrice + parseFloat(item.eggless || item.gift.eggless);
                }
                if(item.addFlowers || item.gift.addFlowers){
                    giftPrice = giftPrice + parseFloat(item.addFlowers || item.gift.addFlowers);
                }
                discount = discount > giftPrice ? giftPrice : discount; 
                total = total + giftPrice;
            } else {
                allGiftsAdded = false
            }
        })
        this.setState({ totalPrice : total, discount : allGiftsAdded && this.state.selectedOccasions.length > 3 && (this.props.is_offer_enabled || this.state.applyDiscount) ? discount : 0 },
            () => { saveSessionStorage('selectedOccasions',this.state.selectedOccasions)}
        )
    }

    deleteGift = (index) => {
        let arr = [...this.state.selectedOccasions];
        let item = Object.assign({},arr[index]) 
        delete item.gift;
        delete item.addVase;
        delete item.addGreetings;
        delete item.doubleFlowers;
        delete item.eggless;
        delete item.messageOnCake;
        delete item.weightOfCake;
        delete item.addFlowers;
        arr[index] = item
        this.setState({
            selectedOccasions : arr
        }, () => this.calculateTotalPrice())
    }

    chooseGift = (item) => {
        saveSessionStorage('chosenRelative',item)
        pushToDataLayer('OnChooseGift')
        this.props.history.push(routerNames.MENU,{
            selectedOccasions : this.state.selectedOccasions,
            chosenRelative : item
        })
    }

    addGreetings = (e,index) => {
        const arr = [...this.state.selectedOccasions];
        let item = Object.assign({}, arr[index])
        let total = this.state.totalPrice;
        if(e.target.checked){
            item['addGreetings'] = true;
            total = total + 99;
        }
        else{
            item['addGreetings'] = false;
            total = total - 99;
        }
        arr[index] = item
        this.setState({
            selectedOccasions : arr,
            totalPrice : total
        }, () => {
            saveSessionStorage('selectedOccasions',this.state.selectedOccasions)
        })
    }

    viewSummary = (id) => {
        const element = document.getElementById(id)
        element.scrollIntoView({behavior: "smooth", block: "end", inline: "end"});
    }

    handlePlaceOrder = () => {
        const arr = [...this.state.selectedOccasions]
        const user = getSessionStorage('userInfo');
        const arr1 = []
        arr.forEach(item => {
            arr1.push(item.gift.nid)
        })

        const data = {
            step       : 5,
            id         : user.id,
            user_email : user.user_email,
            data       : arr1,
            total      : this.state.totalPrice - this.state.discount
        }

        this.setState({ fetchingData: true })

        axiosInstance.post(`checkCartData`, data, {
            headers: {
                'Auth'         : localStorage.getItem('Token'),
                'Content-Type' :'application/json'
            }
        })
        .then(res => {
            this.setState({ fetchingData: false })
            if(res.data.statusCode===200 && res.data.data){
                pushToDataLayer('OnPlaceOrderContinue')
                window.location.replace(res.data.data)
            } else if(res.data.statusCode===401){
                fetchToken("submit",this.handlePlaceOrder)
            } else {
                toast("Oops Something went wrong, Please try again");
            }
        })
        .catch( e => { toast("Could not process request, Please try again") })
    }

    render(){
        const arr = this.props.history.location.pathname.split('/');
        const pageName = arr[arr.length-1]
        let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        let allGiftsAdded = true
        const details = this.state.selectedOccasions.map((item,index) => {
            const date = new Date(item.date.date);
            if(!item.gift){
                allGiftsAdded = false;
            }
            return(
                <div className='border-top-bottom container pb-4 pt-4' key={item.relative.relation+'_'+item.relative.id}>
                    <div className='font-weight-bold'>{item.relative.relation}</div>
                    <div className='align-items-center d-flex pb-15 pt-15'>
                        <img className='img-size' src={profile} alt='profile'/>
                        <span className='ml-3 mt-1'>{item.name}</span>
                    </div>
                    <div className='align-items-center d-flex pb-15 pt-15'>
                        <img className='img-size' src={gift} alt='gift'/>
                        <span className='ml-3 mt-1'>{item.occasion} : <span className='font-weight-bold'>{date.getDate()} {months[date.getMonth()]}, {date.getFullYear()}</span></span>
                    </div>
                    {item.address && item.address.status &&
                        <div className='align-items-center d-flex pb-15 pt-15'>
                            <img className='img-size' src={location} alt='gift'/>
                            <span className='ml-3 mt-1'>{item.address.address.streetAddress}, {item.address.address.city}, {item.address.address.pincode}</span>
                        </div>
                    }
                    {
                        item.gift && item.gift.title ? 
                            <ChosenGift
                                item={item}
                                index={index}
                                deleteGift={this.deleteGift}
                                addGreetings={this.addGreetings}
                                checked={item.addGreetings}
                                pageName={pageName}
                            /> : 
                            <div className='align-items-center d-flex pb-15 pt-15'>
                                <img className='img-size' src={add} alt='add'/>
                                <span onClick={() => this.chooseGift(item)} className='ml-3 mt-1 font-weight-bold color-blue'>Choose Gift</span>
                            </div>
                    }
                </div>
            )
        })
        
        return(
            <React.Fragment>
                <ToastContainer />
                <LoadingCircle load={this.state.fetchingData}/>
                {details}
                {this.state.totalPrice > 0 && 
                    <PriceDetails
                        id = "price-details"
                        selectedOccasions={this.state.selectedOccasions}
                        totalPrice={this.state.totalPrice}
                        allGiftsAdded={allGiftsAdded}
                        discount={this.state.discount}
                        is_offer_enabled={this.props.is_offer_enabled || this.state.applyDiscount}
                    />}
                {this.state.totalPrice > 0 && 
                    <PlaceOrder
                        selectedOccasions={this.state.selectedOccasions}
                        totalPrice={this.state.totalPrice}
                        handlePlaceOrder={this.handlePlaceOrder}
                        viewSummary={this.viewSummary}
                        allGiftsAdded={allGiftsAdded}
                        discount={this.state.discount}
                        is_offer_enabled={this.props.is_offer_enabled || this.state.applyDiscount}
                    />}
            </React.Fragment>
        )
    }
}

export default (withRouter(Details));