import React from 'react';
import rectangle from '../../../components/Layout/components/images/rectangle.svg';
import cake from '../../../components/Layout/components/images/group-16.png';
import bin from '../../../components/Layout/components/images/delete-button.svg';
import '../../../index.css';
import './index.css';

class ChosenGift extends React.Component {
    render(){
        const item = this.props.item
        const gift = this.props.item.gift
        let gift_cost = ''
        let price = '';
        const image = this.props.item.gift.fullSizeImages ? this.props.item.gift.fullSizeImages[0] : this.props.item.gift.image
        if(gift.type==='cake' && gift.attributes && gift.attributes['10'] && item.weightOfCake){
            
            //attribute id '10' gives the list of available weights for cakes

            Object.keys(gift.attributes['10'].options).forEach(id => {
                if(gift.attributes['10'].options[id].name===item.weightOfCake){
                    price = gift_cost = parseFloat(gift.attributes['10'].options[id].price)
                }
            })
        } else {
            price = gift_cost = parseFloat(gift.price)
        }
        if(item.doubleFlowers || item.gift.doubleFlowers){
            const addCost = item.doubleFlowers ? item.doubleFlowers : item.gift.doubleFlowers
            price = price + parseFloat(addCost);
        }
        if(item.addVase || item.gift.addVase){
            const addCost = item.addVase ? item.addVase : item.gift.addVase
            price = price + parseFloat(addCost);
        }
        if(item.eggless || item.gift.eggless){
            const addCost = item.eggless ? item.eggless : item.gift.eggless
            price = price + parseFloat(addCost);
        }
        if(item.addGreetings || item.gift.addGreetings){
            price = price + 99;
        }
        if(item.addFlowers || item.gift.addFlowers){
            const addCost = item.addFlowers ? item.addFlowers : item.gift.addFlowers
            price = price + parseFloat(addCost);
        }
        return(
            <div>
                <div className='d-flex pt-4 pb-4 border-gift'>
                    <div>
                        <img className='image-size' src={image} alt='cake'/>
                    </div>
                    <div className='pl-4 w-75'>
                        <div className='d-flex justify-content-between'>
                            <span>{this.props.item.gift.title}</span>
                            {this.props.pageName==='delivery-details' && <span><img onClick={() => this.props.deleteGift(this.props.index)} src={bin} alt='delete'/></span>}
                        </div>
                        <div className='gift-price pt-1 pb-2'>Rs. {parseFloat(gift_cost)}</div>
                        {/* <div className='pt-1'>
                            <label className="container-check">Add greeting @ <strong>99</strong> 
                                <input checked={this.props.checked} onChange={(e) => this.props.addGreetings(e,this.props.index)} type="checkbox" name='doublePrice' />
                                <span className="checkmark"></span>
                            </label>
                        </div> */}
                    </div>
                </div>
                <div className='justify-content-between pt-3'>
                    {item.gift.type === 'cake' && 
                        <div>
                            <img src={rectangle} alt='rectangle'/>
                            <span className='pl-2'>Weight - {item.weightOfCake || item.gift.weightOfCake}</span>
                        </div>
                    }
                    {(item.doubleFlowers > 0 || item.gift.doubleFlowers > 0) && 
                        <div>
                            <img src={rectangle} alt='rectangle'/>
                            <span className='pl-2'>Double the flowers @ Rs {parseFloat(item.doubleFlowers || item.gift.doubleFlowers)}</span>
                        </div>
                    }
                    {(item.addVase > 0 || item.gift.addVase > 0) && 
                        <div>
                            <img src={rectangle} alt='rectangle'/>
                            <span className='pl-2'>Add vase @ Rs {parseFloat(item.addVase || item.gift.addVase)}</span>
                        </div>
                    }
                    {(item.eggless > 0 || item.gift.eggless > 0) && 
                        <div>
                            <img src={rectangle} alt='rectangle'/>
                            <span className='pl-2'>Make it eggless @ Rs {parseFloat(item.eggless || item.gift.eggless)}</span>
                        </div>
                    }
                    {(item.addFlowers > 0 || item.gift.addFlowers > 0) && 
                        <div>
                            <img src={rectangle} alt='rectangle'/>
                            <span className='pl-2'>Add 10 Roses @ Rs {parseFloat(item.addFlowers || item.gift.addFlowers)}</span>
                        </div>
                    }
                    { item.gift.type === 'cake' && ((item.messageOnCake && item.messageOnCake !== '') || item.gift.messageOnCake && item.gift.messageOnCake!=="") && 
                        <div>
                            <img src={rectangle} alt='rectangle'/>
                            <span className='pl-2 message-on-cake'>"{item.messageOnCake || item.gift.messageOnCake}"</span>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default ChosenGift;