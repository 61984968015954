import React, { Component } from 'react';
import Layout from '../../../components/Layout/container';
import UserForm from './UserForm';
import Headline from './Headline';
import './index.css';
import { withRouter } from 'react-router-dom';
import { routerNames } from '../../../config/config';

class Information extends Component {

    render() {
        return (
            <div className='parent-div'>
                <Layout page={routerNames.OCCASION_LIST} serenade={true}/>
                <Headline 
                    title='Schedule your celebration this Valentine week'
                    subtitle='Please add Your details'
                />
                <UserForm/>
            </div>
        )
    }
}

export default withRouter(Information)
