import React from 'react';
import { isEmpty, ucFirst, fetchToken, saveSessionStorage, getSessionStorage, removeSessionStorage } from '../../../helpers';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';
import '../../../index.css';
import { axiosInstance, axiosInstanceGetCity } from '../../../config/axiosInstance';
import { Field, reduxForm } from 'redux-form';
import { MaterialTextField } from '../../../components/RenderField';
import Modal from 'react-modal';
import SlidingPane from 'react-sliding-pane';
import OrderStatus from './OrderStatus';
import { ToastContainer, toast } from 'react-toastify';
import LoadingCircle from '../../../components/Loader';
import 'react-toastify/dist/ReactToastify.min.css';
import { routerNames } from '../../../config/config';
import error from '../../../components/Layout/components/images/error.svg';
import { pushToDataLayer } from "../../../GS_GTM/gtmFunctions";

class OrderedProducts extends React.Component {
    state = {
        occasionList : [],
        fetching: false,
        isMessageAdded : false,
        showModal: false,
        modalForIndex: null,
        fields : {
            streetAddress: "",
            pincode: "",
            city: "",
            state: "",
            deliveryCharges: 0
        },
        sameAddressForAllChecked: false,
        total: '',
        status_id: '21',
        disableAddressCheck: false,
        isPincodeValid: true
    }

    componentDidMount(){
        Modal.setAppElement(this.ele);
        if(this.props.history.location.search!==""){
            this.updatePaymentStatus();
        } else {
            this.getOrderDetail();
        }
    }

    getOrderDetail = () => {
        this.setState({ fetching: true })
        fetchToken("submit",this.getOrderDetailAPI)
    }

    getOrderDetailAPI = () => {
        const pathname = this.props.history.location.pathname.split('/')
        const id = pathname[pathname.length-2]
        saveSessionStorage('userInfoSerenade', { id: id })
        const code = pathname[pathname.length-1]
        this.setState({ fetching: true })
        
        axiosInstance.post("orderDetail", {id: id, hash_key: code}, {
            headers: {
                'Auth': localStorage.getItem('Token'),
                'Content-Type':'application/json'
            }
        })
        .then(res => {
            if(res.data && res.data.data){
                const data = []
                res.data.data.forEach((item,index) => {
                    let obj = {
                        date : item.date,
                        name: item.name,
                        occasion: item.occasion,
                        gift: item.gift,
                        order_id: item.order_id,
                        mobile: item.mobile,
                        image: item.image
                    }
                    if(item.address){
                        obj.address = item.address
                    }
                    if(item.message){
                        obj.message = item.message
                    }
                    data.push(obj)
                })
                pushToDataLayer('OnNextSerenade',{},{},{
                    event: "clickEvent",
                    eventCategory: "Serenade",
                    eventAction: "Order Confirmation",
                    eventLabel: "Success"
                })
                this.setState({ 
                    occasionList : data, 
                    total: res.data.total, 
                    status_id : 21,
                    fetching : false
                })
            } else {
                this.setState({ fetching: false })
                toast("Oops Something went wrong, Please try again");
            }
        })
        .catch(e => {
            this.setState({ fetching: false })
            toast("Oops Something went wrong, Please try again");
        })
    }

    handleAddressModal = () => {
        this.setState({ 
            showModal: 'address',
            sameAddressForAllChecked: true, 
            disableAddressCheck: true 
        })
    }

    sameAddressForAll = (e) => {
        this.setState ({ sameAddressForAllChecked : e.target.checked})
    }

    removeModal = (key) => {
        this.setState({ showModal: false, sameAddressForAllChecked: false, disableAddressCheck: false })
    }

    handleAddressChange = (e,field) => {
        const { fields } = this.state
        fields[field] = e.target.value
        if(field==='pincode' && e.target.value.length===6){
            this.checkPincode(e.target.value)
        }
        this.setState({ fields })
    }

    checkPincode = (value) => {
        this.setState({ fetchingData: true })
        axiosInstanceGetCity.get(``, {
            params : {
                pincode : value,
                all_c : 0,
                country_id: 0
            }
        })
        .then(res => {
            this.setState({ fetchingData: false })
            const { fields } = this.state;
            if(res.data && res.data.delivery_status){
                fields['city'] = res.data.city;
                fields['state'] = res.data.state;
                fields['deliveryCharges'] = res.data.cost
                this.setState({ fields: fields, isPincodeValid: true }, () => this.initializeForm(this.state.fields.streetAddress,value,fields['city'],fields['state']));
            } else {
                fields['city'] = '';
                fields['state'] = '';
                fields['deliveryCharges'] = 0
                this.setState({ fields: fields, isPincodeValid: false }, () => {
                    this.initializeForm(this.state.fields.streetAddress,value,fields['city'],fields['state']);
                    //toast("Sorry, We do not deliver in this area");
                })
            }
        })
        .catch( e => { toast("Could not process request, Please try again") })
    }

    initializeForm = (streetAddress,pincode,city,state) => {
        const fields = {
            streetAddress : streetAddress,
            pincode     : pincode,
            city        : city,
            state       : state
        }
        this.props.initialize(fields);
    }

    handlePersonalMessage = (e) => {
        let occasionList = [...this.state.occasionList]
        let ele = Object.assign({},occasionList[this.state.modalForIndex])
        ele.message = e.target.value
        occasionList[this.state.modalForIndex] = ele
        this.setState({ occasionList })
    }

    savePersonalMessage = () => {
        let occasionList = [...this.state.occasionList]
        if(occasionList[this.state.modalForIndex].message && occasionList[this.state.modalForIndex].message!==''){
            const user = getSessionStorage('userInfoSerenade');
            if(!user){
                this.props.history.push(routerNames.USER_INFO_SERENADE)
            } else {
                this.handleSubmit()
            }
        } else {
            this.setState({ showModal: false })
        }
    }

    removePersonalMessage = (index) => {
        let occasionList = [...this.state.occasionList]
        const user = getSessionStorage('userInfoSerenade');
        if(!user){
            this.props.history.push(routerNames.USER_INFO_SERENADE)
        } else {
            let ele = Object.assign({},occasionList[index])
            delete ele.message
            occasionList[index] = ele
            saveSessionStorage('occasionList',occasionList)
            this.setState({ occasionList })
            
            this.handleSubmit()
        }
    }

    handleSubmit = () => {
        let arr = []
        const user = getSessionStorage('userInfoSerenade');
        this.state.occasionList.forEach((item) => {
            let json = {
                address  : item.address,
                message  : item.message,
                order_id : item.order_id,
            }
            arr.push(json)
        })

        const data = {
            step              : 6,
            id                : user.id,
            data              : arr,
            order_type        : '2'
        }

        this.setState({ fetching: true })
        axiosInstance.post(`updateAddress`, data, {
            headers: {
                'Auth': localStorage.getItem('Token'),
                'Content-Type':'application/json'
            }
        })
        .then(res => {
            this.setState({ fetching: false })
            if(res.data.statusCode===200){
                toast("Update is successful. Thank you for your order.");
                this.setState({ showModal: false })
                //{ onClose: () => this.props.history.push(routerNames.HOME) }
            } else if(res.data.statusCode===400) {
                this.setState({ fetching: false })
                toast("Order already processed. Please contact customer care.");
            } else if(res.data.statusCode==401){
                fetchToken("submit",this.handleSubmit)
            } else {
                this.setState({ fetching: false })
                toast("Oops Something went wrong, Please try again");
            }
        })
        .catch( e => { toast("Could not process request, Please try again") })
    }

    handleRetry = () => {
        const arr = [...this.state.occasionList]
        const user = getSessionStorage('userInfoSerenade');
        const arr1 = []
        arr.forEach(item => {
            arr1.push(item.gift.nid)
        })

        const data = {
            step       : 5,
            id         : user.id,
            user_email : user.user_email,
            data       : arr1,
            total      : this.state.total,
            order_type : '2'
        }

        this.setState({ fetching: true })

        axiosInstance.post(`checkCartData`, data, {
            headers: {
                'Auth': localStorage.getItem('Token'),
                'Content-Type':'application/json'
            }
        })
        .then(res => {
            this.setState({ fetching: false })
            if(res.data && res.data.data){
                window.location.replace(res.data.data)
            } else {
                toast("Oops Something went wrong, Please try again");
            }
        })
        .catch( e => { toast("Could not process request, Please try again") })
    }

    saveAddress = () => {
        let occasionList = [...this.state.occasionList]
        const user = getSessionStorage('userInfoSerenade');
        if(!user){
            this.props.history.push(routerNames.USER_INFO_SERENADE)
        } else {
            if(this.state.sameAddressForAllChecked){
                occasionList.forEach((item,i) => {
                    let ele = Object.assign({},occasionList[i])
                    ele.address = this.state.fields
                    ele.address.mobile = item.mobile
                    occasionList[i] = ele
                })
            } else {
                let ele1 = Object.assign({},occasionList[this.state.modalForIndex])
                ele1.address = this.state.fields
                ele1.address.mobile = ele1.mobile
                occasionList[this.state.modalForIndex] = ele1
            }

            this.setState( { occasionList }, () => this.handleSubmit() )
        }
    }

    updatePaymentStatus = () => {
        const orderID = this.props.match.params.orderId        
        const userInfo = getSessionStorage('userInfoSerenade');
        if(userInfo) {
            const params = new URLSearchParams(this.props.location.search)
            let status_id = params.get('status_id') || 0;
            let order_id = orderID;
            let transactionId = params.get('order_id') || 0;
            const data = {
                status_id : status_id,
                id : order_id,
                transactionId: transactionId,
                user_email: userInfo.user_email,
                order_type: '2'
            }

            this.setState({ fetching: true })
            
            axiosInstance.post(`updatePaymentStatus`, data, {
                headers: {
                    'Auth': localStorage.getItem('Token'),
                    'Content-Type':'application/json'
                }
            })
            .then(res => {
                this.setState({ fetching: false })
                if(res.data && res.data.data){
                    const data = []
                    res.data.data.forEach((item,index) => {
                        let obj = {
                            date : item.date,
                            name: item.name,
                            occasion: item.occasion,
                            gift: item.gift,
                            order_id: item.order_id,
                            mobile: item.mobile,
                            image: item.image,
                        }
                        if(item.address){
                            obj.address = item.address
                        }
                        if(item.message){
                            obj.message = item.message
                        }
                        data.push(obj)
                    })
                    this.setState({ 
                        occasionList : data, 
                        total: res.data.total, 
                        status_id : status_id
                    })
                    removeSessionStorage('occasionList')
                } else if(res.data.statusCode==401){
                    fetchToken("paymentDone")
                } else {
                    toast("Oops Something went wrong, Please try again");
                }
            })
            .catch( e => { toast("Could not process request, Please try again") })
        } else {
            this.props.history.push(routerNames.USER_INFO_SERENADE)
        }
    }

    render(){
        const pathname = this.props.history.location.pathname.split('/')
        const orderNumber = pathname[pathname.length-1]
        const list = this.state.occasionList.map((item,index) => {
            return(
                <div key={item.occasion+index} className='m-3 delivery'>
                    <div className='align-items-center d-flex justify-content-between p-10 border-bottom-gift'>
                        <p className='occasion-detail'><img src={item.image} alt='im-g'/> {item.occasion} ( {item.date.split('-')[2]}th Feb {item.date.split('-')[0]} )</p>
                    </div>
                    <div className='border-bottom-gift'>
                        <p className='align-items-center d-flex justify-content-between m-0 p-10 pb-0'>
                            <span className='name'>{item.name}</span>
                            {/* {item.address && this.state.status_id==='21' && 
                                <span 
                                    onClick={() => {
                                        this.setState({ 
                                            showModal: 'address', 
                                            modalForIndex: index, 
                                            sameAddressForAllChecked: false, 
                                            disableAddressCheck: false,
                                            fields: item.address
                                        }, () => this.initializeForm(item.address.streetAddress,item.address.pincode,item.address.city,item.address.state))}
                                    } 
                                    className='edit'>EDIT ADDRESS</span>
                                } */}
                        </p>
                        <p className='m-0 pl-2 mobile-gift'>{item.mobile}</p>
                        {item.address && <div className='address p-10'>{item.address.streetAddress}, {item.address.city}, {item.address.state} {item.address.pincode}</div>}
                        {/* {(!item.message && this.state.status_id==='21') && <div onClick={() => this.setState({ showModal: 'message', modalForIndex: index })} className='edit m-2 p-2 text-center personal-message'>ADD PERSONAL MESSAGE</div>} */}
                        {item.message && 
                            <div className='m-2 personal-message'>
                                <div className='p-2 font-size-13'>
                                    <p>{item.message}</p>
                                    {/* <p className='m-0 edit'>
                                        <span onClick={() => this.setState({ showModal: 'message', modalForIndex: index })}>EDIT</span>
                                        <span onClick={() => this.removePersonalMessage(index)} className='ml-3'>REMOVE</span>
                                    </p> */}
                                </div>
                            </div>
                        }
                    </div>
                    <div className='d-flex pt-4 pb-4 border-gift pl-2 pr-2'>
                        <div>
                            <img className='image-size' src={item.gift.image || (item.gift.fullSizeImages && item.gift.fullSizeImages[0])} alt='cake'/>
                        </div>
                        <div className='pl-4 w-75'>
                            <div className='d-flex justify-content-between'>
                                <span className='name'>{item.gift.title}</span>
                            </div>
                            <div className='gift-price pt-1 name'>Rs {parseFloat(item.gift.price)}</div>
                            {item.gift.type==='cake' && <div className='font-12'>{item.weightOfCake || item.gift.weightOfCake}</div>}
                            {/* <div className='pt-1'>
                                <label className="container-check name">Add greeting @ <strong>99</strong> 
                                    <input type="checkbox" name='add99' />
                                    <span className="checkmark"></span>
                                </label>
                            </div> */}
                        </div>
                    </div>
                    <div className='d-flex flex-wrap justify-content-between p-10'>
                        {item.gift.doubleFlowers > 0 && <p className='attributes1 m-0'>&#9679;&nbsp;Double the flowers @ Rs {parseFloat(item.gift.doubleFlowers)}</p>}
                        {item.gift.eggless > 0 && <p className='attributes2 m-0'>&#9679;&nbsp;Eggless Cake @ Rs {parseFloat(item.gift.eggless)}</p>}
                        {item.gift.addVase > 0 && <p className='attributes2 m-0'>&#9679;&nbsp;Add Vase @ Rs {parseFloat(item.gift.addVase)}</p>}
                        {item.gift.addFlowers > 0 && <p className='attributes2 m-0'>&#9679;&nbsp;Add Flowers @ Rs {parseFloat(item.gift.addFlowers)}</p>}
                        {item.address && item.address.deliveryCharges > 0 && <p className='attributes1 m-0'>&#9679;&nbsp;Delivery Charges @Rs {parseFloat(item.address.deliveryCharges)}</p>}
                    </div>
                </div>
            )
        })
        return(
            <div className={this.state.status_id !== '21' ? 'order-placed-parent' : ""}>
                <LoadingCircle load={this.state.fetching}/>
                <ToastContainer />
                {this.props.history.location.search!=="" && <OrderStatus occasionList={this.state.occasionList} handleAddressModal={this.handleAddressModal} {...this.props}/>}
                <p className='font-weight-bold pl-3 font-14'>Order Number : {"GS-"+orderNumber}</p>
                {list}
                <SlidingPane
                    isOpen={this.state.showModal!==false}
                    from='bottom'
                    className='slide-pane'
                    ariaHideApp={false}
                >
                    {this.state.showModal==='message' && 
                        <MessageBox 
                            item={this.state.occasionList[this.state.modalForIndex]}
                            removeModal={this.removeModal} 
                            savePersonalMessage={this.savePersonalMessage} 
                            handlePersonalMessage={this.handlePersonalMessage}
                    />}
                    {this.state.showModal==='address' && 
                        <AddressBox 
                            handleSubmit={this.props.handleSubmit} 
                            sameAddressForAll={this.sameAddressForAll} 
                            handleAddressChange={this.handleAddressChange} 
                            saveAddress={this.saveAddress} 
                            fields={this.state.fields} 
                            removeModal={this.removeModal}
                            length={this.state.occasionList.length}
                            sameAddressForAllChecked={this.state.sameAddressForAllChecked}
                            disableAddressCheck={this.state.disableAddressCheck}
                            isPincodeValid={this.state.isPincodeValid}
                    />}
                </SlidingPane>
                {this.state.status_id !== '21' && <div id='bottom-btn' className='h-100 position-relative m-auto w-80'>
                    <button onClick={() => this.handleRetry()} className='btn-next w-100'>Retry</button>
                </div>}
            </div>
        )
    }
}

const MessageBox = (props) => {
    const specialFormat2 = /[#@*\[\]\\|<>\/]/;
    const emojiFormat = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
    const disableBtn = specialFormat2.test(props.item.message) || emojiFormat.test(props.item.message)
    return(
        <div className='confirm-delete padding-message-box'>
            <div className='align-items-center d-flex justify-content-between pb-3'>
                <p className='m-0'>Personal Message</p>
                <img onClick={() => props.removeModal('message')} src={error} alt='close'/>
            </div>
            <div className='align-items-center d-flex flex-column justify-content-between'>
                <div className='position-relative'>
                    <textarea 
                        id='serenade-gift-message' 
                        className='message-text font-size-13' 
                        cols='30' 
                        rows='4' 
                        maxLength='250'
                        placeholder='Type your message...'
                        onChange={props.handlePersonalMessage}
                        value={props.item.message}
                    />
                    <span className='count-char'>{(props.item.message && props.item.message.length) || 0}/250</span>
                </div>
                {disableBtn && <span className='errors pt-20'>Message can't have special characters</span>}
                <button disabled={disableBtn} onClick={props.savePersonalMessage} className='delete-modal-btn cancel-modal-btn padding-big'>SAVE</button>
            </div>
        </div>
    )
}

const AddressBox = (props) => {
    const { streetAddress, pincode, city, state} = props.fields
    const { handleSubmit } = props;
    return(
        <div className="show-address-box left pt-2-">
            <div className='align-items-center d-flex justify-content-between pb-3'>
                {props.length > 1 && 
                    <div className='pt-1'>
                        <label className="container-check name m-0">Same for all events 
                            <input disabled={props.disableAddressCheck} checked={props.sameAddressForAllChecked} onChange={props.sameAddressForAll} type="checkbox" name='doublePrice' />
                            <span className="checkmark"></span>
                        </label>
                    </div>}
                <img className={props.length <=1 ? 'ml-auto' : ''} onClick={() => props.removeModal('address')} src={error} alt='close'/>
            </div>
            <p className='border-0 p-0 summary-heading'>Address</p>
            <form className='pt-20' onSubmit={handleSubmit(props.saveAddress)}>
                <div className="field-wrap-address fullname-wrap">
                    <div className="form-group address-box-form">
                        <Field
                            name='streetAddress'
                            label='Delivery Address'
                            labelClassName={classnames({
                                'form-label': true,
                                'input-active': !isEmpty(streetAddress) ? true : false
                            })}
                            id="streetAddress-field"
                            type='text'
                            onChange={e => props.handleAddressChange(e,'streetAddress')}
                            className="form-input serenade-form-address form-input-serenade"
                            component={MaterialTextField}
                        />
                    </div>
                </div>
                <div className="field-wrap-address mobile-wrap mobile">
                    <div className="form-group address-box-form">
                        <Field
                            name='pincode'
                            label='Pincode'
                            labelClassName={classnames({
                                'form-label': true,
                                'input-active': !isEmpty(pincode) ? true : false
                            })}
                            id="pincode-field"
                            type='number'
                            onChange={e => props.handleAddressChange(e,'pincode')}
                            className="form-input serenade-form-address form-input-serenade"
                            component={MaterialTextField}
                        />
                         {!props.isPincodeValid && <span className='wrong-pincode'><sup>*</sup>Sorry, We do not deliver in this area.</span>}
                    </div>
                </div>
                <div className="field-wrap-address mobile-wrap mobile">
                    <div className="form-group address-box-form">
                        <Field
                            name='city'
                            label='City'
                            labelClassName={classnames({
                                'form-label': true,
                                'input-active': !isEmpty(city) ? true : false
                            })}
                            id="city-field"
                            type='text'
                            onChange={e => props.handleAddressChange(e,'city')}
                            className="form-input serenade-form-address form-input-serenade"
                            component={MaterialTextField}
                        />
                    </div>
                </div>
                <div className="field-wrap-address mobile-wrap mobile">
                    <div className="form-group address-box-form">
                        <Field
                            name='state'
                            label='State'
                            labelClassName={classnames({
                                'form-label': true,
                                'input-active': !isEmpty(state) ? true : false
                            })}
                            id="state-field"
                            type='text'
                            onChange={e => props.handleAddressChange(e,'state')}
                            className="form-input serenade-form-address form-input-serenade"
                            component={MaterialTextField}
                        />
                    </div>
                </div>
                <input type='submit' className='delete-modal-btn cancel-modal-btn padding-big save-address-btn' name="Next" value="Next"/>
            </form>
        </div>
    )
}

const validate = values => {
    const specialFormat = /[\[\]\\|<>]/;
    const specialFormat2 = /[#@*\[\]\\|<>\/]/;
    const emojiFormat = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;

    const errors = {};
    const fields = ['streetAddress', 'pincode', 'city', 'state'];
    fields.forEach(field => {
        if (!values[field]) {
            errors[field] = `${ucFirst(field)} is required`;
        }
    })

    if(values['pincode'] && (values['pincode'].length > 6 || values['pincode'].length < 6)){
        errors['pincode'] = `${ucFirst('pincode')} number is not valid`
    }

    if(specialFormat2.test(values['pincode'])){
        errors['pincode'] = `${ucFirst('pincode')} can't have special characters`
    }

    if(specialFormat.test(values['streetAddress'])){
        errors['streetAddress'] = `${ucFirst('streetAddress')} can't have special characters`
    }

    if(emojiFormat.test(values['streetAddress'])){
        errors['streetAddress'] = `${ucFirst('streetAddress')} can't have emojis`
    }

    return errors;
}

export default (reduxForm({
    form: 'AddressBoxForm',
    validate
  })(withRouter(OrderedProducts)));
