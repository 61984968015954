import React from 'react';
import {WithOutClass} from '../helpers/index';
import './Loader.css';
const Loader = (props) => {
  return (
    <WithOutClass>
    {
      props.load === true ? (
        <div className="checkout-loader">
          <span></span>
        </div>
      ) :
      null
    }
    </WithOutClass>
  )
}
export default Loader;
