import React from 'react';
import Layout from '../../../components/Layout/container';
import OrderedProducts from './OrderedProducts';
import { withRouter } from 'react-router-dom';

class OrderPlaced extends React.Component {
    render(){
        return(
            <div className='background-pink pb-2'>
                <Layout serenade={true}/>
                <OrderedProducts/>
            </div>
        )
    }
}

export default withRouter(OrderPlaced)