import React from 'react';
import classnames from 'classnames';

export const MaterialTextField = (props) => {
    let {input, id, className, label, labelClassName, type, meta: { touched, error, warning }} = props;
    if (touched && error) {
      className = classnames({
          [className] : true,
          'error-border': true
      })
    }
    return (
        <React.Fragment>
            <input disabled={label==='City' || label==='State'} id={id} {...input}  type={type} className={className} />
            <label className={labelClassName} htmlFor={id} className={labelClassName}>{label}<span className="required">*</span></label>
            {touched ? (error ? <span className='errors'>{error}</span> : null)  : null} 
        </React.Fragment>
    );
}


// Normal fields
export const TextField = ({input, label, type, meta: { touched, error, warning }}) => (
  <div>
    <label>{label}</label>
    <div>
      <input {...input} placeholder={label} type={type} />
      {touched && ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}
    </div>
  </div>
)
