import React from 'react';
import classnames from 'classnames';
import { fetchToken, isEmpty, ucFirst, saveSessionStorage, getSessionStorage } from '../../../helpers';
import { Field, reduxForm } from 'redux-form';
import { MaterialTextField } from '../../../components/RenderField';
import { withRouter } from 'react-router-dom';
import { routerNames } from '../../../config/config';
import './index.css';
import '../../../index.css';
import { axiosInstance, axiosInstanceGetCity } from '../../../config/axiosInstance';
import LoadingCircle from '../../../components/Loader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { pushToDataLayer } from "../../../GS_GTM/gtmFunctions";

const formFields = []
const specialFormat = /[!$%^*#()_+={};'"|.<>?]/;
let emojiFormat = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;

class AddressForm extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            selectedOccasions : props.selectedOccasions,
            fetchingData: false,
        }
    }

    componentDidMount(){
        const selectedOccasions = getSessionStorage('selectedOccasions');
        if(selectedOccasions){
            selectedOccasions.forEach(item => {
                const date = new Date(item.date.date);
                item.date.date = date;
                if(!item.name){
                    this.props.history.push(routerNames.DETAILS)
                }
            })
            this.setState({ selectedOccasions : selectedOccasions })
        } else {
            this.props.history.push(routerNames.SELECT_RELATION)
        }
        this.initializeForm();
    }

    initializeForm = () => {
        const data = {}
        this.state.selectedOccasions && this.state.selectedOccasions.forEach((item,index) => {
            if(item.address){
                data[`${item.relative.relation+'_'+item.relative.id}'s-Pincode`] = item.address.address.pincode
                data[`${item.relative.relation+'_'+item.relative.id}'s-City`] = item.address.address.city
                data[`${item.relative.relation+'_'+item.relative.id}'s-Address`] = item.address.address.streetAddress
                data[`${item.relative.relation+'_'+item.relative.id}'s-State`] = item.address.address.state
                data[`${item.relative.relation+'_'+item.relative.id}'s-Mobile`] = item.address.address.mobile
            }
        })
        this.props.initialize(data);
    }

    componentDidUpdate(){
        this.initializeForm();
    }

    checkPincode = (value,index,element,address,address1) => {
        this.setState({ fetchingData: true })
        axiosInstanceGetCity.get(``, {
            params : {
                pincode : value,
                all_c : 0,
                country_id: 0
            }
        })
        .then(res => {
            this.setState({ fetchingData: false })
            if(res.data && res.data.delivery_status){
                address1['city'] = res.data.city;
                if(address1.pincode && address1.pincode!==''&&
                    address1.city&&address1.city!==''&&
                    address1.streetAddress&&address1.streetAddress!==''&&
                    address1.mobile&&address1.mobile!==''){
                        address.status = true
                    } else {
                        address.status = false
                    }
            } else {
                address1['city'] = '';
                address.status = false
                toast("Sorry, We do not deliver in this area");
            }
            address.address = address1;
            element.address = address;
            let arr = [...this.state.selectedOccasions]
            arr[index] = element
            this.setState({ selectedOccasions : arr })
        })
        .catch( e => { toast("Could not process request, Please try again") })
    }

    handleChange = (key,e,index,item) => {
        let element = Object.assign({},item);
        let address = item.address ? Object.assign({},item.address) : {address : {}, status: false}
        let address1 = item.address ? Object.assign({},item.address.address) : {}
        if(key==='pincode'){
            if(e.target.value.length <= 6){
                address1[key] = e.target.value;
            }
            if(e.target.value.length===6){
                this.checkPincode(e.target.value,index,element,address,address1)
            }
        } else {
            address1[key] = e.target.value;
        }
        address.address = address1;
        if(address1.pincode && address1.city && address1.streetAddress && address1.mobile){
            address.status = true;
        } else {
            address.status = false;
        }
        element['address'] = address
        let arr = [...this.state.selectedOccasions]
        arr[index] = element
        this.setState({ selectedOccasions : arr })
    }

    setDefaultAddress = (e,address,index,item) => {
        let element = Object.assign({},item);
        if(!element.hasOwnProperty('address')){
            element['address'] = {};
        }
        element['address'] = address;
        let arr = [...this.state.selectedOccasions];
        arr[index] = element;
        this.setState({ selectedOccasions : arr })
    }

    handleSubmit = (buttonEvent) => {
        let arr = [...this.state.selectedOccasions];
        const user = getSessionStorage('userInfo');
        const arr1 = [];

        arr.forEach((item) => {
            const obj = {
                relation : item.relative.id+'-'+item.relative.relation+'-'+item.relative.isMultiple,
                occasion : item.occasion,
                date     : item.date.date,
                name     : item.name,
                address  : item.address ? item.address.address ? item.address.address : '' : ''
            }
            if(item.gift){ 
                obj.gift = {
                    nid           : item.gift.nid,
                    title         : item.gift.title,
                    price         : item.gift.price,
                    image         : item.gift.image || item.gift.images[0] || '',
                    doubleFlowers : item.doubleFlowers || item.gift.doubleFlowers || 0,
                    addVase       : item.addVase || item.gift.addVase || 0,
                    weightOfCake  : item.weightOfCake || item.gift.weightOfCake || 0,
                    eggless       : item.eggless || item.gift.eggless || 0,
                    messageOnCake : item.messageOnCake || item.gift.messageOnCake || '',
                    addFlowers    : item.addFlowers || item.gift.addFlowers || 0,
                    type          : item.type || item.gift.type || ''
                }
            }
            if(item.address && !item.address.status){
                delete item.address
                delete obj.address
            }
            arr1.push(obj)
        })

        const data = {
            step       : 3,
            id         : user.id,
            user_email : user.user_email,
            data       : arr1
        }

        this.updateOrderSchedular(data,arr,buttonEvent)      
    }

    updateOrderSchedular = (data,arr,buttonEvent) => {
        this.setState({ fetchingData: true })
        axiosInstance.post(`updateOrderScheduler`, data, {
            headers: {
                'Auth'         : localStorage.getItem('Token'),
                'Content-Type' :'application/json'
            }
        })
        .then(res => {
            this.setState({ fetchingData: false })
            if(res.data.statusCode===200){
                saveSessionStorage('selectedOccasions',arr)
                pushToDataLayer('OnAddressContinue',{},{},{
                    event: "clickEvent",
                    eventCategory: "Schedular",
                    eventAction: "delivery-address",
                    eventLabel: buttonEvent
                })
                this.props.history.push(routerNames.DELIVERY_DETAILS,{
                    selectedOccasions : arr,
                    is_offer_enabled  : res.data.is_offer_enabled
                })
            } else if(res.data.statusCode==401){
                fetchToken("submit",this.handleSubmit)
            } else {
                this.setState({ fetchingData: false })
                toast("Oops Something went wrong, Please try again");
            }
        })
        .catch( e => { toast("Could not process request, Please try again") })
    }

    render(){
        const { handleSubmit } = this.props;
        let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        let showButton = "Next";
        let disable = false;
        const form = this.state.selectedOccasions && this.state.selectedOccasions.map((item,index) => {
            formFields.push(item.relative)

            if(!item.address || (item.address && !item.address.status)){
                showButton = "Skip"
            }

            if(item.address && (specialFormat.test(item.address.address.city) || 
                specialFormat.test(item.address.address.streetAddress) ||
                specialFormat.test(item.address.address.pincode) || 
                specialFormat.test(item.address.address.mobile) ||
                emojiFormat.test(item.address.address.city) ||
                emojiFormat.test(item.address.address.streetAddress)))
            {
                disable = true
            }

            if(item.address && item.address.address.mobile && (item.address.address.mobile.length > 14 || item.address.address.mobile.length < 10)){
                disable = true
            }

            const date = new Date(item.date.date);
            return(
                <div className='form-section' key={item.relative.relation+'_'+item.relative.id}>
                    <div className='relative-name'>{item.relative.relation}</div>
                    <div className='occasion-address-form'>{item.occasion} : {date.getDate()} {months[date.getMonth()]}, {date.getFullYear()}</div>
                    {
                        this.state.selectedOccasions.map((ele,i) => {
                            return(
                                <div className='mt-4' key={ele.relative.relation+'-'+ele.relative.id}>
                                    {
                                        index!==i && (!item.address || (item.address && !item.address.status)) && ele.address && ele.address.address && ele.address.address.pincode && ele.address.address.city && ele.address.address.streetAddress && ele.address.address.mobile &&
                                            <label className="container-check">{`Same as Address - ${i+1}`}
                                                <input type="radio" name={item.relative.relation+'_'+item.relative.id} onChange={(e) => this.setDefaultAddress(e,ele.address,index,item)}/>
                                                <span className="checkmark"></span>
                                            </label>
                                    }
                                </div>
                            )
                        })
                    }
                    <div className='d-flex'>
                        <div className="title-wrap mt-20 pr-2">
                            <div className="field-wrap fullname-wrap">
                                <div className="form-group">
                                    <Field
                                        name={`${item.relative.relation+'_'+item.relative.id}'s-Pincode`}
                                        label='Pincode'
                                        labelClassName={classnames({
                                            'form-label': true,
                                            'input-active': !isEmpty(this.state.selectedOccasions[index].address) && !isEmpty(this.state.selectedOccasions[index].address.address.pincode) ? true : false
                                        })}
                                        id={`${item.relative.relation}'s Pincode`}
                                        type='number'
                                        onChange={e => this.handleChange("pincode",e,index,item)}
                                        className="form-input"
                                        component={MaterialTextField}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="title-wrap mt-20 pl-2">
                            <div className="field-wrap fullname-wrap">
                                <div className="form-group">
                                    <Field
                                        name={`${item.relative.relation+'_'+item.relative.id}'s-City`}
                                        label='City'
                                        labelClassName={classnames({
                                            'form-label': true,
                                            'input-active': !isEmpty(this.state.selectedOccasions[index].address) && !isEmpty(this.state.selectedOccasions[index].address.address.city) ? true : false
                                        })}
                                        id={`${item.relative.relation}'s City`}
                                        type='text'
                                        //onChange={e => this.handleChange("city",e,index,item)}
                                        className="form-input"
                                        component={MaterialTextField}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="title-wrap mt-20">
                        <div className="field-wrap fullname-wrap">
                            <div className="form-group">
                                <Field
                                    name={`${item.relative.relation+'_'+item.relative.id}'s-Address`}
                                    label='Delivery Address'
                                    labelClassName={classnames({
                                        'form-label': true,
                                        'input-active': !isEmpty(this.state.selectedOccasions[index].address) && !isEmpty(this.state.selectedOccasions[index].address.address.streetAddress) ? true : false
                                    })}
                                    id={`${item.relative.relation}'s Address`}
                                    type='text'
                                    onChange={e => this.handleChange('streetAddress',e,index,item)}
                                    className="form-input"
                                    component={MaterialTextField}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='d-flex'>
                        <div className="title-wrap mt-20">
                            <div className="field-wrap fullname-wrap">
                                <div className="form-group again">
                                    <Field
                                        name={`${item.relative.relation+'_'+item.relative.id}'s-Mobile`}
                                        label='Mobile'
                                        labelClassName={classnames({
                                            'form-label': true,
                                            'input-active': !isEmpty(this.state.selectedOccasions[index].address) && !isEmpty(this.state.selectedOccasions[index].address.address.mobile) ? true : false
                                        })}
                                        id={`${item.relative.relation}'s Mobile`}
                                        type='tel'
                                        onChange={e => this.handleChange('mobile',e,index,item)}
                                        className="form-input"
                                        component={MaterialTextField}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        })

        return(
            <div className='form-container'>
                <LoadingCircle load={this.state.fetchingData}/>
                <ToastContainer />
                <form className='mb-50' onSubmit={handleSubmit(() => this.handleSubmit(showButton))}>
                    {form}
                    <div id='bottom-btn' className='fixed-bottom fixed-button w-100'>
                        <div className="h-100 position-relative w-80 m-auto">
                        {showButton === 'Skip' ?
                            <input disabled={disable} type="submit" className={disable ? 'btn-next btn-next-small disable-btn' : 'btn-next btn-next-small'} name="Skip" value="SKIP I'LL ADD LATER" />
                            : <input disabled={disable} type="submit" className={disable ? 'btn-next disable-btn' : 'btn-next'} name="Next" value="Next" />
                        }
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}

const validate = values => {
    const errors = {};
    const relatives = formFields;
    const fields = ['Pincode','City','Address','State','Mobile'];
    
    relatives.forEach(relative => {
        fields.forEach(field => {
            if (values[`${relative.relation+'_'+relative.id}'s-${field}`]===undefined) {
                errors[`${relative.relation+'_'+relative.id}'s-${field}`] = `${ucFirst(`${relative.relation}'s ${field}`)} is required`;
            }
        })
        if(specialFormat.test(values[`${relative.relation+'_'+relative.id}'s-City`])){
            errors[`${relative.relation+'_'+relative.id}'s-City`] = `${ucFirst(`City`)} can't have special characters`; 
        }
    })

    return errors;
}

export default reduxForm({
    form: 'AddressFormNew',
    validate
})(withRouter(AddressForm))
