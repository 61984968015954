import React from 'react';
import Logo from "../../components/Layout/components/images/logo.svg";
import shield from "../../components/Layout/components/images/shield.svg";
import smiley from "../../components/Layout/components/images/smiley.svg";
import phone from "../../components/Layout/components/images/phone.svg";

const Header = () => {
    return(
        <div className='header-coming-soon'>
            <a href='https://www.floweraura.com/'>
                <img className='logo-header' src={Logo} alt='flower-aura'/>
            </a>
            <div className='d-flex font-header font-weight-bold justify-content-around'>
                <div className='w-25 d-flex align-items-center'><img className='header-icon' src={shield} alt='protection'/><p className='m-0 pl-2'>100% payment protection</p></div>
                <div className='w-25 d-flex align-items-center'><img className='header-icon' src={smiley} alt='smiley'/><p className='m-0 pl-2'>2 million smiles Delivered</p></div>
                <div className='w-25 d-flex align-items-center'><img className='header-icon' src={phone} alt='phone'/><p className='m-0 pl-2'>Need Assistance +91-9650062220</p></div>
            </div>
        </div>
    )
}

export default Header;