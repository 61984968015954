import React from 'react';
import blueLocation from '../../../components/Layout/components/images/group-3.svg';
import location from '../../../components/Layout/components/images/location-16.svg';
import gift from '../../../components/Layout/components/images/gift.svg';
import mail from '../../../components/Layout/components/images/mail.svg';
import add from '../../../components/Layout/components/images/group-2.svg';
import { withRouter } from 'react-router-dom';
//import edit from '../../../components/Layout/components/images/edit.svg';
import rectangle from '../../../components/Layout/components/images/rectangle.svg';
import { routerNames } from '../../../config/config';
import Modal from 'react-modal';
import SlidingPane from 'react-sliding-pane';
import classnames from 'classnames';
import { fetchToken, isEmpty, ucFirst, getSessionStorage, removeSessionStorage, saveSessionStorage } from '../../../helpers';
import { Field, reduxForm } from 'redux-form';
import { MaterialTextField } from '../../../components/RenderField';
import Calendar from 'react-calendar';
import { axiosInstance, axiosInstanceGetCity } from '../../../config/axiosInstance';
import { ToastContainer, toast } from 'react-toastify';
import LoadingCircle from '../../../components/Loader';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import '../../../index.css';
import './index.css';
import { pushToDataLayer } from "../../../GS_GTM/gtmFunctions";  

const formFields = [];
const specialFormat = /[\[\]\\|<>\/]/;
const emojiFormat = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;

class UserOrders extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            selectedOccasions : [],
            fetchingData: false,
            total : 0,
            status_id : '',
            isPincodeValid: true
        }
    }

    componentDidMount(){
        Modal.setAppElement(this.ele);
        if(this.props.history.location.search!==""){
            this.updatePaymentStatus();
        } else {
            this.getOrderDetail();
        }
    }

    getOrderDetail = () => {
        this.setState({ fetchingData: true })
        fetchToken("submit",this.getOrderDetailAPI)
    }

    getOrderDetailAPI = () => {
        const pathname = this.props.history.location.pathname.split('/')
        const id = pathname[pathname.length-2]
        saveSessionStorage('userInfo', { id: id })
        const code = pathname[pathname.length-1]
        this.setState({ fetchingData: true })
        
        axiosInstance.post("orderDetail", {id: id, hash_key: code}, {
            headers: {
                'Auth': localStorage.getItem('Token'),
                'Content-Type':'application/json'
            }
        })
        .then(res => {
            if(res.data && res.data.data){
                const data = []
                res.data.data.forEach((item,index) => {
                    let obj = {
                        relative : { id: index, relation: item.relation },
                        date : { date : new Date(item.date), status: true },
                        name: item.name,
                        occasion: item.occasion,
                        gift: item.gift,
                        order_id: item.order_id
                    }
                    if(item.address){
                        obj.address = { status: true, address : item.address}
                    }
                    if(item.message){
                        obj.message = { message: item.message, status: true }
                    }
                    data.push(obj)
                })
                pushToDataLayer('OnOrderConfirmationPageLoad')
                this.setState({ 
                    selectedOccasions : data, 
                    total: res.data.total, 
                    status_id : 21,
                    fetchingData : false
                })
            } else {
                toast("Oops Something went wrong, Please try again");
            }
        })
        .catch(e => {
            this.setState({ fetchingData: false })
            toast("Oops Something went wrong, Please try again");
        })
    }

    updatePaymentStatus = () => {
        const orderID = this.props.match.params.orderId        
        const userInfo = getSessionStorage('userInfo');
        if(userInfo) {
            const params = new URLSearchParams(this.props.location.search)
            let status_id = params.get('status_id') || 0;
            let order_id = orderID;
            let transactionId = params.get('order_id') || 0;
            const data = {
                status_id : status_id,
                id : order_id,
                transactionId: transactionId,
                user_email: userInfo.user_email
            }

            this.setState({ fetchingData: true })
            
            axiosInstance.post(`updatePaymentStatus`, data, {
                headers: {
                    'Auth': localStorage.getItem('Token'),
                    'Content-Type':'application/json'
                }
            })
            .then(res => {
                this.setState({ fetchingData: false })
                if(res.data && res.data.data){
                    const data = []
                    res.data.data.forEach((item,index) => {
                        let obj = {
                            relative : { id: index, relation: item.relation },
                            date : { date : new Date(item.date), status: true },
                            name: item.name,
                            occasion: item.occasion,
                            gift: item.gift,
                            order_id: item.order_id
                        }
                        if(item.address){
                            obj.address = { status: true, address : item.address}
                        }
                        if(item.message){
                            obj.message = { message: item.message, status: true }
                        }
                        data.push(obj)
                    })
                    this.setState({ 
                        selectedOccasions : data, 
                        total: res.data.total, 
                        status_id : status_id
                    })
                    removeSessionStorage('relatives')
                    removeSessionStorage('selectedOccasions')
                    removeSessionStorage('chosenRelative')
                    removeSessionStorage('currentMenuCategory')
                } else if(res.data.statusCode==401){
                    fetchToken("paymentDone")
                } else {
                    toast("Oops Something went wrong, Please try again");
                }
            })
            .catch( e => { toast("Could not process request, Please try again") })
        } else {
            this.props.history.push(routerNames.HOME)
        }
    }

    handleShowSlideBox = (key,item,index) => {
        const element = Object.assign({},item);
        element[key] = {
            [key]: item[key] && item[key][key] ? item[key][key] : "",
            status : false
        };
        const arr = [...this.state.selectedOccasions];
        arr[index] = element;
        this.setState({ selectedOccasions : arr })
    }

    handleGiftMessage = (e,index,item) => {
        const element = Object.assign({},item);
        const message = Object.assign({},item.message);
        message.message = e.target.value;
        element.message = message
        const arr = [...this.state.selectedOccasions];
        arr[index] = element;
        this.setState({ selectedOccasions : arr })
    }

    saveMessage = (item,index) => {
        const element = Object.assign({},item);
        const message = Object.assign({},item.message);
        message.status = true;
        element.message = message;
        const arr = [...this.state.selectedOccasions];
        arr[index] = element
        this.setState({ selectedOccasions : arr })
    }

    initializeForm = (index) => {
        const data = {}
        const item = this.state.selectedOccasions[index];
        data[`${item.relative.relation}'s-Pincode`] = item.address.address.pincode
        data[`${item.relative.relation}'s-City`] = item.address.address.city
        this.props.initialize(data);
    }

    checkPincode = (value,index,element,address,address1) => {
        this.setState({ fetchingData: true })
        axiosInstanceGetCity.get(``, {
            params : {
                pincode : value,
                all_c : 0,
                country_id: 0
            }
        })
        .then(res => {
            this.setState({ fetchingData: false })
            if(res.data && res.data.delivery_status){
                address1['city'] = res.data.city;
                this.setState({ isPincodeValid: true })
            } else {
                address1['city'] = '';
                this.setState({ isPincodeValid: false })
                //toast("Sorry, We do not deliver in this area");
            }
            address.address = address1;
            element.address = address;
            let arr = [...this.state.selectedOccasions]
            arr[index] = element
            this.setState({ selectedOccasions : arr },() => this.initializeForm(index))
        })
        .catch( e => { toast("Could not process request, Please try again") })
    }

    handleChangeAddress = (key,e,index,item) => {
        let element = Object.assign({},item);
        let address = Object.assign({},item.address)
        let address1 = Object.assign({},item.address.address)
        if(key==='pincode' && e.target.value.length===6){
            this.checkPincode(e.target.value,index,element,address,address1)
        }
        address1[key] = e.target.value;
        address.address = address1
        element.address = address
        let arr = [...this.state.selectedOccasions];
        arr[index] = element
        this.setState({ selectedOccasions : arr })
    }

    handleChangeDate = (date,item,index) => {
        const element =  Object.assign({},item);
        let date1 = Object.assign({},item.date);
        date1 = {
            date : date,
            status: true
        }
        element.date = date1;
        const arr = [...this.state.selectedOccasions];
        arr[index] = element;
        this.setState({ selectedOccasions : arr })
    }


    saveAddress = (item,index,value) => {
        if(Object.keys(item.address.address).length===4){
            const element = Object.assign({},item);
            const address = Object.assign({},item.address);
            address.status = true;
            element.address = address
            const arr = [...this.state.selectedOccasions];
            arr[index] = element;
            this.setState({ selectedOccasions : arr })
        }
    }

    closeSlider = (index,sliderName) => {
        let arr = [...this.state.selectedOccasions]
        let item = Object.assign({}, arr[index])
        if(sliderName==='date'){
            let date = Object.assign({},item.date)
            date.status = true
            item.date = date
        } else {
            delete item[sliderName];
        }
        arr[index] = item
        this.props.reset();
        this.setState({ selectedOccasions : arr })
    }

    handleSubmit = () => {
        let arr = []
        const user = getSessionStorage('userInfo');
        this.state.selectedOccasions.forEach((item) => {
            let json = {
                address  : item.address.address,
                message  : item.message ? item.message.message : "",
                order_id : item.order_id, 
            }
            arr.push(json)
        })

        const data = {
            step              : 6,
            id                : user.id,
            data              : arr,
        }

        this.setState({ fetchingData: true })
        axiosInstance.post(`updateAddress`, data, {
            headers: {
                'Auth': localStorage.getItem('Token'),
                'Content-Type':'application/json'
            }
        })
        .then(res => {
            this.setState({ fetchingData: false })
            if(res.data.statusCode===200){
                toast("Update is successful. Thank you for your order.");
                //{ onClose: () => this.props.history.push(routerNames.HOME) }
            } else if(res.data.statusCode===400) {
                this.setState({ fetchingData: false })
                toast("Order already processed. Please contact customer care.");
            } else if(res.data.statusCode==401){
                fetchToken("submit",this.handleSubmit)
            } else {
                this.setState({ fetchingData: false })
                toast("Oops Something went wrong, Please try again");
            }
        })
        .catch( e => { toast("Could not process request, Please try again") })
    }

    handleRetry = () => {
        const arr = [...this.state.selectedOccasions]
        const user = getSessionStorage('userInfo');
        const arr1 = []
        arr.forEach(item => {
            arr1.push(item.gift.nid)
        })

        const data = {
            step       : 5,
            id         : user.id,
            user_email : user.user_email,
            data       : arr1,
            total      : this.state.total
        }

        this.setState({ fetchingData: true })

        axiosInstance.post(`checkCartData`, data, {
            headers: {
                'Auth': localStorage.getItem('Token'),
                'Content-Type':'application/json'
            }
        })
        .then(res => {
            this.setState({ fetchingData: false })
            if(res.data && res.data.data){
                window.location.replace(res.data.data)
            } else {
                toast("Oops Something went wrong, Please try again");
            }
        })
        .catch( e => { toast("Could not process request, Please try again") })
    }

    handleGetHelp = () => {
        //console.log(this.state)
    }

    render(){
        const pathname = this.props.history.location.pathname.split('/')
        const orderNumber = pathname[pathname.length-1]
        let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        let disableSubmit = false;
        let showButton = (this.state.status_id==21) ? "success" : (this.state.status_id==28 || this.state.status_id==23) ? 'pending' : 'failed';
        let disableMsgSave = false;
        let disableAddress = false;
        let style = {}
        const details = this.state.selectedOccasions.map((item,index) => {
            formFields.push(item.relative.relation)
            const date = new Date(item.date.date)
            let giftPrice = 0;
            if(item.weightOfCake && item.gift.type==='cake' && item.gift.attributes['10']){
                Object.keys(item.gift.attributes['10'].options).forEach(id => {
                    if(item.gift.attributes['10'].options[id].name===item.weightOfCake){
                        giftPrice = item.gift.attributes['10'].options[id].price
                    }
                })
            } else { giftPrice = item.gift.price }
            let price = parseFloat(giftPrice);
            // if(item.gift.doubleFlowers) {
            //     price = price + parseFloat(item.gift.doubleFlowers)
            // }
            // if(item.gift.addVase) {
            //     price = price + parseFloat(item.gift.addVase)
            // }
            // if(item.gift.eggless){
            //     price = price + parseFloat(item.gift.eggless)
            // }
            // if(item.gift.addGreetings) {
            //     price = price + 99
            // }

            if(!item.address){
                disableSubmit = true
            }
            if(item.address && item.address.address && (
                (item.address.address.pincode && item.address.address.pincode.length > 6) ||
                emojiFormat.test(item.address.address.streetAddress) || 
                emojiFormat.test(item.address.address.city) ||
                specialFormat.test(item.address.address.city) || 
                (item.address.address.mobile && (item.address.address.mobile.length > 14 || item.address.address.mobile.length < 10))
            )
            ){
                disableAddress = true;
                style = {
                    background: 'lightgray'
                }
            }
            if(item.message && (specialFormat.test(item.message.message) || emojiFormat.test(item.message.message))){
                disableMsgSave = true;
                style = {
                    background: 'lightgray'
                }
            }
            const { handleSubmit } = this.props
            
            return(
                <div ref={ref => this.ele = ref} className='border-top-bottom container pb-4 pt-4' key={item.relative.relation+'_'+item.relative.id}>
                    <div className='font-weight-bold'>{item.relative.relation.split('-')[1]}</div>
                    <div className='d-flex pt-4 pb-4 border-gift'>
                        <div>
                            <img className='image-size-order' src={item.gift.image} alt='cake'/>
                        </div>
                        <div className='d-flex flex-column justify-content-center pl-4 w-75'>
                            <div className='d-flex justify-content-between'>
                                <span>{item.gift.title}</span>
                                {/* <span><img onClick={() => this.props.deleteGift(this.props.index)} src={bin} alt='delete'/></span> */}
                            </div>
                            <div className='gift-price-order pt-1 pb-2'>Rs. {price}</div>
                        </div>
                    </div>
                    <div className='justify-content-between pt-3'>
                        {item.gift.doubleFlowers > 0 && 
                            <div>
                                <img src={rectangle} alt='rectangle'/>
                                <span className='pl-2'>Double the flowers @ Rs {parseFloat(item.gift.doubleFlowers)}</span>
                            </div>
                        }
                        {item.gift.addVase > 0 && 
                            <div>
                                <img src={rectangle} alt='rectangle'/>
                                <span className='pl-2'>Add vase @ Rs {parseFloat(item.gift.addVase)}</span>
                            </div>
                        }
                        {item.gift.addFlowers > 0 && 
                            <div>
                                <img src={rectangle} alt='rectangle'/>
                                <span className='pl-2'>Add 10 Roses @ Rs {parseFloat(item.gift.addFlowers)}</span>
                            </div>
                        }
                        {item.gift.weightOfCake > 0 && 
                            <div>
                                <img src={rectangle} alt='rectangle'/>
                                <span className='pl-2'>Weight - {parseFloat(item.gift.weightOfCake)}</span>
                            </div>
                        }
                        {item.gift.eggless > 0 && 
                            <div>
                                <img src={rectangle} alt='rectangle'/>
                                <span className='pl-2'>Make it eggless @ Rs {parseFloat(item.gift.eggless)}</span>
                            </div>
                        }
                        {item.gift.messageOnCake !== '' && 
                            <div>
                                <img src={rectangle} alt='rectangle'/>
                                <span className='pl-2'>"{item.gift.messageOnCake}"</span>
                            </div>
                        }
                    </div>
                    <div className='align-items-center d-flex pb-15 pt-15'>
                        <img className='img-size' src={gift} alt='gift'/>
                        <span className='ml-3 mt-1'>{item.occasion} : <span>{date.getDate()} {months[date.getMonth()]}, {date.getFullYear()}</span></span>
                        {/* <span className='ml-auto' onClick={() => this.handleShowSlideBox("date",item,index)}><img src={edit} alt='edit'/></span> */}
                    </div>

                    {
                        item.date && item.date.status===false &&
                        <div>
                            <SlidingPane
                                isOpen={item.date && item.date.status===false}
                                from='bottom'
                                className='slide-pane'
                                onRequestClose={() => this.closeSlider(index,'date')}
                            >
                                <Calendar
                                    className='calendar'
                                    onChange={(date) => this.handleChangeDate(date,item,index)}
                                    value={item.date ? item.date.date : new Date()}
                                    minDate={new Date()}
                                />
                            </SlidingPane>
                        </div>
                    }

                    {item.address && item.address.status ? 
                        <div className='align-items-center d-flex pb-15 pt-15'>
                            <img className='img-size' src={location} alt='location'/>
                            <span className='ml-3 mt-1'>{item.address.address.streetAddress}, {item.address.address.city}, {item.address.address.pincode}</span>
                        </div> 
                        :
                        showButton==='success' && 
                        <div className='align-items-center d-flex pb-15 pt-15'>
                            <img className='img-size image-color' src={blueLocation} alt='location'/>
                            <span className='ml-3 mt-1 text-color' onClick={() => this.handleShowSlideBox("address",item,index)}>Add Delivery Address</span>
                        </div>
                    }

                    {item.address && item.address.status===false &&
                        <div>
                            <SlidingPane
                                isOpen={item.address!==undefined}
                                from='bottom'
                                className='slide-pane'
                                onRequestClose={() => this.closeSlider(index,'address')}
                            >
                                 <form className='message-box p-4' style={{visibility:'visible'}} onSubmit={handleSubmit((value) => this.saveAddress(item,index,value))}>
                                    <div className='d-flex'>
                                        <div className="title-wrap mt-20 pr-2">
                                            <div className="field-wrap fullname-wrap">
                                                <div className="form-group">
                                                    <Field
                                                        name={`${item.relative.relation}'s-Pincode`}
                                                        label='Pincode'
                                                        labelClassName={classnames({
                                                            'form-label': true,
                                                            'input-active': !isEmpty(this.state.selectedOccasions[index].address) && !isEmpty(this.state.selectedOccasions[index].address.address.pincode) ? true : false
                                                        })}
                                                        id={`${item.relative.relation}'s Pincode`}
                                                        type='number'
                                                        onChange={e => this.handleChangeAddress("pincode",e,index,item)}
                                                        className="form-input"
                                                        component={MaterialTextField}
                                                    />
                                                    {!this.state.isPincodeValid && <span className='wrong-pincode'><sup>*</sup>Sorry, We do not deliver in this area.</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="title-wrap mt-20 pl-2">
                                            <div className="field-wrap fullname-wrap">
                                                <div className="form-group">
                                                    <Field
                                                        name={`${item.relative.relation}'s-City`}
                                                        label='City'
                                                        labelClassName={classnames({
                                                            'form-label': true,
                                                            'input-active': !isEmpty(this.state.selectedOccasions[index].address) && !isEmpty(this.state.selectedOccasions[index].address.address.city) ? true : false
                                                        })}
                                                        id={`${item.relative.relation}'s City`}
                                                        type='text'
                                                        //onChange={e => this.handleChangeAddress("city",e,index,item)}
                                                        className="form-input"
                                                        component={MaterialTextField}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="title-wrap mt-20">
                                        <div className="field-wrap fullname-wrap">
                                            <div className="form-group">
                                                <Field
                                                    name={`${item.relative.relation}'s-Address`}
                                                    label='Delivery Address'
                                                    labelClassName={classnames({
                                                        'form-label': true,
                                                        'input-active': !isEmpty(this.state.selectedOccasions[index].address) && !isEmpty(this.state.selectedOccasions[index].address.address.streetAddress) ? true : false
                                                    })}
                                                    id={`${item.relative.relation}'s Address`}
                                                    type='text'
                                                    onChange={e => this.handleChangeAddress('streetAddress',e,index,item)}
                                                    className="form-input"
                                                    component={MaterialTextField}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='d-flex'>
                                        <div className="title-wrap mt-20 pl-2">
                                            <div className="field-wrap fullname-wrap">
                                                <div className="form-group">
                                                    <Field
                                                        name={`${item.relative.relation}'s-Mobile`}
                                                        label='Mobile'
                                                        labelClassName={classnames({
                                                            'form-label': true,
                                                            'input-active': !isEmpty(this.state.selectedOccasions[index].address) && !isEmpty(this.state.selectedOccasions[index].address.address.mobile) ? true : false
                                                        })}
                                                        id={`${item.relative.relation}'s Mobile`}
                                                        type='tel'
                                                        onChange={e => this.handleChangeAddress('mobile',e,index,item)}
                                                        className="form-input"
                                                        component={MaterialTextField}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="h-100 position-relative m-auto">
                                            <input disabled={disableAddress} style={style} id='address' type="submit" className='btn-save-address-box' name="Save" value="Save" />
                                        </div>
                                    </div>
                                </form>                            
                        </SlidingPane>
                    </div>
                    }

                    {item.message !== undefined && item.message.status === true? 
                        <div className='pb-15 pt-15'>
                            <img className='img-size-mail' src={mail} alt='mail'/>
                            <span className='ml-3 mt-1 opacity'>Message</span>
                            <div className='ml-2 pl-4 pt-3'>{item.message.message}</div>
                        </div> 
                        :
                        showButton==='success' && 
                        <div className='align-items-center d-flex pb-15 pt-15'>
                            <img className='img-size image-color' src={add} alt='location'/>
                            <span className='ml-3 mt-1 text-color' onClick={() => this.handleShowSlideBox('message',item,index)}>Write Message</span>
                        </div>
                    }
                    
                    {
                        item.message !==undefined && item.message.status === false &&
                        <div>
                            <SlidingPane
                                isOpen={item.message!==undefined && item.message.status===false}
                                from='bottom'
                                className='slide-pane'
                                onRequestClose={() => this.closeSlider(index,'message')}
                            >
                                <div className='message-box pt-4'>
                                    <h4 className='font-weight-bold pb-20'>Write your message</h4>
                                    <div className='position-relative'>
                                        <textarea
                                            onChange={e => this.handleGiftMessage(e,index,item)} 
                                            id='gift-message' 
                                            className='message-text' 
                                            cols='32' 
                                            rows='4' 
                                            maxLength='250'
                                        />
                                        <span className='count-char'>{item.message.message.length}/250</span>
                                    </div>
                                    <div className='pt-20'>
                                        <button 
                                            disabled={disableMsgSave}
                                            onClick={() => this.saveMessage(item,index)}
                                            style={style}
                                            className='btn-next pb-20 w-50 p-10 position-relative font-weight-bold'
                                            id="message">Save</button>
                                    </div>
                                </div>                                
                            </SlidingPane>
                        </div>
                    }

                </div>
            )
        })
        return(
            <React.Fragment>
                <ToastContainer />
                <LoadingCircle load={this.state.fetchingData}/>
                <p className='font-weight-bold pl-3 font-14'>Order Number : {"GS-"+orderNumber}</p>
                <div className='mb-70'>
                    {details}
                </div>
                {showButton==='success' ?
                    <div id='bottom-btn' className="fixed-bottom fixed-button w-100">
                        <div className='h-100 position-relative m-auto w-80'>
                            <button disabled={disableSubmit} className={disableSubmit ? 'btn-next w-100 p-10 disable-submit' : 'btn-next w-100 p-10'} onClick={this.handleSubmit}>SUBMIT</button>
                        </div>
                    </div> : 
                showButton==='pending' ?
                    <div id='bottom-btn' className="fixed-bottom fixed-button w-100">
                        <div className='h-100 position-relative m-auto w-80'>
                            <button className='btn-next w-100 p-10' onClick={this.handleGetHelp}>Get Help</button>
                        </div>
                    </div>
                : 
                    <div id='bottom-btn' className="fixed-bottom fixed-button w-100">
                        <div className='h-100 position-relative m-auto w-80'>
                            <button className='btn-next w-100 p-10' onClick={this.handleRetry}>Retry</button>
                        </div>
                    </div>
                }
            </React.Fragment>
        )
    }
}

const validate = values => {
    const errors = {};
    const relatives = formFields;
    const fields = ['Pincode','City','Address','State','Mobile'];
    const specialFormat = /[\[\]\\|<>\/]/;

    relatives.forEach(relative => {
        fields.forEach(field => {
            if (values[`${relative}'s-${field}`]===undefined) {
                errors[`${relative}'s-${field}`] = `Required`;
            }

            if(values[`${relative}'s-Mobile`] && (values[`${relative}'s-Mobile`].length > 14 || values[`${relative}'s-Mobile`].length < 10)){
                errors[`${relative}'s-Mobile`] = `Number is not valid`
            }

            if (`${relative}'s-Pincode` && values[`${relative}'s-Pincode`] && values[`${relative}'s-Pincode`].length!==6) {
                errors[`${relative}'s-Pincode`] = ucFirst(`Pincode must be 6 digits`);
            }

            if(specialFormat.test(values[`${relative}'s-Address`])){
                errors[`${relative}'s-Address`] = `Address can't have special characters`
            }

            if(specialFormat.test(values[`${relative}'s-Pincode`])){
                errors[`${relative}'s-Pincode`] = `Pincode can't have special characters`
            }

            if(specialFormat.test(values[`${relative}'s-City`])){
                errors[`${relative}'s-City`] = `City can't have special characters`
            }
        
            if(emojiFormat.test(values[`${relative}'s-Address`])){
                errors[`${relative}'s-Address`] = `Address can't have emojis`
            }
        })
    })

    return errors;
}

export default (reduxForm({
    form: 'AddressBoxForm',
    validate
  })(withRouter(UserOrders)));