import React, { Component } from 'react';
import Layout from '../../../components/Layout/container';
import ChooseGiftsPage from './ChooseGiftsPage';
import Headline from '../../UserForm/components/Headline';
import './index.css';
import { withRouter } from 'react-router-dom';
import { routerNames } from '../../../config/config';
import { isEmpty, getSessionStorage } from '../../../helpers';
import { scroller } from "react-scroll";

class ChooseGifts extends Component {    
    componentDidMount() {
        const currentChooseItemIndex = getSessionStorage('currentChooseItemIndex')
        if(currentChooseItemIndex != null){
           this.scrollToSection()
        }
      
    }
    
    scrollToSection = () => {
        const currentChooseItemIndex = getSessionStorage('currentChooseItemIndex')
        scroller.scrollTo("scroll_item_"+(currentChooseItemIndex+ 1), {
          duration: 800,
          delay: 0,
          smooth: "easeInOutQuart",
        });
    }
    render() {
        return (
            <div className='parent-div-gifts'>
                <Layout serenade={true} page={routerNames.RECEIVER_NAME}/>
                <Headline 
                    title='Choose Your Gifts'
                    subtitle="Add gifts as per your events"
                />
                <div className='events'>Scheduled Events</div>
                <ChooseGiftsPage/>
            </div>
        )
    }
}

export default withRouter(ChooseGifts)