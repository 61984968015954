import React from 'react';
import './index.css';
import { withRouter } from 'react-router-dom';
import { routerNames, BASE_URL } from '../../../config/config';
import { fetchToken, saveSessionStorage, getSessionStorage } from '../../../helpers';
import { axiosInstance } from '../../../config/axiosInstance';
import { ToastContainer, toast } from 'react-toastify';
import LoadingCircle from '../../../components/Loader';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import '../../../index.css';
import rose from '../../../components/Layout/components/images/rose.svg';
import propose from '../../../components/Layout/components/images/propose.svg';
import chocolate from '../../../components/Layout/components/images/chocolate.svg';
import teddy from '../../../components/Layout/components/images/teddy.svg';
import promise from '../../../components/Layout/components/images/promise.svg';
import hug from '../../../components/Layout/components/images/hug.svg';
import kiss from '../../../components/Layout/components/images/kiss.svg';
import valentine from '../../../components/Layout/components/images/valentine.svg';
import { pushToDataLayer } from "../../../GS_GTM/gtmFunctions";

class Occasions extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            occasionList : [
                {occasion : "Rose Day",      date: "07", image : rose,      isSelected: false},
                {occasion : "Propose Day",   date: "08", image : propose,   isSelected: false},
                {occasion : "Chocolate Day", date: "09", image : chocolate, isSelected: false},
                {occasion : "Teddy Day",     date: "10", image : teddy,     isSelected: false},
                {occasion : "Promise Day",   date: "11", image : promise,   isSelected: false},
                {occasion : "Hug Day",       date: "12", image : hug,       isSelected: false},
                {occasion : "Kiss Day",      date: "13", image : kiss,      isSelected: false},
                {occasion : "Valentine Day", date: "14", image : valentine, isSelected: false},
            ]
        }
    }

    componentDidMount(){
        axiosInstance.get(`${BASE_URL}/fa-get-page-data/site-header`)
        .then(response => {
            let responseData = response.data;
            if (response.data.error_code === 0 && responseData.data.isUserAnonymous === false) {
                const data = responseData.data.user_info;
                if(data){
                    const info = {
                        "user_name"   : data.name,
                        "user_email"  : data.mail,
                        "user_mobile" : data.number,
                        "order_type"  : "2",
                        "occasionList": getSessionStorage('occasionList')
                    }
                    saveSessionStorage('userInfoSerenade',info)
                }
            }
        }).catch( e => {
            console.log(e)
        })

        const list1 = [...this.state.occasionList]
        const list2 = getSessionStorage('occasionList')
        pushToDataLayer('OnNextSerenade',{},{},{
            event: "clickEvent",
            eventCategory: "Serenade",
            eventAction: "Home",
            eventLabel: "Home"
        })
        if(list2) {
            const date = new Date();
            const utcDate = date.getUTCDate();
            const utcMonth = date.getUTCMonth();
            list2.forEach(item2 => {
                const arr = item2.date.split('-');
                const occDate = arr[arr.length-1];
                list1.forEach(item1 => {
                    if(item1.occasion===item2.occasion && utcMonth <= 1){
                        if(utcMonth < 1){
                            item1.isSelected = true
                            item1.name = item2.name
                            item1.mobile = item2.mobile
                            item1.address = item2.address
                            item1.gift = item2.gift
                            item1.message = item2.message
                        } else if(utcMonth==1 && utcDate <= occDate){
                            item1.isSelected = true
                            item1.name = item2.name
                            item1.mobile = item2.mobile
                            item1.address = item2.address
                            item1.gift = item2.gift
                            item1.message = item2.message
                        }
                    }
                })
            })
            this.setState({ occasionList: list1 })
        }
    }

    addOccasion = (e,item,index) => {
        let flag = e.target.checked ? true : false
        let obj = {occasion: item.occasion, date: item.date, image: item.image, isSelected: flag}
        let arr = [...this.state.occasionList]
        arr[index] = obj
        this.setState({ occasionList : arr })
    }

    handleNext = () => {
            const arr = [];
            const date = new Date();
            let yr = date.getFullYear();
            if((date.getMonth() + 1) > 2){
                yr = date.getFullYear() + 1;
            }
            let label = 'Next - '
            this.state.occasionList.forEach(item => {
                if(item.isSelected){
                    label = label + item.occasion + ' - '
                    const obj = {
                        occasion : item.occasion, 
                        date: yr+'-02-'+item.date,
                        image: item.image,
                        name: item.name,
                        mobile: item.mobile,
                        address: item.address,
                        gift: item.gift,
                        message: item.message
                    }
                    arr.push(obj)
                }
            })

        saveSessionStorage('occasionList',arr)
        pushToDataLayer('OnNextSerenade',{},{},{
            event: "clickEvent",
            eventCategory: "Serenade",
            eventAction: "Home",
            eventLabel: label
        })
        this.props.history.push(routerNames.USER_INFO_SERENADE)
    }

    updateOrderSchedular = (data) => {
        // this.setState({ fetchingData : true })

        // axiosInstance.post(`updateOrderScheduler`, data, {
        //     headers: {
        //         'Auth'         : localStorage.getItem('Token'),
        //         'Content-Type' : 'application/json'
        //     }
        // })
        // .then(res => {
        //     this.setState({ fetchingData : false })
        //     if(res.data.statusCode===200){
        //         saveSessionStorage('occasionList',res.data.data)
        //         this.props.history.push(routerNames.RECEIVER_NAME)
        //     } else if(res.data.statusCode==401){
        //         fetchToken("submit",this.handleNext);
        //     } else {
        //         toast("Oops Something went wrong, Please try again");
        //     }
        // })
        // .catch( e => {
        //     this.setState({ fetchingData : false })
        //     toast("Could not process request, Please try again");
        // })
    }

    render(){
        let disable = true
        const occasionList = this.state.occasionList.map((item,index) => {
            let disableOccasion = false
            if(item.isSelected){ disable = false }
            const date = new Date();
            const utcDate = date.getUTCDate();
            const utcMonth = date.getUTCMonth();
            const utcHours = date.getHours();
            if(utcMonth > 1 || (utcMonth == 1 && utcDate > item.date) || (utcMonth == 1 && utcDate == item.date && utcHours >= 20)){
                disableOccasion = true
            }
            disableOccasion = false;
            return (
                <div key={item.occasion} className={item.isSelected ? 'occasion-list occasion-list-selected' : 'occasion-list'}>
                    <label className="container-serenade">
                        <img src={item.image} alt='i-mg'/>
                        {item.occasion} ( {item.date}th Feb )
                        <input disabled={disableOccasion} type="checkbox" checked={item.isSelected} onChange={(e) => this.addOccasion(e,item,index)}/>
                        <span className={disableOccasion ? "checkmark-serenade disabled-checkbox" : "checkmark-serenade"}></span>
                    </label>
                </div>
            )
        })
        return(
            <div className='occasion-list-container' ref={ref => this.el = ref}>
                <LoadingCircle load={this.state.fetchingData} />
                <ToastContainer />
                {occasionList}
                <StepOne handleNext={this.handleNext} disable={disable}/>
            </div>
        )
    }
}

const StepOne = (props) => {
    const disable = props.disable
    return(
        <React.Fragment>
            <div id='bottom-btn' className="fixed-bottom fixed-button-serenade w-100">
                <div className='h-100 position-relative m-auto w-80'>
                    <button disabled={disable} onClick={props.handleNext} className={disable ? 'btn-next btn-next-disable w-100' : 'btn-next w-100'}>Next</button>
                </div>
            </div>
        </React.Fragment>
    )
}
export default withRouter(Occasions);