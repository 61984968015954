import React, { Component } from 'react';
import Layout from '../../../components/Layout/container';
import OrderStatus from './OrderStatus';
import UserOrders from './UserOrders';

class OrderPlaced extends Component {
    render(){
        return(
            <React.Fragment>
                <Layout/>
                {this.props.history.location.search!=="" && <OrderStatus {...this.props}/>}
                <UserOrders/>
            </React.Fragment>
        )
    }
}

export default OrderPlaced;