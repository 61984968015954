import React from 'react';
import Information from './routes/Information/container';
import SelectRelation from './routes/selectRelation/container';
import RelativeDetailsForm from './routes/RelativeDetailsForm/container';
import DeliveryAddress from './routes/DeliveryAddress/container';
import DeliveryDetails from './routes/DeliveryDetails/container';
import Menu from './routes/Menu/container';
import ProductDetails from './routes/ProductDetails/container';
import OrderPlaced from './routes/OrderPlaced/container';
import ComingSoon from './routes/ComingSoon';
import UserForm from './routes_serenade/UserForm/container';
import SelectOccasion from './routes_serenade/selectOccasion/container';
import ReceiverInfo from './routes_serenade/ReceiverInfoForm/container';
import ReceiverAddress from './routes_serenade/ReceiverAddressForm/container';
import ChooseGifts from './routes_serenade/ChooseGifts/container';
import SerenadeMenu from './routes_serenade/Menu/container';
import SerenadeProductDetails from './routes_serenade/ProductDetails/container';
import OrderPlacedSerenade from './routes_serenade/OrderPlaced/container';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { routerNames } from './config/config';

const App = () => {
    return (
      <BrowserRouter>
        <Switch>

            {/* gift scheduler routes */}
            
            <Route exact path={routerNames.HOME} component={Information} />
            <Route path={routerNames.COMING_SOON} component={ComingSoon} />
            <Route path={routerNames.SELECT_RELATION} component={SelectRelation} />
            <Route path={routerNames.DETAILS} component={RelativeDetailsForm} />
            <Route path={routerNames.DELIVERY_ADDRESS} component={DeliveryAddress} />
            <Route path={routerNames.DELIVERY_DETAILS} component={DeliveryDetails} />
            <Route path={routerNames.MENU} component={Menu} />
            <Route path={routerNames.PRODUCT_DETAILS+'/:product_id'} component={ProductDetails} />
            <Route path={routerNames.ORDER_PLACED+'/:orderId'} component={OrderPlaced} />
            <Route path={routerNames.PAYMENT_DETAILS_SCHEDULER} component={DeliveryDetails} />

            {/* serenade routes */}

            <Route exact path='/serenade/valentine' component={SelectOccasion} />
            <Route path='/serenade/valentine/sender-info' component={UserForm} />
            <Route path='/serenade/valentine/receiver-info' component={ReceiverInfo} />
            {/* <Route path={routerNames.RECEIVER_ADDRESS} component={ReceiverAddress} /> */}
            <Route path='/serenade/valentine/choose-gifts' component={ChooseGifts} />
            <Route path='/serenade/valentine/menu' component={SerenadeMenu} />

            <Route path={routerNames.SERENADE_PRODUCT_DETAILS+'/:product_id'} component={SerenadeProductDetails} />
            <Route path={routerNames.ORDER_PLACED_SERENADE+'/:orderId'} component={OrderPlacedSerenade} />
            <Route path={routerNames.PAYMENT_DETAILS_SERENADE} component={ChooseGifts} />
        </Switch>
      </BrowserRouter>
    );
}

export default App;
