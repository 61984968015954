import React from 'react';
import { withRouter } from 'react-router-dom';
//import { routerNames } from '../../../config/config';
import '../../../index.css';
import './index.css';

const PlaceOrder = (props) => {
    return(
        <div id='bottom-btn' className="d-flex fixed-bottom fixed-button p-3 w-100">
            <div className='w-50 text-center'>
                <div className='total-price'>Total Rs {props.totalPrice - props.discount}</div>
                <div onClick={() => props.viewSummary("price-details")} className='summary'>View&nbsp;summary</div>
            </div>
            <div className='h-100 position-relative m-auto w-50'>
                <button 
                    disabled={!props.allGiftsAdded} 
                    onClick={props.handlePlaceOrder} 
                    className={!props.allGiftsAdded ? 'btn-next w-100 p-10 top-9 disable-btn': 'btn-next w-100 p-10 top-9'}
                >
                    Place&nbsp;Order
                </button>
            </div>
        </div>
    )
}

export default (withRouter(PlaceOrder));