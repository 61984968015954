import React from 'react';
import classnames from 'classnames';
import { fetchToken ,isEmpty, ucFirst, saveSessionStorage, getSessionStorage } from '../../../helpers';
import { Field, reduxForm } from 'redux-form';
import { MaterialTextField } from '../../../components/RenderField';
import { withRouter } from 'react-router-dom';
import { routerNames } from '../../../config/config';
import { axiosInstance } from '../../../config/axiosInstance';
import LoadingCircle from '../../../components/Loader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import './index.css';
import '../../../index.css';
import { pushToDataLayer } from "../../../GS_GTM/gtmFunctions"

const formFields = []
const specialFormat = /[!@#$%^*`()_+\-=\[\]{};':"\\|<>\/?]/;
let emojiFormat = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;

class DetailsForm extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            selectedOccasions : props.selectedOccasions,
            fetchingData      : false
        }
    }

    componentDidMount(){
        const selectedOccasions = getSessionStorage('selectedOccasions');
        if(selectedOccasions){
            this.setState({ selectedOccasions : selectedOccasions })
        }
        else {
            this.props.history.push(routerNames.SELECT_RELATION)
        }
        this.initializeForm();
    }

    componentDidUpdate(){
        this.initializeForm();
    }

    initializeForm = () => {
        let data = {};
        this.state.selectedOccasions && this.state.selectedOccasions.forEach(item => {
            data[item.relative.relation+'_'+item.relative.id] = item.name
        })
        this.props.initialize(data)
    }

    handleChange = (e,index,item) => {
        let arr = [...this.state.selectedOccasions];
        const relation1 = item.relative.relation;
        const id1 = item.relative.id;
        const isMultiple = item.relative.isMultiple;
        arr.forEach(element => {
            const relation2 = element.relative.relation;
            const id2 = element.relative.id
            if((relation1===relation2 && (id1===id2 || isMultiple==='0'))){
                element['name'] = e.target.value;
            }
        })
        this.setState({ selectedOccasions : arr });
    }

    handleSubmit = () => {
        const user = getSessionStorage('userInfo');
        const arr = [];
        this.state.selectedOccasions.forEach(item => {
            const obj = {
                relation : item.relative.id+'-'+item.relative.relation+'-'+item.relative.isMultiple,
                occasion : item.occasion,
                date     : item.date.date,
                name     : item.name
            }
            if(item.address){ obj.address = item.address }
            if(item.gift){ 
                obj.gift = {
                    nid           : item.gift.nid,
                    title         : item.gift.title,
                    price         : item.gift.price,
                    image         : item.gift.image,
                    doubleFlowers : item.doubleFlowers || item.gift.doubleFlowers,
                    addVase       : item.addVase || item.gift.addVase,
                    weightOfCake  : item.weightOfCake || item.gift.weightOfCake,
                    eggless       : item.eggless || item.gift.eggless,
                    messageOnCake : item.messageOnCake || item.gift.messageOnCake,
                    addFlowers    : item.addFlowers || item.gift.addFlowers
                }
            }
            arr.push(obj)
        })

        const data = {
            step       : 2,
            id         : user.id,
            user_email : user.user_email,
            data       : arr
        }

        this.updateOrderSchedular(data);
    }

    updateOrderSchedular = (data) => {
        this.setState({ fetchingData : true })
        axiosInstance.post(`updateOrderScheduler`, data, {
            headers: {
                'Auth': localStorage.getItem('Token'),
                'Content-Type':'application/json'
            }
        })
        .then(res => {
            this.setState({ fetchingData : false })
            if(res.data.statusCode===200){
                saveSessionStorage('selectedOccasions',this.state.selectedOccasions)
                pushToDataLayer('OnNameContinue')
                this.props.history.push(routerNames.DELIVERY_ADDRESS,{
                    selectedOccasions : this.state.selectedOccasions
                })
            } else if(res.data.statusCode==401){
                fetchToken("submit",this.handleSubmit);
            } else { toast("Oops Something went wrong, Please try again") }
        })
        .catch( e => {
            this.setState({ fetchingData : false })
            toast("Could not process request, Please try again");
        })
    }

    render(){
        const { handleSubmit } = this.props;
        let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        let disableNext = false
        const form = this.state.selectedOccasions && this.state.selectedOccasions.map((item,index) => {
            formFields.push(item.relative)
            const date = new Date(item.date.date)
            if(!item.name || specialFormat.test(item.name) || emojiFormat.test(item.name)){ disableNext = true }
            return(
                <div className={(item.relative.id > 100 && item.relative.isMultiple === '0') ? 'display-none':'form-section'} key={item.relative.relation+'_'+item.relative.id}>
                    <div className='occasion-name'>{item.relative.relation}'s {item.occasion} : {date.getDate()} {months[date.getMonth()]}, {date.getFullYear()}</div>
                    <div className="title-wrap mt-20">
                        <div className="field-wrap fullname-wrap">
                            <div className="form-group">
                                <Field
                                    name={item.relative.relation+'_'+item.relative.id}
                                    label={`${item.relative.relation}'s Name`}
                                    labelClassName={classnames({
                                        'form-label': true,
                                        'input-active': !isEmpty(this.state.selectedOccasions[index].name) ? true : false
                                    })}
                                    id={item.relative.relation+"_"+item.relative.id}
                                    type='text'
                                    onChange={e => this.handleChange(e,index,item)}
                                    className="form-input"
                                    component={MaterialTextField}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
        return(
            <div className='form-container'>
                <LoadingCircle load={this.state.fetchingData}/>
                <ToastContainer />
                <form className='mb-90' onSubmit={handleSubmit(this.handleSubmit)}>
                    {form}
                    <div id='bottom-btn' className='align-items-center d-flex fixed-bottom fixed-button w-100'>
                        <div className='text-center w-50'>{this.props.selectedOccasions && this.props.selectedOccasions.length} {this.props.selectedOccasions && this.props.selectedOccasions.length > 1 ? 'Deliveries' : 'Delivery'} Selected</div>
                        <div className="h-100 position-relative w-50">
                            <input disabled={disableNext} type="submit" className= {disableNext ? 'btn-next btn-next-small disable-next':'btn-next btn-next-small'} name="Next" value="Next" />
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}

const validate = values => {
    const errors = {};
    const fields = formFields;

    fields.forEach(field => {
        if (!values[field.relation+'_'+field.id]) {
            errors[field.relation+'_'+field.id] = `${ucFirst(field.relation)}'s name is required`;
        }
        if (specialFormat.test(values[field.relation+'_'+field.id])) {
            errors[field.relation+'_'+field.id] = `${ucFirst(field.relation)}'s name can't have special characters`;
        }
    })

    return errors;
}

export default reduxForm({
    form: 'DetailsForm',
    validate
})(withRouter(DetailsForm))