import React from 'react';
import classnames from 'classnames';
import { isEmpty, ucFirst, fetchToken, saveSessionStorage, getSessionStorage } from '../../../helpers';
import { Field, reduxForm } from 'redux-form';
import { MaterialTextField } from '../../../components/RenderField';
import { withRouter } from 'react-router-dom';
import { routerNames } from '../../../config/config';
import '../../../index.css';
import { axiosInstance, axiosInstanceGetCity } from '../../../config/axiosInstance';
import { ToastContainer, toast } from 'react-toastify';
import LoadingCircle from '../../../components/Loader';
import 'react-toastify/dist/ReactToastify.min.css';


class ReceiverForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: {
                streetAddress : '',
                pincode     : '',
                city        : '',
                state       : '',  
            },
            fetchingData : false,
            occasionList : []
        }
    }

    componentDidMount(){
        let occasionList = getSessionStorage('occasionList')
        const user = getSessionStorage('userInfoSerenade');
        if(occasionList && user){
            if(occasionList[0] && occasionList[0].address){
                const fields = {
                    streetAddress : occasionList[0].address.streetAddress,
                    pincode     : occasionList[0].address.pincode,
                    city        : occasionList[0].address.city,
                    state       : occasionList[0].address.state
                }
                this.setState({ fields: fields})
                this.props.initialize(fields);
            }
            this.setState({ occasionList: occasionList})
        } else {
            this.props.history.push(routerNames.USER_INFO_SERENADE)
        }
    }

    initializeForm = (pincode,city,state) => {
        const fields = {
            streetAddress : this.state.fields.streetAddress,
            pincode     : pincode,
            city        : city,
            state       : state
        }
        this.props.initialize(fields);
    }

    checkPincode = (value) => {
        this.setState({ fetchingData: true })
        axiosInstanceGetCity.get(``, {
            params : {
                pincode : value,
                all_c : 0,
                country_id: 0
            }
        })
        .then(res => {
            this.setState({ fetchingData: false })
            const { fields } = this.state;
            if(res.data && res.data.delivery_status){
                fields['city'] = res.data.city;
                fields['state'] = res.data.state;
            } else {
                fields['city'] = '';
                fields['state'] = '';
                toast("Sorry, We do not deliver in this area");
            }
            this.setState({ fields }, () => this.initializeForm(value,fields['city'],fields['state']));
        })
        .catch( e => { toast("Could not process request, Please try again") })
    }

    handleChange = ({ target: {type, value, name} }) => {
        const { fields } = this.state;
        fields[name] = value;
        if(name==='pincode' && value.length===6){
            this.checkPincode(value)
        }
        this.setState({ fields });
    }

    handleSubmit = () => {
        const user = getSessionStorage('userInfoSerenade');
        if(!user){
            this.props.history.push(routerNames.USER_INFO_SERENADE)
        } else {
            const arr = [];
            this.state.occasionList.forEach(item => {
                const obj = {
                    occasion : item.occasion, 
                    date: item.date, 
                    name: item.name, 
                    mobile: item.mobile,
                    image: item.image,
                    address: {
                        streetAddress : this.state.fields.streetAddress,
                        pincode     : this.state.fields.pincode,
                        city        : this.state.fields.city,
                        state       : this.state.fields.state,
                        mobile      : item.mobile,
                    },
                    gift : item.gift,
                    message: item.message
                }
                arr.push(obj)
            })

            const data = {
                step            : 1,
                id              : user.id,
                user_email      : user.user_email,
                data            : arr,
                order_type      : '2',
            }

            this.updateOrderSchedular(data);
        }
    }

    updateOrderSchedular = (data) => {
        this.setState({ fetchingData : true })

        axiosInstance.post(`updateOrderScheduler`, data, {
            headers: {
                'Auth'         : localStorage.getItem('Token'),
                'Content-Type' : 'application/json'
            }
        })
        .then(res => {
            this.setState({ fetchingData : false })
            if(res.data.statusCode===200){
                saveSessionStorage('occasionList',res.data.data)
                this.props.history.push(routerNames.CHOOSE_GIFTS_PAGE)
            } else if(res.data.statusCode==401){
                fetchToken("submit",this.handleSubmit);
            } else {
                toast("Oops Something went wrong, Please try again");
            }
        })
        .catch( e => {
            this.setState({ fetchingData : false })
            toast("Could not process request, Please try again");
        })
    }

    render() {
        const { streetAddress, pincode, city, state}  = this.state.fields;
        const { handleSubmit } = this.props;
        return (
            <div className="container user-form-wrapper userform-wrapper">
                <LoadingCircle load={this.state.fetchingData}/>
                <ToastContainer />
                <div className="left pt-2-">
                    <form className='serenade-user-info-form' onSubmit={handleSubmit(this.handleSubmit)}>
                        <div className="title-wrap-serenade">
                            <div className="fullname-wrap">
                                <div className="form-group">
                                    <Field
                                        name='streetAddress'
                                        label='Street Address'
                                        labelClassName={classnames({
                                            'form-label': true,
                                            'input-active': !isEmpty(streetAddress) ? true : false
                                        })}
                                        id="streetAddress-field"
                                        type='text'
                                        onChange={e => this.handleChange(e)}
                                        className="form-input serenade-form form-input-serenade"
                                        component={MaterialTextField}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="title-wrap-serenade">
                            <div className="mobile-wrap mobile">
                                <div className="form-group">
                                    <Field
                                        name='pincode'
                                        label='Pincode'
                                        labelClassName={classnames({
                                            'form-label': true,
                                            'input-active': !isEmpty(pincode) ? true : false
                                        })}
                                        id="pincode-field"
                                        type='number'
                                        onChange={e => this.handleChange(e)}
                                        className="form-input serenade-form form-input-serenade"
                                        component={MaterialTextField}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="title-wrap-serenade">
                            <div className="mobile-wrap mobile">
                                <div className="form-group">
                                    <Field
                                        name='city'
                                        label='City'
                                        labelClassName={classnames({
                                            'form-label': true,
                                            'input-active': !isEmpty(city) ? true : false
                                        })}
                                        id="city-field"
                                        type='text'
                                        //onChange={e => this.handleChange(e)}
                                        className="form-input serenade-form form-input-serenade"
                                        component={MaterialTextField}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="title-wrap-serenade">
                            <div className="mobile-wrap mobile">
                                <div className="form-group">
                                    <Field
                                        name='state'
                                        label='State'
                                        labelClassName={classnames({
                                            'form-label': true,
                                            'input-active': !isEmpty(state) ? true : false
                                        })}
                                        id="state-field"
                                        type='text'
                                        onChange={e => this.handleChange(e)}
                                        className="form-input serenade-form form-input-serenade"
                                        component={MaterialTextField}
                                    />
                                </div>
                            </div>
                        </div>
                        <div id='bottom-btn' className="align-items-center d-flex fixed-bottom fixed-button-serenade justify-content-around">
                            <p onClick={() => this.props.history.push(routerNames.CHOOSE_GIFTS_PAGE)} className='skip'>Skip for now</p>
                            <div className='h-100 position-relative m-auto w-55'>
                                <input type="submit" className="pri-button mt-11" name="Next" value="Next" />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

const validate = values => {
    const specialFormat = /[\[\]\\|<>]/;
    const specialFormat2 = /[#@*\[\]\\|<>\/]/;
    const emojiFormat = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;

    const errors = {};
    const fields = ['streetAddress', 'pincode', 'city', 'state'];
    fields.forEach(field => {
        if (!values[field]) {
            errors[field] = `${ucFirst(field)} is required`;
        }
    })

    if(values['pincode'] && (values['pincode'].length > 6 || values['pincode'].length < 6)){
        errors['pincode'] = `${ucFirst('pincode')} number is not valid`
    }

    if(specialFormat2.test(values['pincode'])){
        errors['pincode'] = `${ucFirst('pincode')} can't have special characters`
    }

    if(specialFormat2.test(values['city'])){
        errors['city'] = `${ucFirst('city')} can't have special characters`
    }

    if(specialFormat.test(values['streetAddress'])){
        errors['streetAddress'] = `${ucFirst('streetAddress')} can't have special characters`
    }

    if(emojiFormat.test(values['city'])){
        errors['city'] = `${ucFirst('city')} can't have emojis`
    }

    if(specialFormat2.test(values['state'])){
        errors['state'] = `${ucFirst('state')} can't have special characters`
    }

    if(emojiFormat.test(values['state'])){
        errors['state'] = `${ucFirst('state')} can't have emojis`
    }

    if(emojiFormat.test(values['streetAddress'])){
        errors['streetAddress'] = `${ucFirst('streetAddress')} can't have emojis`
    }

    return errors;
}

export default reduxForm({
    form: 'ReceiverForm',
    validate
})(withRouter(ReceiverForm))
