import React, { Component } from 'react';
import { routerNames } from '../../../config/config';
import Layout from '../../../components/Layout/container';
import Headline from '../../Information/components/Headline';
import Relative from './Relative';;

class SelectRelation extends Component {
    render(){
        return(
            <React.Fragment>
                <Layout page={routerNames.HOME}/>
                <Headline title='Who would you like to send gifts?'/>
                <Relative/>
            </React.Fragment>
        )
    }
}

export default SelectRelation;