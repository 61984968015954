import React from 'react';
import '../../../index.css';
import './index.css';

const PriceDetails = (props) => {
    const showPrices = props.selectedOccasions.map((item,index) => {
        if(item.gift){
            let giftPrice = 0;
            if((item.weightOfCake) && item.gift.type==='cake' && item.gift.attributes['10']){
                Object.keys(item.gift.attributes['10'].options).forEach(id => {
                    if(item.gift.attributes['10'].options[id].name===item.weightOfCake){
                        giftPrice = item.gift.attributes['10'].options[id].price
                    }
                })
            } 
            else { giftPrice = item.gift.price }

            let price = parseFloat(giftPrice);

            if(item.doubleFlowers || item.gift.doubleFlowers){
                price = price + parseFloat(item.doubleFlowers || item.gift.doubleFlowers);
            }
            if(item.addVase || item.gift.addVase){
                price = price + parseFloat(item.addVase || item.gift.addVase);
            }
            if(item.addGreetings || item.gift.addGreetings){
                price = price + 99;
            }
            if(item.eggless || item.gift.eggless) {
                price = price + parseFloat(item.eggless || item.gift.eggless);
            }
            if(item.addFlowers || item.gift.addFlowers) {
                price = price + parseFloat(item.addFlowers || item.gift.addFlowers);
            }

            return (
                <div
                    key={item.relative.relation+"-"+item.occasion}
                    className='d-flex justify-content-between pb-1'
                >
                    <span className='w-75 price-type'>{item.relative.relation}'s gift</span>
                    <span className='w-25 gift-amount text-left'>Rs. {parseFloat(price)}</span>
                </div>
            )
        }
         return null;
    })
    const walletDiscount = 0;
    const total = props.totalPrice - walletDiscount;
    return(
        <div className='p-3'>
            <h4 className='font-weight-bold'>Price&nbsp;Details</h4>
            <div className='d-flex justify-content-between pt-2 pb-2'>
                <span className='w-75 price-type'>Sub total</span>
                <span className='w-25 gift-amount text-left'>Rs. {parseFloat(props.totalPrice)}</span>
            </div>
            <div>
                {showPrices}
                {props.discount > 0 && props.allGiftsAdded && props.is_offer_enabled &&
                    <div className='d-flex justify-content-between'>
                        <span className='w-75 price-type'>Discount</span>
                        <span className='w-25 gift-amount text-left'>Rs. {parseFloat(props.discount)}</span>
                    </div>
                }
            </div>
            <div className='d-flex justify-content-between pt-2'>
                <span className='w-75 gift-amount font-weight-bold'>Total</span>
                <span className='w-25 gift-amount font-weight-bold text-left'>Rs. {parseFloat(total)-parseFloat(props.discount)}</span>
            </div>
            <div id={props.id} className='h-99'></div>
        </div>
    )
}

export default PriceDetails;