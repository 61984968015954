import React, { Fragment } from 'react';

import './index.css';
import './review.css';
import { BASE_URL } from '../../../config/config';
import {isEmpty} from '../../../helpers';
import ReviewCard from './ReviewCard';
const Reviews = (props) => {
  let reviewList = null;
  let reviewTitle = null;
  let reviewLink = null;

  if (props.reviews) {        
      reviewList = (        
        <Fragment>
          {            
            props.reviews.map((review) => {              
              return (
                <ReviewCard
                  key={+review.rid}
                  review={review}
                  isMobileReq={props.isMobileReq}
                />
              )
            })
          }
        </Fragment>
      )
  }
  reviewTitle = `Review For - ${props.productName}`;
  reviewLink = `${BASE_URL}/reviews/p/${props.productNid}/${(props.productName.split(' ').join('-')).toLowerCase()}`;  
  let isSchemaTagEnabled = false;
  if (!isEmpty(props.schemaTagsEnabled) && !isEmpty(props.isCityPageName)) {
    isSchemaTagEnabled = props.isCityPageName !== false ? props.schemaTagsEnabled : false;
  }

  return (
    <div 
    className={`product-reviews border-t`}>      
      <div className="view-product-review" {...(isSchemaTagEnabled && {itemType: 'https://schema.org/Product', itemScope: true})}>
        {isSchemaTagEnabled ? <meta itemProp="name" content={`Online ${props.isCityPageName.category} Delivery in ${props.isCityPageName.city}`} /> : null }
        <div className="review_box">
          <h2 className="margin-bottom-0">
            Review For
            <div className="review_product">
              {props.productName}
            </div>
          </h2>
          <div className="view-footer">
            <div className="view_footer_center">
              <a 
                className="btn review-link ui-link" 
                href={reviewLink}
                target="_self">
                View More
              </a>
            </div>
          </div>
        </div>
        
        <div className="cs-top-banner">
          <div className="rating-recommends">
            <div className="rating-section"  {...(isSchemaTagEnabled && { itemProp: 'aggregateRating', itemScope: true, itemType: 'https://schema.org/AggregateRating'})}>
              {(isSchemaTagEnabled && !isEmpty(props.reviewCount)) ? 
                <React.Fragment>  
                  <span itemProp="ratingValue" style={{display: 'none'}}>{props.rating || 4.5}</span>
                  <span itemProp="reviewCount" style={{display: 'none'}}>{props.reviewCount || Math.floor(Math.random() * 1000) }</span>
                </React.Fragment>
              : null }

              <p className="rating-recommends-result">
                {props.rating} out of 5
              </p>
            </div>
            <div className="recommends-section">
              <p className="rating-recommends-result">
                {props.recommend}% Recommended
              </p>
            </div>
          </div>
        </div>
        
        <div className="vertical-line vertical_line_reivews"><span></span></div>

        <div className="view-review-list">
          {            
            <div className="view-content">
              { reviewList }
            </div>            
          }                   
        </div>
      </div>
    </div>

  )
}

export default Reviews;
