export const GetParams = (paramName = '', locationSearch = null) => {
    let searchParam =  locationSearch === null ? window.location.search :  locationSearch;
    let returnParamValue = 0;

    if (searchParam === "") {
        return returnParamValue;
    }
    searchParam = searchParam.substring(1);
    const searchParamsInArray = searchParam.split("&");

    for (let index = 0; index < searchParamsInArray.length; index++) {
        const elementValueInArray = searchParamsInArray[index].split('=');

        if (elementValueInArray[0] === paramName) {
            returnParamValue = elementValueInArray[1];
            break;
        }
    }

    return returnParamValue;
}
