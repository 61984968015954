import React from 'react';
import Header from './Header';
import './index.css';

class LayoutComponent extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Header serenade={this.props.serenade} page={this.props.page}/>
            </React.Fragment>
        )
    }
}

export default LayoutComponent;
