import { combineReducers } from 'redux';
import Information from '../../routes/Information/reducers';
import SelectRelation from '../../routes/selectRelation/reducers';
import RelativeDetailsForm from '../../routes/RelativeDetailsForm/reducers';
import DeliveryAddress from '../../routes/DeliveryAddress/reducers';
import DeliveryDetails from '../../routes/DeliveryDetails/reducers';
import Menu from '../../routes/Menu/reducers';
import ProductDetails from '../../routes/ProductDetails/reducers';
import OrderPlaced from '../../routes/OrderPlaced/reducers';
import App from '../../components/Layout/reducers';
import { reducer as formReducer } from 'redux-form'

const rootReducer = combineReducers({
  Information,
  SelectRelation,
  RelativeDetailsForm,
  DeliveryAddress,
  DeliveryDetails,
  Menu,
  ProductDetails,
  OrderPlaced,
  App,
  form: formReducer
});

export default rootReducer;
