import React, { Component } from 'react'; 
import { Fragment } from 'react';
import Modal from 'react-modal';

import { isEmpty } from '../../../helpers';

const customStyles = {
  content : {
    top                   : 'auto',
    left                  : '0%',
    right                 : 'auto',
    bottom                : '0',
    marginRight           : '0',
    padding: '16px',
    borderRadius: '0'
  }
};

class ReviewCard extends Component {

  constructor() {
    super();
 
    this.state = {
      modalIsOpen: false
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }


  openModal() {    
    this.setState({modalIsOpen: true});
  }
  closeModal() {
    this.setState({modalIsOpen: false});
  }

  render() {
    const reviewData = this.props.review;
    let productRating = null;
    if (reviewData) {
      productRating = Array.from(Array(5).keys()).map((index) => {
        return <sup key={index} className={index + 1 <= +reviewData.product_rating ? 'fill-star' : 'unfill-star'}></sup>
      });
    }

    return (
      <>
        <div itemProp="review" itemScope itemType="http://schema.org/Review" className={+reviewData.uid_recommends === 1 ? 'review-list recommand-service' : 'review-list'}>
          <meta itemProp="name" content={reviewData.review_title} />
          <meta itemProp="datePublished" content={new Date(parseInt(reviewData.review_created)*1000).toISOString().slice(0,10)} />          
          <h3>
            {reviewData.author}
            {!isEmpty(reviewData.city) ? <span>({reviewData.city})</span> : ''}
          </h3>

          <div itemProp="reviewRating" itemScope itemType="http://schema.org/Rating" className="rating_review_recomend">
            <h6>Rating and Review</h6>
            <meta itemProp="worstRating" content="1" />
            <meta itemProp="ratingValue" content={reviewData.product_rating} />
            <meta itemProp="bestRating" content="5" />
            <div className="rating ratingstar">
              {productRating}              
            </div>
            {
              +reviewData.uid_recommends === 1 ? (
                <div className="recommended">
                  <span>Recommended</span>
                </div>
              ) : null
            }
          </div>

          <meta itemProp="author" content={reviewData.author} />

          <p itemProp="description" className="rating-desc">
            {
              this.props.isMobileReq === "false" ? (
                <samp>{reviewData.review_text}</samp>
              ) : (
                <Fragment>
                  {reviewData.review_text.length > 100 ? `${reviewData.review_text.substr(0, 100)}...` : reviewData.review_text}       
                  {reviewData.review_text.length > 100 ? <samp className="readMore" onClick={this.openModal}>Read more</samp> : ''}
                </Fragment>
              )
            }          
          </p>

         

          <Modal
            isOpen={this.state.modalIsOpen}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={this.closeModal}            
            contentLabel="reviewModal"
            style={customStyles}
          >
            <div className="full_reivew_nsg">
              <p>
                {reviewData.review_text}
              </p>              
            </div>
            <div className="closeBtnReview">
              <button onClick={this.closeModal}>close</button>
            </div>
          </Modal>
        </div>
      </>
    );
  }
}

export default ReviewCard;