import React, { Component } from 'react';
import Layout from '../../../components/Layout/container';
import ProductDescription from './ProductDescription';
import Banner from './Banner';
import PriceBox from './PriceBox';
import AddGift from './AddGift';
import { fetchToken, saveSessionStorage, getSessionStorage, removeSessionStorage } from '../../../helpers'
import { withRouter } from 'react-router-dom';
import { routerNames } from '../../../config/config';
import { axiosInstance, axiosInstanceProductDetails } from '../../../config/axiosInstance';
import { ToastContainer, toast } from 'react-toastify';
import LoadingCircle from '../../../components/Loader';
import { pushToDataLayer } from "../../../GS_GTM/gtmFunctions";  

class ProductDetails extends Component {
    constructor(props){
        super(props);
        const state = this.props.location.state
        const selectedOccasions = state ? state.selectedOccasions : getSessionStorage('selectedOccasions')
        const chosenRelative = state ? state.chosenRelative : getSessionStorage('chosenRelative')
        this.state = {
            selectedOccasions : selectedOccasions,
            chosenRelative    : chosenRelative,
            product           : {},
            fetchingData      : false,
            productPrice      : '',
            surcharge         : {},
            addSurcharge      : false,
            surchargePercent  : ''
        }
    }

    componentDidMount() {
        const product_id = this.props.match.params.product_id
        const selectedOccasions = getSessionStorage('selectedOccasions')
        const chosenRelative = getSessionStorage('chosenRelative')
        if(selectedOccasions && chosenRelative){
            selectedOccasions.forEach(item => {
                if(!item.name){
                    this.props.history.push(routerNames.DETAILS)
                }
            })
            this.setState({ fetchingData:true })
            axiosInstanceProductDetails.get(product_id)
            .then(res => {
                if(res.data.result.product.type==='cake' && res.data.result.product.attributes['10']){
                    const initialWeight = res.data.result.product.attributes['10'].options[Object.keys(res.data.result.product.attributes['10'].options)[0]].name
                    chosenRelative['weightOfCake'] = initialWeight 
                }

                this.setState({
                    product        : res.data.result.product,
                    fetchingData   : false,
                    chosenRelative : chosenRelative,
                    addSurcharge   : res.data.result.product.isValentineSurcharge == 0 ? false : true,
                }, () => this.surchargeData())
            })
            .catch( e => {
                this.setState({ fetchingData:false })
                toast("Could not load Items, Please check your internet connection");
            })
        }
        else {
            if(!selectedOccasions){
                this.props.history.push(routerNames.SELECT_RELATION)
            }
            else if(!chosenRelative){
                this.props.history.push(routerNames.DELIVERY_DETAILS)
            }
        }
    }

    surchargeData = () => {
        this.setState({ fetchingData:true })
        axiosInstance.get('getSurChargeData',{
            headers: {
                'Auth': localStorage.getItem('Token'),
                'Content-Type':'application/json'
            }
        })
        .then(res => {
            if(res.data && res.data.data) {
                this.setState({ surcharge : res.data.data, fetchingData:false })
            }
        })
        .catch( e => {
            this.setState({ fetchingData:false })
            toast("Could not load Items, Please check your internet connection");
        })
    }

    static getDerivedStateFromProps(props,state){
        let productPrice = '';
        let product = state.product
        if(product.type==='cake' && product.attributes['10'] && state.chosenRelative.weightOfCake){
            Object.keys(product.attributes['10'].options).forEach(id => {
                if(product.attributes['10'].options[id].name===state.chosenRelative.weightOfCake){
                    productPrice = product.attributes['10'].options[id].price
                }
            })
        } else { productPrice = product.price }

        const date = state.chosenRelative && new Date(state.chosenRelative.date.date);
        const index = state.chosenRelative && date.getDate();
        const month = state.chosenRelative && date.getMonth();
        let surchargePercent = 0;
        if(state.addSurcharge && month === 1 && state.surcharge[parseFloat(index)]){
            const surcharge              = state.surcharge[parseFloat(index)]
            surchargePercent             = surcharge
            productPrice                 = parseFloat(productPrice) + parseFloat(surcharge)*parseFloat(productPrice)
        }

        return {
            productPrice     : Math.floor(parseFloat(productPrice)),
            surchargePercent : surchargePercent
        }
    }

    handleAdditions = (e,key,price) => {
        const chosenRelative = Object.assign({}, this.state.chosenRelative);
        const newKey = key === 'Make it eggless' ? 'eggless' : 
                       key ==='Add Vase' ? 'addVase' : 
                      (key === 'Double the quantity of flowers' || key==='Double the flower' || key === 'Double the flower quantity') ? 'doubleFlowers' : 
                       key === 'Add 10 red roses' ? 'addFlowers' : "nullKey"
        if(e.target.checked){
            if(newKey==='doubleFlowers'&& this.state.addSurcharge){
                price = parseFloat(price) + Math.floor(parseFloat(this.state.surchargePercent)*parseFloat(price))
            }
            chosenRelative[newKey] = price;
        } else {
            chosenRelative[newKey] = 0;
        }

        this.setState({ chosenRelative : chosenRelative })
    }

    addGift = (product) => {
        removeSessionStorage('currentMenuCategory');
        removeSessionStorage('chosenRelative');
        const arr = [...this.state.selectedOccasions];
        const chosenRelative = Object.assign({}, this.state.chosenRelative);
        const user = getSessionStorage('userInfo');
        let index = -1;
        let arr1 = []
        arr.forEach((item,i) => {
            let productPrice = '';
            if(item.gift){
                if(item.gift.type==='cake' && item.weightOfCake && item.gift.attributes['10']){
                    Object.keys(item.gift.attributes['10'].options).forEach(id => {
                        if(item.gift.attributes['10'].options[id].name===item.weightOfCake){
                            productPrice = item.gift.attributes['10'].options[id].price
                        }
                    })
                } else { productPrice = item.gift.price }
            }
            const obj = {
                relation : item.relative.id+'-'+item.relative.relation+'-'+item.relative.isMultiple,
                occasion : item.occasion,
                date     : item.date.date,
                name     : item.name,
                address  : item.address ? item.address.address : '',
                gift     : item.gift ? {
                    nid           : item.gift.nid,
                    title         : item.gift.title,
                    price         : productPrice,
                    image         : item.gift.fullSizeImages ? item.gift.fullSizeImages[0] : item.gift.image,
                    doubleFlowers : item.doubleFlowers || item.gift.doubleFlowers || 0,
                    addVase       : item.addVase || item.gift.addVase || 0,
                    addFlowers    : item.addFlowers || item.gift.addFlowers || 0,
                    weightOfCake  : item.weightOfCake || item.gift.weightOfCake || 0,
                    eggless       : item.eggless || item.gift.eggless || 0,
                    messageOnCake : item.messageOnCake || item.gift.messageOnCake || '',
                    type          : item.gift.type

                } : "not chosen yet"
            }
            if(obj.address===''){
                delete obj.address
            }
            if(chosenRelative.relative.relation===item.relative.relation && chosenRelative.relative.id===item.relative.id && chosenRelative.occasion===item.occasion){
                index = i;
                obj.gift = {
                    nid           : product.nid,
                    title         : product.title,
                    price         : this.state.productPrice,
                    image         : this.state.product.fullSizeImages[0],
                    doubleFlowers : chosenRelative.doubleFlowers || 0,
                    addVase       : chosenRelative.addVase || 0,
                    addFlowers    : chosenRelative.addFlowers || 0,
                    weightOfCake  : chosenRelative.weightOfCake || 0,
                    eggless       : chosenRelative.eggless || 0,
                    messageOnCake : chosenRelative.messageOnCake || '',
                    type          : product.type
                }
            }
            arr1.push(obj)
        })

        let product1 = {
            attributes    : product.attributes,
            nid           : product.nid,
            title         : product.title,
            price         : this.state.productPrice,
            image         : this.state.product.fullSizeImages[0],
            doubleFlowers : chosenRelative.doubleFlowers || 0,
            addVase       : chosenRelative.addVase || 0,
            addFlowers    : chosenRelative.addFlowers || 0,
            weightOfCake  : chosenRelative.weightOfCake || 0,
            eggless       : chosenRelative.eggless || 0,
            messageOnCake : chosenRelative.messageOnCake || '',
            type          : product.type
        }
        
        chosenRelative['gift'] = product1;
        arr[index] = chosenRelative;

        const data = {
            step       : 4,
            id         : user.id,
            user_email : user.user_email,
            data       : arr1
        }
        this.updateOrderSchedular(data,arr)
    }

    updateOrderSchedular = (data,arr) => {
        this.setState({ fetchingData: true })
        axiosInstance.post(`updateOrderScheduler`, data, {
            headers: {
                'Auth': localStorage.getItem('Token'),
                'Content-Type':'application/json'
            }
        })
        .then(res => {
            this.setState({ fetchingData: false })
            if(res.data.statusCode===200){
                saveSessionStorage('selectedOccasions',arr)
                pushToDataLayer('OnAddGift')
                this.props.history.push(routerNames.DELIVERY_DETAILS,{
                    selectedOccasions : arr,
                    is_offer_enabled  : res.data.is_offer_enabled
                })
            } else if(res.data.statusCode==401){
                fetchToken("submit",this.addGift)
            } else {
                this.setState({ fetchingData: false })
                toast("Oops Something went wrong, Please try again");
            }
        })
        .catch( e => { toast("Could not process request, Please try again") })
    }

    handleCakeWeight = (value) => {
        const chosenRelative = Object.assign({}, this.state.chosenRelative);
        chosenRelative['weightOfCake'] = value
        this.setState({ chosenRelative : chosenRelative })
    }

    handleMessageOnCake = (e) => {
        const chosenRelative = Object.assign({}, this.state.chosenRelative);
        chosenRelative['messageOnCake'] = e.target.value
        if(e.target.value.length <= 25){
            this.setState({ chosenRelative : chosenRelative })
        }
    }

    render(){
        return(
            <React.Fragment>
                <ToastContainer />
                <LoadingCircle load = { this.state.fetchingData }/>
                <Layout page = { routerNames.MENU }/>
                <Banner images = { this.state.product.fullSizeImages }/>
                <PriceBox 
                    product             = { this.state.product } 
                    handleAdditions     = { this.handleAdditions } 
                    handleMessageOnCake = { this.handleMessageOnCake }
                    chosenRelative      = { this.state.chosenRelative }
                    handleCakeWeight    = { this.handleCakeWeight }
                    setProductPrice     = { this.setProductPrice }
                    productPrice        = { this.state.productPrice }
                    surcharge           = { this.state.surcharge }
                    addSurcharge        = { this.state.addSurcharge }
                    surchargePercent    = { this.state.surchargePercent }
                />
                <ProductDescription product = { this.state.product }/>
                <AddGift addGift={ () => this.addGift(this.state.product) }/>
            </React.Fragment>
        )
    }
}

export default (withRouter(ProductDetails));