import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import rectangle from '../../../components/Layout/components/images/rectangle.svg';
import './index.css';
import '../../../index.css';

class ProductDescription extends Component {
    render(){
        const product = this.props.product;
        return(
            <div className='p-3 mb-65 border-t'>
                <div className='light-heading pb-2'>Product Contains</div>
                <div className='d-flex pb-3 align-items-baseline'>
                    <img src={rectangle} alt='rectangle' />
                    <span className='pl-3 product-title'>{product.invoiceDescription}</span>
                </div>
                <div className='light-heading pb-2'>Product Description</div>
                <div className='product-title'>{product.description}</div>
            </div>
        )
    }
}

export default (withRouter(ProductDescription));