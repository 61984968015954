import React from 'react';
import tick from '../../../components/Layout/components/images/group.svg';
import remove from '../../../components/Layout/components/images/remove.svg';
import pending from '../../../components/Layout/components/images/info-button (1).svg';
import './index.css';
import { removeSessionStorage } from '../../../helpers';

const OrderStatus = (props) => {
    let occasionList = props.occasionList
    let urlSearchObj = {};
    let image = null;
    let text = "";
    let subText = "";
    let imageClass = ""
    let style = {}
    const params = new URLSearchParams(props.location.search)
    let status_id = params.get('status_id') || 0;
    if(status_id==21){
        image = tick;
        text = "ORDER SUCCESSFUL"
        subText = "Please add your special note for respective orders below"
        imageClass = "check-mark"
        style = {
            background : '#199100'
        }
        removeSessionStorage('occasionList')
    }
    else if(status_id==28 || status_id==23){
        image = pending;
        text = "ORDER PENDING"
        subText = "There is an issue with your order. We will contact you soon"
        imageClass = "pending-mark"
        style = {
            background : '#ffffff'
        }
    }
    else{
        image = remove;
        text = "ORDER FAILED"
        subText = "There is an issue with your order please try again"
        imageClass = "failed-mark"
        style = {
            background : '#ffffff'
        }
    }
    return(
        <div className='background-white m-3 p-4 order-status-serenade'>
            <div style={style} className="order-tick">
                <img className={imageClass} src={image} alt="tick"/>
            </div>
            <div className='p-10 pt-15 text-center'>
                <h4 className='font-weight-bold'>{text}</h4>
                <div>{subText}</div>
            </div> 
            {occasionList && occasionList[0] && !occasionList[0].address && text==='ORDER SUCCESSFUL' &&
                <div className='text-center'>
                    <button onClick={props.handleAddressModal} className='order-placed-address-btn'>ADD ADDRESS</button>
                </div>}
        </div>
    )
}

export default OrderStatus;