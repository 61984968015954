import React from 'react';
import { isEmpty, ucFirst, fetchToken, saveSessionStorage, getSessionStorage } from '../../../helpers';
import { withRouter } from 'react-router-dom';
import { routerNames } from '../../../config/config';
import classnames from 'classnames';
import '../../../index.css';
import { axiosInstance, axiosInstanceCoupon, axiosInstanceGetCity } from '../../../config/axiosInstance';
import { Field, reduxForm } from 'redux-form';
import { MaterialTextField } from '../../../components/RenderField';
import Modal from 'react-modal';
import SlidingPane from 'react-sliding-pane';
import { ToastContainer, toast } from 'react-toastify';
import LoadingCircle from '../../../components/Loader';
import 'react-toastify/dist/ReactToastify.min.css';
import bin from '../../../components/Layout/components/images/delete-button.svg';
import plus from '../../../components/Layout/components/images/plus-sign-in-circle.png';
import error from '../../../components/Layout/components/images/error.svg';
import { pushToDataLayer } from "../../../GS_GTM/gtmFunctions";
import {GetParams} from '../../../helpers/URLParms'

class ChooseGiftsPage extends React.Component {

    state = {
        occasionList : [],
        fetching: false,
        couponCode: '',
        isCouponApplied: false,
        couponMessage: "",
        isMessageAdded : false,
        showModal: false,
        modalForIndex: null,
        fields : {
            streetAddress: "",
            pincode: "",
            city: "",
            state: "",
            deliveryCharges: 0
        },
        sameAddressForAllChecked: false,
        couponDiscount: '',
        isPincodeValid: true
    }

    componentDidMount(){
        Modal.setAppElement(this.el);
        const arr = this.props.location.pathname.split('/')
        const pageName = arr[arr.length-1]
        const occasionList = getSessionStorage('occasionList')
        if(!occasionList){
            if(pageName==='direct-payment'){
                this.paymentDetails()
            } else {
                this.props.history.push(routerNames.USER_INFO_SERENADE)
            }
        } else {
            occasionList.forEach(item => {
                if(item.address){
                    const fields = {
                        streetAddress   : item.address.streetAddress,
                        pincode         : item.address.pincode,
                        city            : item.address.city,
                        state           : item.address.state,
                        deliveryCharges : item.address.deliveryCharges 
                    }
                    this.setState({ fields: fields })
                    this.props.initialize(fields);
                }
            })

            this.setState({ occasionList })
        }
    }

    paymentDetails = () => {
        this.setState({ fetching: true })
        fetchToken("submit",this.getOrderDetailAPI)
    }

    getOrderDetailAPI = () => {
        const params = new URLSearchParams(this.props.location.search)
        const id = params.get('id')
        const hash = params.get('hash')
        this.setState({ fetching: true })
        
        axiosInstance.post("orderDetail", {id: id, hash_key: hash, order_type: '2'}, {
            headers: {
                'Auth': localStorage.getItem('Token'),
                'Content-Type':'application/json'
            }
        })
        .then(res => {
            if(res.data && res.data.data){
                const data = []
                res.data.data.forEach((item,index) => {
                    let obj = {
                        date : item.date,
                        name: item.name,
                        occasion: item.occasion,
                        gift: item.gift,
                        order_id: item.order_id,
                        mobile: item.mobile,
                        image: item.image
                    }
                    if(item.address){
                        obj.address = item.address
                    }
                    if(item.message){
                        obj.message = item.message
                    }
                    data.push(obj)
                })
                saveSessionStorage('userInfoSerenade', { id: id, user_email: res.data.user_email })
                saveSessionStorage('occasionList', data)
                this.setState({ 
                    occasionList : data,  
                    fetching : false,
                    couponDiscount : res.data.coupon_data !== null ? Math.floor(parseFloat(res.data.coupon_data.amount)) : 0,
                    couponCode : res.data.coupon_data !== null ? res.data.coupon_data.code : ''
                })
            } else {
                this.setState({ fetching: false })
                toast("Oops Something went wrong, Please try again");
            }
        })
        .catch(e => {
            this.setState({ fetching: false })
            toast("Oops Something went wrong, Please try again");
        })
    }

    scrollToBottom = () => {
        const element = document.getElementById('gift-summary')
        element.scrollIntoView({behavior: "smooth", block: "start", inline: "start"})
    }

    handleCouponCode = (e) => {
        this.setState({ couponCode: e.target.value })
    }

    applyCoupon = (value) => {
        if(value===true) {
            const user = getSessionStorage('userInfoSerenade')
            if(!user){
                this.props.history.push(routerNames.USER_INFO_SERENADE)
            } else {
                const fa_source = GetParams("fa_source", this.props.location.search)
                const data = {
                    code : this.state.couponCode,
                    email : user.user_email,
                    order_id : user.id,
                    calling_media: 'scheduler',
                    fa_source: fa_source === "app" ? "app" : "web" 
                }
                this.setState({ fetching:true })
                axiosInstanceCoupon.get(``,{
                    params: data
                })
                .then(res => {
                    if(res.data.success){
                        this.setState({ 
                            couponDiscount : Math.floor(parseFloat(res.data.data.amount)),
                            isCouponApplied : true,
                            couponMessage : res.data.message,
                            fetching      : false
                        })
                    } else if(res.data.error){
                        this.setState({ 
                            couponDiscount : 0,
                            isCouponApplied : false,
                            couponMessage : res.data.error_message,
                            couponCode: '',
                            fetching : false
                        })
                    }
                })
                .catch( e => {
                    this.setState({ fetching : false })
                    toast("Oops, Something went wrong please try again...");
                })
            }
        } else {
            this.setState({ 
                couponDiscount : 0,
                isCouponApplied : false,
                couponMessage : "",
                couponCode: ""
            })
        }
    }

    removeModal = () => {
        this.setState({ showModal: false })
    }

    sameAddressForAll = (e) => {
        this.setState ({ sameAddressForAllChecked : e.target.checked})
    }

    handlePersonalMessage = (e) => {
        let occasionList = getSessionStorage('occasionList')
        let ele = Object.assign({},occasionList[this.state.modalForIndex])
        ele.message = e.target.value
        occasionList[this.state.modalForIndex] = ele
        this.setState({ occasionList })
    }

    savePersonalMessage = () => {
        let occasionList = [...this.state.occasionList]
        if(occasionList[this.state.modalForIndex].message && occasionList[this.state.modalForIndex].message!==''){
            const user = getSessionStorage('userInfoSerenade');
            if(!user){
                this.props.history.push(routerNames.USER_INFO_SERENADE)
            } else {
                const data = {
                    step            : 4,
                    id              : user.id,
                    user_email      : user.user_email,
                    data            : occasionList,
                    order_type      : '2',
                }
        
                this.updateOrderSchedular(data)
            }
        } else {
            this.setState({ showModal: false })
        }
    }

    removePersonalMessage = (index) => {
        let occasionList = getSessionStorage('occasionList')
        const user = getSessionStorage('userInfoSerenade');
        if(!user){
            this.props.history.push(routerNames.USER_INFO_SERENADE)
        } else {
            let ele = Object.assign({},occasionList[index])
            delete ele.message
            occasionList[index] = ele
            saveSessionStorage('occasionList',occasionList)
            this.setState({ occasionList })

            const data = {
                step            : 4,
                id              : user.id,
                user_email      : user.user_email,
                data            : occasionList,
                order_type      : '2',
            }
            
            this.updateOrderSchedular(data)
        }
    }

    handleAddressChange = ({ target: {type, value, name} }) => {
        const { fields } = this.state;
        fields[name] = value;
        if(name==='pincode' && value.length===6){
            this.checkPincode(value)
        }
        this.setState({ fields });
    }

    checkPincode = (value) => {
        this.setState({ fetching: true })
        axiosInstanceGetCity.get(``, {
            params : {
                pincode : value,
                all_c : 0,
                country_id: 0
            }
        })
        .then(res => {
            this.setState({ fetching: false })
            const { fields } = this.state;
            if(res.data && res.data.delivery_status){
                fields['city'] = res.data.city;
                fields['state'] = res.data.state;
                fields['deliveryCharges'] = res.data.cost;
                const domain = '.'+window.location.hostname;
                document.cookie = `fc_cn=${res.data.tid}; domain=${domain}`
                document.cookie = `user_select_city=${res.data.city}; domain=${domain}`
                this.setState({ fields:fields, isPincodeValid: true }, () => this.initializeForm(value,fields['city'],fields['state']));
            } else {
                fields['city'] = '';
                fields['state'] = '';
                fields['deliveryCharges'] = 0;
                //toast("Sorry, We do not deliver in this area");
                this.setState({ fields:fields, isPincodeValid: false }, () => this.initializeForm(value,fields['city'],fields['state']));
            }
            pushToDataLayer('pincodeCheckSerenade',{},{},{
                event: "clickEvent",
                eventCategory: "serenade checkout - pincode status",
                eventAction: res.data && res.data.delivery_status ? true : false,
                eventLabel: value
            })
        })
        .catch( e => { toast("Could not process request, Please try again") })
    }

    initializeForm = (pincode,city,state) => {
        const fields = {
            streetAddress : this.state.fields.streetAddress,
            pincode     : pincode,
            city        : city,
            state       : state,
        }
        this.props.initialize(fields);
    }

    saveAddress = () => {
        let occasionList = getSessionStorage('occasionList')
        const user = getSessionStorage('userInfoSerenade');
        if(!user){
            this.props.history.push(routerNames.USER_INFO_SERENADE)
        } else {
            if(this.state.sameAddressForAllChecked){
                occasionList.forEach((item,i) => {
                    let ele = Object.assign({},occasionList[i])
                    ele.address = this.state.fields
                    ele.address.mobile = item.mobile
                    occasionList[i] = ele
                })
            } else {
                let ele = Object.assign({},occasionList[this.state.modalForIndex])
                ele.address = this.state.fields
                ele.address.mobile = ele.mobile
                occasionList[this.state.modalForIndex] = ele
            }
            this.setState({ showModal: false, sameAddressForAllChecked: false })

            const data = {
                step            : 4,
                id              : user.id,
                user_email      : user.user_email,
                data            : occasionList,
                order_type      : '2',
            }

            this.updateOrderSchedular(data)
        }
    }

    deleteEvent = () => {
        let occasionList = getSessionStorage('occasionList');
        const user = getSessionStorage('userInfoSerenade');
        if(!user){
            this.props.history.push(routerNames.USER_INFO_SERENADE)
        } else {
            occasionList.splice(this.state.modalForIndex,1);
            saveSessionStorage('occasionList',occasionList);
            this.setState({ occasionList })
            this.setState({ showModal: false });
            const data = {
                step            : 4,
                id              : user.id,
                user_email      : user.user_email,
                data            : occasionList,
                order_type      : '2',
            }
            this.applyCoupon(false)
            this.updateOrderSchedular(data)
        }
    }

    updateOrderSchedular = (data) => {
        this.setState({ fetching : true })
        
        if (data.data && data.data.length > 0) {
            let label = 'Update - ';
            data.data.forEach(item => {
                label = label+item.occasion+' - '
            })

            pushToDataLayer('OnDeleteSelectedDay',{},{},{
                event: "clickEvent",
                eventCategory: "Serenade",
                eventAction: "Updated Days",
                eventLabel: label
            })
        }

        axiosInstance.post(`updateOrderScheduler`, data, {
            headers: {
                'Auth'         : localStorage.getItem('Token'),
                'Content-Type' : 'application/json'
            }
        })
        .then(res => {
            this.setState({ fetching : false })
            if(res.data.statusCode===200){
                saveSessionStorage('occasionList',res.data.data)
                this.setState({ showModal: false, occasionList: res.data.data })

                if(res.data.data && res.data.data.length === 0){
                    this.props.history.push(routerNames.OCCASION_LIST)
                }
            } else if(res.data.statusCode==401){
                fetchToken("submit",this.handleSubmit);
            } else {
                toast("Oops Something went wrong, Please try again");
            }
        })
        .catch( e => {
            this.setState({ fetching : false })
            toast("Could not process request, Please try again");
        })
    }

    addGiftClick = (item,index) => {        
        let occasionList = [...this.state.occasionList]
        const newItem = Object.assign({},item)
        newItem.giftIndex = index;
        delete newItem.gift;
        delete newItem.doubleFlowers;
        delete newItem.addVase;
        delete newItem.addFlowers;
        delete newItem.weightOfCake;
        delete newItem.eggless;
        delete newItem.messageOnCake;
        occasionList[index] = newItem
        pushToDataLayer('OnNextSerenade',{},{},{
            event         : "clickEvent",
            eventCategory : "Serenade",
            eventAction   : "choose-gifts",
            eventLabel    : "choose-gifts",
            occasion      : item.occasion
        })
        this.setState({occasionList}, () => {
            saveSessionStorage('currentItemIndex',newItem);
            saveSessionStorage('currentChooseItemIndex',index);
            this.props.history.push(routerNames.SERENADE_MENU)
        })
    }

    handlePlaceOrder = (totalPrice) => {
        const arr = [...this.state.occasionList]
        const user = getSessionStorage('userInfoSerenade');
        if(!user){
            this.props.history.push(routerNames.OCCASION_LIST)
        } else {
            const arr1 = []
            arr.forEach(item => {
                arr1.push(item.gift.nid)
            })

            const data = {
                step       : 5,
                id         : user.id,
                user_email : user.user_email,
                data       : arr1,
                total      : totalPrice,
                coupon     : this.state.couponCode,
                order_type : '2',
            }

            this.setState({ fetching: true })

            axiosInstance.post(`checkCartData`, data, {
                headers: {
                    'Auth'         : localStorage.getItem('Token'),
                    'Content-Type' :'application/json'
                }
            })
            .then(res => {
                this.setState({ fetching: false })
                if(res.data && res.data.statusCode==200 && res.data.data){
                    pushToDataLayer('OnNextSerenade',{},{},{
                        event: "clickEvent",
                        eventCategory: "Serenade",
                        eventAction: "choose-gifts",
                        eventLabel: "direct-payment"
                    })
                    window.location.replace(res.data.data)
                } else if(res.data.statusCode==401){
                    fetchToken("submit",this.handlePlaceOrder)
                } else if(res.data.statusCode==400) {
                    toast("Oops Something went wrong, Please try again");
                }
            })
            .catch( e => { toast("Could not process request, Please try again") })
        }
    }


    render(){
        const arr = this.props.location.pathname.split('/')
        const pageName = arr[arr.length-1]
        let disable = false
        let occasionList = getSessionStorage('occasionList')
        let total = 0;
        let keyIndex = ''
        let deliveryCharges = 0;
        const list = occasionList && occasionList.map((item,index) => {
            if(!item.gift){ disable = true }
            keyIndex = 'scroll_item_'+index
            return(                
                <div key={item.occasion+index} className={'m-3 delivery '+keyIndex} >
                    <div className='align-items-center d-flex justify-content-between p-10 border-bottom-gift'>
                        <p className='occasion-detail'><img src={item.image} alt='im-g'/> {item.occasion} ( {item.date.split('-')[2]}th Feb {item.date.split('-')[0]} )</p>
                        {pageName === 'choose-gifts' && <img onClick={() => this.setState({ showModal: 'delete', modalForIndex: index })} src={bin} alt='delete'/>}
                    </div>
                    <div className='border-bottom-gift'>
                        <p className='align-items-center d-flex justify-content-between m-0 p-10 pb-0'>
                            <span className='name'>{item.name}</span>
                            {pageName === 'choose-gifts' && item.address && <span onClick={() => {
                                this.setState({
                                    showModal: 'address', 
                                    modalForIndex: index, 
                                    fields: item.address
                                }, () => this.initializeForm(item.address.pincode,item.address.city,item.address.state))
                            }} className='edit'>EDIT ADDRESS</span>}
                        </p>
                        <p className='m-0 pl-2 mobile-gift'>{item.mobile}</p>
                        {item.address && <div className='address p-10'>{item.address.streetAddress}, {item.address.city}, {item.address.state} {item.address.pincode}</div>}
                        {pageName === 'choose-gifts' && item.gift && !item.message && <div onClick={() => this.setState({ showModal: 'message', modalForIndex: index })} className='edit m-2 p-2 text-center personal-message'>ADD PERSONAL MESSAGE</div>}
                        {pageName === 'choose-gifts' && item.gift && item.message && 
                            <div className='m-2 personal-message'>
                                <div className='p-2 font-size-13'>
                                    <p>{item.message}</p>
                                    <p className='m-0 edit'>
                                        <span onClick={() => this.setState({ showModal: 'message', modalForIndex: index })}>EDIT</span>
                                        <span onClick={() => this.removePersonalMessage(index)} className='ml-3'>REMOVE</span>
                                    </p>
                                </div>
                            </div>
                        }
                    </div>
                    {item.gift ? <div>
                            <div className='d-flex pt-4 pb-4 border-gift pl-2 pr-2'>
                            <div>
                                <img className='image-size' src={item.gift.image || (item.gift.fullSizeImages && item.gift.fullSizeImages[0])} alt='cake'/>
                            </div>
                            <div className='pl-4 w-75'>
                                <div className='d-flex justify-content-between'>
                                    <span className='name'>{item.gift.title}</span>
                                </div>
                                <div className='gift-price pt-1 name'>Rs {parseFloat(item.gift.price)}</div>
                                {item.gift.type==='cake' && <div className='font-12'>{item.weightOfCake || item.gift.weightOfCake}</div>}
                                {/* <div className='pt-1'>
                                    <label className="container-check name">Add greeting @ <strong>99</strong> 
                                        <input type="checkbox" name='add99' />
                                        <span className="checkmark"></span>
                                    </label>
                                </div> */}
                                {pageName === 'choose-gifts' && <p className='m-0 edit pt-2'>
                                    <span onClick={() => this.addGiftClick(item,index)}>CHANGE</span>
                                </p>}
                            </div>
                        </div>
                        <div className='d-flex flex-wrap justify-content-between p-10'>
                        {item.gift.doubleFlowers > 0 && <p className='attributes1 m-0'>&#9679;&nbsp;Double flowers @Rs {parseFloat(item.gift.doubleFlowers)}</p>}
                        {item.gift.eggless > 0 && <p className='attributes2 m-0'>&#9679;&nbsp;Eggless Cake @Rs {parseFloat(item.gift.eggless)}</p>}
                        {item.gift.addVase > 0 && <p className='attributes2 m-0'>&#9679;&nbsp;Add Vase @Rs {parseFloat(item.gift.addVase)}</p>}
                        {item.gift.addFlowers > 0 && <p className='attributes2 m-0'>&#9679;&nbsp;Add Flowers @Rs {parseFloat(item.gift.addFlowers)}</p>}
                        {item.address && item.address.deliveryCharges > 0 && <p className='attributes1 m-0'>&#9679;&nbsp;Delivery Charges @Rs {parseFloat(item.address.deliveryCharges)}</p>}
                        </div>
                    </div>
                    :
                    <div>
                        {pageName === 'choose-gifts' && <div onClick={() => this.addGiftClick(item, index)} className='p-10 text-center choose-gift-serenade'><img src={plus}/> Choose Gift</div>}
                    </div>}
                </div>
            )
        })

        const summary = this.state.occasionList.map(item => {
            const price = item.gift ? (parseInt(item.gift.price) + parseInt(item.gift.addVase) + parseInt(item.gift.addFlowers) + parseInt(item.gift.doubleFlowers) + parseInt(item.gift.eggless)) : 0
            total = total + price;
            if(item.address.deliveryCharges){
                deliveryCharges = deliveryCharges + item.address.deliveryCharges
            }
            return(
                <div key={item.occasion}>
                    {item.gift ? 
                        <div className='summary-detail'>
                            <p className='summary-title'>
                                <span>{item.gift.title}</span>
                                <span>Rs {price}</span>
                            </p>
                            <p className='summary-occasion'>{item.occasion}</p>
                        </div> : null
                    }
                </div>
            )
        })
        
        return(
            <div ref={ref => this.el = ref}>
                <LoadingCircle load={this.state.fetching}/>
                <ToastContainer />
                <SlidingPane
                    isOpen={this.state.showModal!==false}
                    from='bottom'
                    className='slide-pane'
                    ariaHideApp={false}
                >
                    {this.state.showModal==='delete' && 
                        <ConfirmDelete
                            removeModal={this.removeModal} 
                            deleteEvent={this.deleteEvent}
                    />}
                    {this.state.showModal==='message' && 
                        <MessageBox 
                            item={this.state.occasionList[this.state.modalForIndex]}
                            removeModal={this.removeModal} 
                            savePersonalMessage={this.savePersonalMessage} 
                            handlePersonalMessage={this.handlePersonalMessage}
                    />}
                    {this.state.showModal==='address' && 
                        <AddressBox 
                            handleSubmit={this.props.handleSubmit} 
                            sameAddressForAll={this.sameAddressForAll} 
                            handleAddressChange={this.handleAddressChange} 
                            saveAddress={this.saveAddress} 
                            fields={this.state.fields} 
                            removeModal={this.removeModal}
                            length={this.state.occasionList.length}
                            isPincodeValid={this.state.isPincodeValid}
                    />}
                </SlidingPane>
                <div>
                    { list }
                </div>
                {pageName === 'choose-gifts' && !disable && <div className='coupon-code'>
                    <div className='coupon-code-inside'>
                        <input onChange={this.handleCouponCode} value={this.state.couponCode} className='coupon-input' type='text' placeholder='Enter Coupon Code'/>
                        {this.state.isCouponApplied ? 
                            <div onClick={() => this.applyCoupon(false)} className='apply-coupon red-col'>REMOVE</div> : 
                            <div onClick={() => this.applyCoupon(true)} className='apply-coupon'>APPLY NOW</div>}
                    </div>
                    <p className={this.state.isCouponApplied ? 'coupon-message color-green' : 'coupon-message red-col'}>{this.state.couponMessage}</p>
                </div>}
                {!disable && <div className='summary-box'>
                    <h5 className='summary-heading'>Price Summary</h5>
                    {summary}
                    <div className='summary-detail border-bottom-gift'>
                        {this.state.couponDiscount > 0 && <p className='summary-title pb-3'>
                            <span>Discount</span>
                            <span className='color-green'>{this.state.couponDiscount}</span>
                        </p>}
                        {deliveryCharges > 0 && <p className='summary-title pb-3'>
                            <span>Delivery Charge</span>
                            <span className='color-green'>{deliveryCharges}</span>
                        </p>}
                    </div>
                    <div className='summary-detail'>
                        <p id = 'gift-summary' className='summary-title summary-total'>
                            <span>Total</span>
                            {/* <span>&#8377;  {total-this.state.couponDiscount + deliveryCharges}</span> */}
                            <span>Rs  {total-this.state.couponDiscount + deliveryCharges}</span>
                        </p>
                    </div>
                </div>}
                <div id='bottom-btn' className="fixed-bottom fixed-button-serenade w-100 show-flex">
                    {!disable && 
                        <div className='w-30 text-center'>
                            <p className='total-price'>Rs {total-this.state.couponDiscount+deliveryCharges}</p>
                            <p className='view-breakup' onClick={this.scrollToBottom}>View Breakup</p>
                        </div>
                    }
                    <div className={disable ? 'h-100 position-relative m-auto w-80' : 'h-100 position-relative m-auto w-60'}>
                        <button onClick={() => this.handlePlaceOrder(total-this.state.couponDiscount+deliveryCharges)} disabled={disable} className={disable ? 'btn-next btn-next-disable w-100' : 'btn-next w-100 enable-padding'}>Pay</button>
                    </div>
                </div>
            </div>
        )
    }
}

const ConfirmDelete = (props) => {
    return(
        <div className='confirm-delete'>
            <p>Do you want to delete this event?</p>
            <div className='align-items-center d-flex justify-content-between'>
                <button className='delete-modal-btn' onClick={props.deleteEvent}>DELETE</button>
                <button onClick={props.removeModal} className='delete-modal-btn cancel-modal-btn'>CANCEL</button>
            </div>
        </div>
    )
}

const MessageBox = (props) => {
    const specialFormat2 = /[#@*\[\]\\|<>\/]/;
    const emojiFormat = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
    const disableBtn = specialFormat2.test(props.item.message) || emojiFormat.test(props.item.message)
    return(
        <div className='confirm-delete padding-message-box'>
            <div className='align-items-center d-flex justify-content-between pb-3'>
                <p className='m-0'>Personal Message</p>
                <img onClick={props.removeModal} src={error} alt='close'/>
            </div>
            <div className='align-items-center d-flex flex-column justify-content-between'>
                <div className='position-relative'>
                    <textarea 
                        id='serenade-gift-message' 
                        className='message-text font-size-13' 
                        cols='30' 
                        rows='4' 
                        maxLength='250'
                        placeholder='Type your message...'
                        onChange={props.handlePersonalMessage}
                        value={props.item.message}
                    />
                    <span className='count-char'>{(props.item.message && props.item.message.length) || 0}/250</span>
                </div>
                {disableBtn && <span className='errors pt-20'>Message can't have special characters</span>}
                <button disabled={disableBtn} onClick={props.savePersonalMessage} className='delete-modal-btn cancel-modal-btn padding-big'>SAVE</button>
            </div>
        </div>
    )
}

const AddressBox = (props) => {
    const { streetAddress, pincode, city, state} = props.fields
    const { handleSubmit } = props;
    return(
        <div className="show-address-box left pt-2-">
            <div className='align-items-center d-flex justify-content-between pb-3'>
                {props.length > 1 && 
                    <div className='pt-1'>
                        <label className="container-check name m-0">Same for all events 
                            <input onChange={props.sameAddressForAll} type="checkbox" name='doublePrice' />
                            <span className="checkmark"></span>
                        </label>
                    </div>}
                <img className={props.length <=1 ? 'ml-auto' : ''} onClick={props.removeModal} src={error} alt='close'/>
            </div>
            <p className='border-0 p-0 summary-heading'>Address</p>
            <form className='pt-20' onSubmit={handleSubmit(props.saveAddress)}>
                <div className="field-wrap-address fullname-wrap">
                    <div className="form-group address-box-form">
                        <Field
                            name='streetAddress'
                            label='Delivery Address'
                            labelClassName={classnames({
                                'form-label': true,
                                'input-active': !isEmpty(streetAddress) ? true : false
                            })}
                            id="streetAddress-field"
                            type='text'
                            onChange={e => props.handleAddressChange(e,'streetAddress')}
                            className="form-input serenade-form-address form-input-serenade"
                            component={MaterialTextField}
                        />
                    </div>
                </div>
                <div className="field-wrap-address mobile-wrap mobile">
                    <div className="form-group address-box-form">
                        <Field
                            name='pincode'
                            label='Pincode'
                            labelClassName={classnames({
                                'form-label': true,
                                'input-active': !isEmpty(pincode) ? true : false
                            })}
                            id="pincode-field"
                            type='number'
                            onChange={e => props.handleAddressChange(e,'pincode')}
                            className="form-input serenade-form-address form-input-serenade"
                            component={MaterialTextField}
                        />
                        {!props.isPincodeValid && <span className='wrong-pincode'><sup>*</sup>Sorry, We do not deliver in this area.</span>}
                        {props.fields.deliveryCharges > 0 && <span className='wrong-pincode delivery-charges-color'><sup>*</sup>Delivery Charges Rs {props.fields.deliveryCharges}</span>}
                    </div>
                </div>
                <div className="field-wrap-address mobile-wrap mobile">
                    <div className="form-group address-box-form">
                        <Field
                            name='city'
                            label='City'
                            labelClassName={classnames({
                                'form-label': true,
                                'input-active': !isEmpty(city) ? true : false
                            })}
                            id="city-field"
                            type='text'
                            onChange={e => props.handleAddressChange(e,'city')}
                            className="form-input serenade-form-address form-input-serenade"
                            component={MaterialTextField}
                        />
                    </div>
                </div>
                <div className="field-wrap-address mobile-wrap mobile">
                    <div className="form-group address-box-form">
                        <Field
                            name='state'
                            label='State'
                            labelClassName={classnames({
                                'form-label': true,
                                'input-active': !isEmpty(state) ? true : false
                            })}
                            id="state-field"
                            type='text'
                            onChange={e => props.handleAddressChange(e,'state')}
                            className="form-input serenade-form-address form-input-serenade"
                            component={MaterialTextField}
                        />
                    </div>
                </div>
                <input type='submit' className='delete-modal-btn cancel-modal-btn padding-big save-address-btn' name="Next" value="Next"/>
            </form>
        </div>
    )
}

const validate = values => {
    const specialFormat = /[\[\]\\|<>]/;
    const specialFormat2 = /[#@*\[\]\\|<>\/]/;
    const emojiFormat = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;

    const errors = {};
    const fields = ['streetAddress', 'pincode', 'city', 'state'];
    fields.forEach(field => {
        if (!values[field]) {
            errors[field] = `${ucFirst(field)} is required`;
        }
    })

    if(values['pincode'] && (values['pincode'].length > 6 || values['pincode'].length < 6)){
        errors['pincode'] = `${ucFirst('pincode')} number is not valid`
    }

    if(specialFormat2.test(values['pincode'])){
        errors['pincode'] = `${ucFirst('pincode')} can't have special characters`
    }

    if(specialFormat.test(values['streetAddress'])){
        errors['streetAddress'] = `${ucFirst('streetAddress')} can't have special characters`
    }

    if(emojiFormat.test(values['streetAddress'])){
        errors['streetAddress'] = `${ucFirst('streetAddress')} can't have emojis`
    }

    return errors;
}

export default (reduxForm({
    form: 'AddressBoxForm',
    validate
  })(withRouter(ChooseGiftsPage)));