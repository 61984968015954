import React from 'react';
import tick from '../../../components/Layout/components/images/group.svg';
import remove from '../../../components/Layout/components/images/remove.svg';
import pending from '../../../components/Layout/components/images/info-button (1).svg';
import './index.css';

const OrderStatus = (props) => {
    let urlSearchObj = {};
    let image = null;
    let text = "";
    let subText = "";
    let imageClass = ""
    let style = {}
    const params = new URLSearchParams(props.location.search)
    let status_id = params.get('status_id') || 0;
    if(status_id==21){
        image = tick;
        text = "ORDER SUCCESSFUL"
        subText = "Please add message and address for respective order below"
        imageClass = "check-mark"
        style = {
            background : '#199100'
        }
    }
    else if(status_id==28 || status_id==23){
        image = pending;
        text = "ORDER PENDING"
        subText = "There is an issue with your order. We will contact you soon"
        imageClass = "pending-mark"
        style = {
            background : '#ffffff'
        }
    }
    else{
        image = remove;
        text = "ORDER FAILED"
        subText = "There is an issue with your order please try again"
        imageClass = "failed-mark"
        style = {
            background : '#ffffff'
        }
    }
    return(
        <div className='pt-30 pb-10'>
            <div style={style} className="order-tick">
                <img className={imageClass} src={image} alt="tick"/>
            </div>
            <div className='p-10 pt-15 text-center'>
                <h4 className='font-weight-bold'>{text}</h4>
                <div>{subText}</div>
            </div>
        </div>
    )
}

export default OrderStatus;