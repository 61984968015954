import React from 'react';
import { withRouter } from 'react-router-dom';
import { routerNames } from '../../../config/config';
import './index.css';
import { GetParams } from '../../../helpers/URLParms'
import { isEmpty } from '../../../helpers';
import StarEmptyImg from '../../../images/star-empty.png';

class MenuList extends React.Component {

    handleClickItem = (product_id, urlData) => {
        const fa_source = GetParams("fa_source", this.props.location.search)
        const url = fa_source === 'app' ? `${routerNames.SERENADE_PRODUCT_DETAILS}/${product_id}?fa_source=` + fa_source : `${routerNames.SERENADE_PRODUCT_DETAILS}/${product_id}`
        this.props.history.push(url, {
            selectedOccasions: this.props.selectedOccasions,
            chosenRelative: this.props.chosenRelative,
            productUrl: urlData
        })
    }

    render() {
        const menuItems = this.props.menuItems && this.props.menuItems.map((item, index) => {
            if (item.ribbon !== "Personalised" && !item.through.includes("Courier")){
                const ratingBGClass =  `product-rating bg${!isEmpty(item.rating) ? (item.rating > 4 ? `-green` : (item.rating > 3 ? `-yellow` : `-red`)) : null}`;  
                const imageArr = Object.keys(item.image)
                return (
                    <div key={index} className='w-50' onClick={() => this.handleClickItem(item.nid, item.url)}>
                        <div className="react_firstthing1">
                        <div>
                        {item.image && <img className='w-100' src={item.image[imageArr[0]].src} alt={item.image[imageArr[0]].alt} />}
                        </div>
                        {
                                item.rating >= 4 ? 
                                <div className={ratingBGClass}>
                                <img className="starImg" src={StarEmptyImg} />
                                    <span className="starNum">{+item.rating === 5 ? '4.7' : item.rating ==0 ? 3 : item.rating }</span>
                                </div>                            
                                : null
                        }
                        </div>
                        <div className='p-3 white-back'>
                            {item.title && <div className='item-name'>{item.title}</div>}
                            <div className="price-offer"><span className="flt price">Rs.{item.amount}</span>
                                {item.list_price !== 0 ?
                                    <span className="flt list-price">Rs.{item.list_price}</span> :
                                    null
                                }
                                <div className="clearfix"></div></div>
                            {/* <div className='item-price'>{item.sell_price}</div> */}
                        </div>
                    </div>
                )
            }                        
        })
        return (
            <div className='d-flex flex-row flex-wrap justify-content-between parent-div-gifts pad-top-10'>
                {menuItems}
            </div>
        )
    }
}
export default (withRouter(MenuList));