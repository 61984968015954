import React from 'react';
import classnames from 'classnames';
import { isEmpty, ucFirst, saveSessionStorage, getSessionStorage } from '../../../helpers';
import { Field, reduxForm } from 'redux-form';
import { MaterialTextField } from '../../../components/RenderField';
import { withRouter } from 'react-router-dom';
import { routerNames } from '../../../config/config';
import '../../../index.css';
import { axiosInstance } from '../../../config/axiosInstance';
import { HeaderOprKey } from '../../../config/config';
import { ToastContainer, toast } from 'react-toastify';
import LoadingCircle from '../../../components/Loader';
import 'react-toastify/dist/ReactToastify.min.css';
import { pushToDataLayer } from "../../../GS_GTM/gtmFunctions"


class UserForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: {
                fullname     : '',
                email        : '',
                mobile       : '',
                token        : '',
                fetchingData : false,
            }
        }
    }

    componentDidMount(){
        pushToDataLayer('OnPageLoad')
        this.fetchToken()
    }

    fetchToken = (redirect) => {
        const userInfo = getSessionStorage('userInfo')
        const data = {}
        if(userInfo) {
            data['fullname'] = userInfo.user_name
            data['email']    = userInfo.user_email
            data['mobile']   = userInfo.user_mobile
        }
        this.props.initialize(data);
        this.setState({fetchingData : true});

        axiosInstance.get(`tokenGenerate`, {
            headers: {
                'Headeroprkey': HeaderOprKey,
            },
        })
        .then(res => {
            if(res.data && res.data.token){
                data['token'] = res.data.token
                localStorage.setItem("Token", res.data.token);
                if(redirect){
                    this.handleSubmit();
                }
            } else {
                toast("Oops, Something went wrong please refresh...");
            }
            this.setState({ fields : data,fetchingData : false });
        })
        .catch( e => {
            this.setState({ fetchingData:false })
            toast("Oops, Something went wrong please try again...");
        })
    }

    handleChange = ({ target: {type, value, name} }) => {
        let val = value
        if(name==='email'){
            val = val.toLowerCase()
        }
        const { fields } = this.state;
        fields[name] = value;
        this.setState({ fields });
    }

    handleSubmit = () => {
        const user = this.state.fields;
        const info = {
            "user_name"   : user['fullname'],
            "user_email"  : user['email'],
            "user_mobile" : user['mobile']	
        }
        this.setState({fetchingData : true})
        axiosInstance.post(`createOrderScheduler`, info, {
            headers: {
                'Auth'         : user['token'],
                'Content-Type' : 'application/json'
            }
        })
        .then(res => {
            this.setState({ fetchingData : false })
            if(res.data.data){
                info.id = res.data.data.id
                saveSessionStorage('userInfo',info)
                if(res.data.data.data){
                    const data = res.data.data.data;
                    const arr = [];
                    const relatives = []
                    data.forEach((item,index) => {
                        const obj = this.create_Occasion_From_API_Data(item,index);
                        arr.push(obj)
                    })
                    arr.forEach(item => {
                        relatives.push(item.relative)
                    })
                    saveSessionStorage('selectedOccasions',arr)
                    saveSessionStorage('savedRelatives',relatives)
                }
                pushToDataLayer('OnHomePageContinue')
                this.props.history.push(routerNames.SELECT_RELATION)

            } else if(res.data.statusCode==401){
                this.fetchToken(true)
            } else if (res.data.statusCode===400){
                toast(res.data.msg);
            }
        })
        .catch( e => {
            this.setState({ fetchingData : false })
            toast("Oops, Something went wrong please try again...");
        })
    }

    create_Occasion_From_API_Data = (item,index) => {
        const obj = {};
        if(item.relation){
            const relationInfo = Object.keys(item.relation)[0]
            const id = parseFloat(relationInfo.split('-')[0])
            const relation = relationInfo.split('-')[1]
            const isMultiple = relationInfo.split('-')[2]
            const occasions = item.relation[Object.keys(item.relation)[0]]
            obj.relative = { id: id, relation: relation, occasions: occasions, isMultiple: isMultiple }
        }
        if(item.occasion){
            obj.occasion = item.occasion
        }
        if(item.date){
            obj.date = { date: new Date(item.date), status: true}
        }
        if(item.name){
            obj.name = item.name
        }
        if(item.address){
            obj.address = { address: item.address, status: true }
        }
        if(item.gift) {
            obj.gift = item.gift
        }
        if(item.message) {
            obj.message = item.message
        }

        return obj;
    }

    render() {
        const { fullname, email, mobile }  = this.state.fields;
        const { handleSubmit } = this.props;
        return (
            <div className="container userform-wrapper mt-50">
                <LoadingCircle load={this.state.fetchingData}/>
                <ToastContainer />
                <div className="left pt-2-">
                    <form onSubmit={handleSubmit(this.handleSubmit)}>
                        <div className="title-wrap">
                            <div className="field-wrap fullname-wrap">
                                <div className="form-group">
                                    <Field
                                        name='fullname'
                                        label='Full Name'
                                        labelClassName={classnames({
                                            'form-label': true,
                                            'input-active': !isEmpty(fullname) ? true : false
                                        })}
                                        id="fullname-field"
                                        type='text'
                                        onChange={e => this.handleChange(e)}
                                        className="form-input"
                                        component={MaterialTextField}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="field-wrap mobile-wrap mobile">
                            <div className="form-group">
                                <Field
                                    name='mobile'
                                    label='Mobile'
                                    labelClassName={classnames({
                                        'form-label': true,
                                        'input-active': !isEmpty(mobile) ? true : false
                                    })}
                                    id="mobile-field"
                                    type='tel'
                                    onChange={e => this.handleChange(e)}
                                    className="form-input"
                                    component={MaterialTextField}
                                    autoComplete="new-password"
                                />
                            </div>
                        </div>
                        <div className="field-wrap email-wrap anonymous-email">
                            <div className="form-group">
                                <Field
                                    name='email'
                                    label='Email'
                                    labelClassName={classnames({
                                        'form-label': true,
                                        'input-active': !isEmpty(email) ? true : false
                                    })}
                                    id="email-field"
                                    type='email'
                                    onChange={e => this.handleChange(e)}
                                    className="form-input"
                                    component={MaterialTextField}
                                />
                            </div>
                        </div>
                        <div className="field-wrap">
                            <input type="submit" className="pri-button" name="Continue" value="CONTINUE" />
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

const validate = values => {
    const specialFormat = /[!@#$%^`&*()_+-=\[\]{};':"\\|,.<>\/?]/;
    const specialFormat2 = /[#@*. \[\]\\|<>\/]/;
    const emailFormat = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const emojiFormat = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;

    const errors = {};
    const fields = ['fullname', 'mobile', 'email'];
    fields.forEach(field => {
        if (!values[field]) {
            errors[field] = `${ucFirst(field)} is required`;
        }
    })

    if(values['mobile'] && (values['mobile'].length > 14 || values['mobile'].length < 10)){
        errors['mobile'] = `${ucFirst('mobile')} number is not valid`
    }

    if(specialFormat2.test(values['mobile'])){
        errors['mobile'] = `${ucFirst('mobile')} can't have special characters`
    }
    
    if(!emailFormat.test(values['email'])){
        errors['email'] = `${ucFirst('email')} is not valid`
    }

    if(specialFormat.test(values['fullname'])){
        errors['fullname'] = `${ucFirst('fullname')} can't have special characters`
    }

    if(emojiFormat.test(values['fullname'])){
        errors['fullname'] = `${ucFirst('fullname')} can't have emojis`
    }

    return errors;
}

export default reduxForm({
    form: 'UserForm',
    validate
})(withRouter(UserForm))
