import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import yellowStar from '../../../components/Layout/components/images/path-copy-3.svg';
import greyStar from '../../../components/Layout/components/images/path-copy-4.svg';
import './index.css';
import '../../../index.css';
import Rating from './Rating';
import { isEmpty } from '../../../helpers/index';

class PriceBox extends Component {
    render(){
        const product = this.props.product ? this.props.product : {};
        const messageLength = (this.props.currentItemIndex && this.props.currentItemIndex.messageOnCake) ? this.props.currentItemIndex.messageOnCake.length : 0
        return(
            <div className='p-3'>
                <div className='product-title'>{product.title}</div>
                <div className='align-items-center pt-3 pb-3'>
                    {!isNaN(parseFloat(this.props.productPrice)) && <span className='pt-1 font-weight-bold font-24'>Rs. {parseFloat(this.props.productPrice)}</span>}
                    {this.props.addSurcharge && this.props.surchargePercent!== 0 && <p className='updated-price-info'><sup>*</sup>Price Updated</p>}
                    <span className='align-items-center d-flex justify-content-between w-60'>
                    {!isEmpty(product.review_list) ? <Rating
                        reviewRating={product.reviewRating}
                        rating={product.reviewRating}
                        />
                    : null
                    }
                    </span>
                </div>
                <React.Fragment>
                {
                    product.attributes && Object.keys(product.attributes) && Object.keys(product.attributes).map(attribute_id => {
                        const attributes = product.attributes
                        if(attribute_id==2 || attribute_id==3 || attribute_id==6 || attribute_id==4 || attribute_id==7){
                            return (
                                attributes[attribute_id].options && Object.keys(attributes[attribute_id].options).map((options_id,index) => {
                                    const options = attributes[attribute_id].options
                                    let showPrice = parseFloat(options[options_id].price)
                                    if(attribute_id==3 && this.props.addSurcharge){
                                        showPrice = showPrice + parseFloat(this.props.surchargePercent)*showPrice;
                                    }
                                    return (
                                        <label key={options.name+'_'+index} className="container-check">{options[options_id].name} @ Rs {Math.floor(showPrice)} 
                                            <input onChange={(e) => this.props.handleAdditions(e,options[options_id].name,options[options_id].price)} type="checkbox" name={options[options_id].name} />
                                            <span className="checkmark"></span>
                                            {this.props.addSurcharge && this.props.surchargePercent!== 0 && attribute_id==3 && <span className='updated-price-info ml-2'>(<sup> *</sup>Price Updated )</span>}
                                        </label>
                                    )
                                })
                            )
                        }
                        if(attribute_id==16){
                            return (
                                <div className='pt-3' key={attribute_id}>
                                    <div className='light-heading pb-3'>Message on Cake</div>
                                    <div className='message-box-cake'>
                                        <div className='position-relative'>
                                            <textarea onChange={e => this.props.handleMessageOnCake(e)} id='gift-message' className='message-text-cake' cols='32' rows='1' maxLength='25'/>
                                            <span className='count-char-cake'>{messageLength}/25</span>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        if(attribute_id==10){
                            return (
                                <div key={attribute_id}>
                                    <div className='light-heading pb-3'>Select Weight</div>
                                    <ul className='d-flex overflow-auto scrollbar weight-row pl-0'>
                                        {
                                            Object.keys(product.attributes[attribute_id].options).map(id => {
                                                return(
                                                    <li
                                                        key={id}
                                                        className = {product.attributes[attribute_id].options[id].name===this.props.currentItemIndex['weightOfCake'] ? 'selected-weight-list' : 'weight-list'}
                                                        onClick={() => this.props.handleCakeWeight(product.attributes[attribute_id].options[id].name)}
                                                    >
                                                        {product.attributes[attribute_id].options[id].name}
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                    
                                </div>
                            )
                        }
                    })
                }
                </React.Fragment>
            {this.props.addSurcharge && this.props.surchargePercent!== 0 && <div className='updated-price-info red-price-info'>( * ) On {this.props.currentItemIndex.occasion}, the flower supplies are little expensive.</div>}
            </div>
        )
    }
}

export default (withRouter(PriceBox));