import React from 'react';
import { withRouter } from 'react-router-dom';
import { routerNames } from '../../../config/config';
import './index.css';

class MenuList extends React.Component {
    
    handleClickItem = (product_id) => {
        this.props.history.push(`${routerNames.PRODUCT_DETAILS}/${product_id}`,{
            selectedOccasions : this.props.selectedOccasions,
            chosenRelative : this.props.chosenRelative
        })
    }

    render(){
        const menuItems = this.props.menuItems.map((item,index) => {
            const imageArr = Object.keys(item.node.image)
            return (
                <div key={index} className='w-50 menu-border' onClick={() => this.handleClickItem(item.node.id)}>
                    {item.node.image && <img className='w-100' src={item.node.image[imageArr[0]].src} alt={item.node.image[imageArr[0]].alt}/>}
                    <div className='p-3'>
                        {item.node.title && <div className='item-name'>{item.node.title}</div>}
                        <div className='item-price'>Rs. {item.node.sell_price}</div>
                    </div>
                </div>
            )
        })
        return(
            <div className='d-flex flex-row flex-wrap justify-content-between border-top-menu'>
                {menuItems}
            </div>
        )
    }
}

export default (withRouter(MenuList));