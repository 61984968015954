import React from 'react';
import { Link } from 'react-scroll'

const Rating = (props) => {
  let totalRating = [1, 2, 3, 4, 5];
  let starRating = null;
  let totalReview = null;

  if (props.reviewRating) {
    starRating = totalRating.map(index => {
      return (
        <div className="star star-1 star-odd" key={index}>
          <span className={props.reviewRating.rating >= index ? "on" : "off"}></span>
        </div>
      )
    });

    totalReview = `${props.reviewRating.reviews} Review`
  }

  return (
    props.reviewRating ? <div className="productRating">
      <div itemProp="aggregateRating" itemScope itemType="https://schema.org/AggregateRating" className="clearfix fivestar-average-stars">
        <div className="fivestar-static-item">
          <div className="form-item form-type-item">
            <div className="fivestar-default">
              <div className="fivestar-widget-static fivestar-widget-static-vote fivestar-widget-static-5 clearfix">
              <span itemProp="reviewCount" style={{display: 'none'}}>{props.reviewRating.reviews}</span>
              <meta itemProp="worstRating" content="1" />
              <meta itemProp="ratingValue" content={props.rating || 5} />
              <meta itemProp="bestRating" content="5" />
              <strong className="reviewPoint">{+props.reviewRating.rating === 5 ? 4.7 : props.reviewRating.rating}</strong>
							<Link activeClass="active" className="total-reviews" to="product-reviews" spy={true} smooth={true} duration={1500}>
								{ totalReview }
							</Link>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div> : ''
  )
}

export default Rating;
