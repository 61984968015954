import React, { Component } from 'react';
import Layout from '../../../components/Layout/container';
import ReceiverAddress from './ReceiverAddress';
import Headline from '../../UserForm/components/Headline';
import './index.css';
import { withRouter } from 'react-router-dom';
import { routerNames } from '../../../config/config';

class ReceiverAddressForm extends Component {
    render() {
        return (
            <div className='parent-div'>
                <Layout page={routerNames.RECEIVER_NAME} serenade={true}/>
                <Headline 
                    title='Where do you want to deliver ?'
                    subtitle="Add your receiver's address"
                />
                <ReceiverAddress/>
            </div>
        )
    }
}

export default withRouter(ReceiverAddressForm)