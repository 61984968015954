import React from 'react';

const Headline = (props) => (
    <div className='container top-head-line'>
        <h6>{props.title}</h6>
        <p>{props.subtitle}</p>
    </div>
)

export default Headline;
