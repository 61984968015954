import React, { Component } from 'react';
import { routerNames } from '../../../config/config';
import Layout from '../../../components/Layout/container';
import Headline from '../../Information/components/Headline';
import { getSessionStorage } from '../../../helpers';
import Details from './Details'

class DeliveryDetails extends Component {
    render(){
        const state = this.props.location.state
        const selectedOccasions = state ? state.selectedOccasions : getSessionStorage('selectedOccasions')
        return(
            <div className='height-full'>
                <Layout page={routerNames.DELIVERY_ADDRESS}/>
                <Headline title='Please select gift'/>
                <Details selectedOccasions={selectedOccasions} is_offer_enabled={state ? state.is_offer_enabled : null}/>
            </div>
        )
    }
}

export default DeliveryDetails;