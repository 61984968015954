import React, { Component } from 'react';
import Layout from '../../../components/Layout/container';
import { Navbar } from './Navbar';
import MenuList from './MenuList'
import { axiosInstanceMenu } from '../../../config/axiosInstance';
import { ToastContainer, toast } from 'react-toastify';
import LoadingCircle from '../../../components/Loader';
import { getSessionStorage, saveSessionStorage } from '../../../helpers';
import { withRouter } from 'react-router-dom';
import { routerNames } from '../../../config/config';
import 'react-toastify/dist/ReactToastify.min.css';

class Menu extends Component {
    constructor(props){
        super(props);
        const category = getSessionStorage('currentMenuCategory')
        this.state = {
            fetchingData: false,
            category : category ? category : 'flower',
            menuItems : [],
            flower : [],
            cake: [],
            plant : [],
            combo: []
        }
    }

    componentDidMount(){
        const selectedOccasions = getSessionStorage('selectedOccasions')
        const chosenRelative = getSessionStorage('chosenRelative')
        if(selectedOccasions && chosenRelative){
            selectedOccasions.forEach(item => {
                if(!item.name){
                    this.props.history.push(routerNames.DETAILS)
                }
            })
            this.handleMenuList(this.state.category)
        }
        else {
            if(!selectedOccasions){
                this.props.history.push(routerNames.SELECT_RELATION)
            }
            else if(!chosenRelative){
                this.props.history.push(routerNames.DELIVERY_DETAILS)
            }
        }
    }

    handleMenuList = (value) => {
        saveSessionStorage('currentMenuCategory',value)
        const items = this.state[value]
        if(items.length > 0){
            this.setState({
                menuItems : items,
                category: value
            })
        } else {
            this.setState({ fetchingData:true })
            axiosInstanceMenu.get(value)
            .then(res => {
                this.setState({
                    [value] : res.data.result.nodes,
                    menuItems : res.data.result.nodes,
                    category: value,
                    fetchingData: false
                })
            })
            .catch( e => {
                this.setState({ fetchingData:false, category: value })
                toast("Could not load Items, Please try again");
            })
        }
    }

    render(){
        const state = this.props.location.state
        const selectedOccasions = state ? state.selectedOccasions : getSessionStorage('selectedOccasions')
        const chosenRelative = state ? state.chosenRelative : getSessionStorage('chosenRelative')
        return(
            <React.Fragment>
                <LoadingCircle load={this.state.fetchingData}/>
                <ToastContainer />
                <Layout page={routerNames.DELIVERY_DETAILS}/>
                <Navbar handleMenuList={this.handleMenuList} category={this.state.category}/>
                <MenuList menuItems={this.state.menuItems} selectedOccasions={selectedOccasions} chosenRelative={chosenRelative} />
            </React.Fragment>
        )
    }
}

export default (withRouter(Menu));