import React, { Component } from 'react';
import Layout from '../../../components/Layout/container';
import ProductDescription from './ProductDescription';
import Banner from './Banner';
import PriceBox from './PriceBox';
import AddGift from './AddGift';
import { isEmpty, fetchToken, saveSessionStorage, getSessionStorage, removeSessionStorage } from '../../../helpers'
import { withRouter } from 'react-router-dom';
import { routerNames } from '../../../config/config';
import { axiosInstance, axiosLaravelInstance } from '../../../config/axiosInstance';
import { ToastContainer, toast } from 'react-toastify';
import LoadingCircle from '../../../components/Loader';
import { pushToDataLayer } from "../../../GS_GTM/gtmFunctions";
import Reviews from './Review'
class ProductDetails extends Component {
    constructor(props){
        super(props);
        const occasionList = getSessionStorage('occasionList') || []
        const currentItemIndex = getSessionStorage('currentItemIndex') || {}
        this.state = {
            occasionList     : occasionList,
            currentItemIndex : currentItemIndex,
            product          : {},
            fetchingData     : false,
            productPrice     : '',
            surcharge        : {},
            addSurcharge     : false,
            surchargePercent : '',
            surchargeApplied : false 
        }
    }

    componentDidMount() {
        const occasionList = getSessionStorage('occasionList')
        const currentItemIndex = getSessionStorage('currentItemIndex')
        if(occasionList && currentItemIndex){
            occasionList.forEach(item => {
                if(!item.name){
                    this.props.history.push(routerNames.DETAILS)
                }
            })
            this.productDetails();
        }
        else {
            if(!occasionList){
                this.props.history.push(routerNames.USER_INFO_SERENADE)
            }
            else if(!currentItemIndex){
                this.props.history.push(routerNames.CHOOSE_GIFTS_PAGE)
            }
        }
    }

    productDetails = () => {
        const currentItemIndex = getSessionStorage('currentItemIndex')
        const product_id = this.props.match.params.product_id
        this.setState({ fetchingData:true })
        if(this.props?.history?.location?.state?.productUrl){
            axiosLaravelInstance.get(this.props.history.location.state.productUrl+'?metainfo=1&rsource=mob')
            .then(res => {
                const responseData = res.data;
                if(responseData.status_code === 200){                
                    if(responseData.data.type==='cake' && responseData.data.attributes['10']){
                        const initialWeight = responseData.data.attributes['10'].options[Object.keys(responseData.data.attributes['10'].options)[0]].name
                        currentItemIndex['weightOfCake'] = initialWeight 
                    }
                    this.setState({
                        product: responseData.data,
                        currentItemIndex: currentItemIndex,
                        addSurcharge: responseData.data.isValentineSurcharge == 0 ? false : true,
                    }, () => this.surchargeData())
                }else{
                    toast("Could not load Items, Please check your internet connection");    
                }
            })
            .catch( e => {
                toast("Could not load Items, Please check your internet connection");
            })
        }else{
            toast("Could not load Items, Please check your internet connection");
        }
    }

    surchargeData = () => {
        this.setState({ fetchingData:true })
        axiosInstance.get('getSurChargeData',{
            headers: {
                'Auth': localStorage.getItem('Token'),
                'Content-Type':'application/json'
            }
        })
        .then(res => {
            if(res.data && res.data.data) {
                this.setState({ surcharge : res.data.data, fetchingData:false })
            }
        })
        .catch( e => {
            this.setState({ fetchingData:false })
            toast("Could not load Items, Please check your internet connection");
        })
    }

    static getDerivedStateFromProps(props,state){
        let productPrice = '';
        let product = state.product
        if(product.type==='cake' && product.attributes['10'] && state.currentItemIndex.weightOfCake){
            Object.keys(product.attributes['10'].options).forEach(id => {
                if(product.attributes['10'].options[id].name===state.currentItemIndex.weightOfCake){
                    productPrice = product.attributes['10'].options[id].price
                }
            })
        } else { productPrice = product.price }
        
        const date = state.currentItemIndex && state.currentItemIndex.date && state.currentItemIndex.date.split('-');
        const index = date && date[date.length-1]
        let surchargePercent = 0
        if(state.addSurcharge && state.surcharge[parseFloat(index)]){
            const surcharge = state.surcharge[parseFloat(index)]
            surchargePercent = surcharge
            productPrice = parseFloat(productPrice) + parseFloat(surcharge)*parseFloat(productPrice)
        }
        return {
            productPrice : Math.floor(parseFloat(productPrice)),
            surchargePercent : surchargePercent,
        }
    }

    handleAdditions = (e,key,price) => {
        const currentItemIndex = Object.assign({}, this.state.currentItemIndex);
        const newKey = key === 'Make it eggless' ? 'eggless' : 
                       key ==='Add Vase' ? 'addVase' : 
                      (key === 'Double the quantity of flowers' || key==='Double the flower' || key === 'Double the flower quantity') ? 'doubleFlowers' : 
                       key === 'Add 10 red roses' ? 'addFlowers' : "nullKey";

        if(e.target.checked){
            if(newKey==='doubleFlowers'&& this.state.addSurcharge){
                price = parseFloat(price) + Math.floor(parseFloat(this.state.surchargePercent)*parseFloat(price))
            }
            currentItemIndex[newKey] = price;
        } else {
            currentItemIndex[newKey] = 0;
        }

        this.setState({ currentItemIndex : currentItemIndex })
    }

    addGift = (product) => {
        removeSessionStorage('currentItemIndex');
        const arr = [...this.state.occasionList];
        const currentItemIndex = Object.assign({}, this.state.currentItemIndex);
        const user = getSessionStorage('userInfoSerenade');
        if(!user){
            this.props.history.push(routerNames.OCCASION_LIST)
        } else {
            let index = -1;
            let arr1 = []
            arr.forEach((item,i) => {
                let productPrice = '';
                if(item.gift){
                    if(item.gift.type==='cake' && item.weightOfCake && item.gift.attributes['10']){
                        Object.keys(item.gift.attributes['10'].options).forEach(id => {
                            if(item.gift.attributes['10'].options[id].name===item.weightOfCake){
                                productPrice = item.gift.attributes['10'].options[id].price
                            }
                        })
                    } else { productPrice = item.gift.price }
                }
                const obj = {
                    occasion : item.occasion,
                    image    : item.image,
                    mobile   : item.mobile, 
                    date     : item.date,
                    name     : item.name,
                    address  : item.address,
                    gift     : item.gift && {
                        nid           : item.gift.nid,
                        title         : item.gift.title,
                        price         : productPrice,
                        image         : item.gift.fullSizeImages ? item.gift.fullSizeImages[0] : item.gift.image,
                        doubleFlowers : item.doubleFlowers || item.gift.doubleFlowers || 0,
                        addVase       : item.addVase || item.gift.addVase || 0,
                        addFlowers    : item.addFlowers || item.gift.addFlowers || 0,
                        weightOfCake  : item.weightOfCake || item.gift.weightOfCake || 0,
                        eggless       : item.eggless || item.gift.eggless || 0,
                        messageOnCake : item.messageOnCake || item.gift.messageOnCake || '',
                        type          : item.gift.type

                    },
                    message: item.message
                }
                if(obj.address===''){
                    delete obj.address
                }
                if(currentItemIndex.occasion===item.occasion){
                    index = i;
                    obj.gift = {
                        nid           : product.nid,
                        title         : product.title,
                        price         : this.state.productPrice,
                        image         : this.state.product.fullSizeImages[0],
                        doubleFlowers : currentItemIndex.doubleFlowers || 0,
                        addVase       : currentItemIndex.addVase || 0,
                        addFlowers    : currentItemIndex.addFlowers || 0,
                        weightOfCake  : currentItemIndex.weightOfCake || 0,
                        eggless       : currentItemIndex.eggless || 0,
                        messageOnCake : currentItemIndex.messageOnCake || '',
                        type          : product.type
                    }
                }
                arr1.push(obj)
            })
            let product1 = {
                attributes    : product.attributes,
                nid           : product.nid,
                title         : product.title,
                price         : this.state.productPrice,
                image         : this.state.product.fullSizeImages[0],
                doubleFlowers : currentItemIndex.doubleFlowers || 0,
                addVase       : currentItemIndex.addVase || 0,
                addFlowers    : currentItemIndex.addFlowers || 0,
                weightOfCake  : currentItemIndex.weightOfCake || 0,
                eggless       : currentItemIndex.eggless || 0,
                messageOnCake : currentItemIndex.messageOnCake || '',
                type          : product.type
            }
            currentItemIndex['gift'] = product1;
            arr[index] = currentItemIndex;

            const data = {
                step       : 4,
                id         : user.id,
                user_email : user.user_email,
                data       : arr1,
                order_type : '2'
            }
            this.updateOrderSchedular(data,arr)
        }
    }

    updateOrderSchedular = (data,arr) => {
        this.setState({ fetchingData: true })
        axiosInstance.post(`updateOrderScheduler`, data, {
            headers: {
                'Auth': localStorage.getItem('Token'),
                'Content-Type':'application/json'
            }
        })
        .then(res => {
            this.setState({ fetchingData: false })
            if(res.data.statusCode===200){
                saveSessionStorage('occasionList',arr)
                pushToDataLayer('OnNextSerenade',{},{},{
                    event: "clickEvent",
                    eventCategory: "Serenade",
                    eventAction: "product-details",
                    eventLabel: "Add"
                })
                this.props.history.push(routerNames.CHOOSE_GIFTS_PAGE,{
                    occasionList : arr,
                    is_offer_enabled  : res.data.is_offer_enabled
                })
            } else if(res.data.statusCode==401){
                fetchToken("submit",this.addGift)
            } else {
                this.setState({ fetchingData: false })
                toast("Oops Something went wrong, Please try again");
            }
        })
        .catch( e => { toast("Could not process request, Please try again") })
    }

    handleCakeWeight = (value) => {
        const currentItemIndex = Object.assign({}, this.state.currentItemIndex);
        currentItemIndex['weightOfCake'] = value
        this.setState({ currentItemIndex : currentItemIndex })
    }

    handleMessageOnCake = (e) => {
        const currentItemIndex = Object.assign({}, this.state.currentItemIndex);
        currentItemIndex['messageOnCake'] = e.target.value
        if(e.target.value.length <= 25){
            this.setState({ currentItemIndex : currentItemIndex })
        }
    }

    render(){
        return(
            <React.Fragment>
                <ToastContainer />
                <LoadingCircle load = { this.state.fetchingData }/>
                <Layout page = { routerNames.SERENADE_MENU } serenade={true}/>
                <Banner images = { this.state.product.fullSizeImages }/>
                { this.state.product.nid !== undefined ?
                    <PriceBox 
                    product             = { this.state.product } 
                    handleAdditions     = { this.handleAdditions } 
                    handleMessageOnCake = { this.handleMessageOnCake }
                    currentItemIndex    = { this.state.currentItemIndex }
                    handleCakeWeight    = { this.handleCakeWeight }
                    setProductPrice     = { this.setProductPrice }
                    productPrice        = { this.state.productPrice }
                    surcharge           = { this.state.surcharge }
                    addSurcharge        = { this.state.addSurcharge }
                    surchargePercent    = { this.state.surchargePercent }
                />
                : null
                }
                <ProductDescription product = { this.state.product }/>                
                {
                    this.state.product.nid !== undefined && !isEmpty(this.state.product.review_list) ? <Reviews
                    productName={this.state.product.title}
                    productNid={this.state.product.nid}
                    reviews={this.state.product.review_list}
                    rating={this.state.product.reviewRating ? this.state.product.reviewRating.rating : ''}
                    recommend={this.state.product.rating_recommends ? this.state.product.rating_recommends.percent : ''}
                    isMobileReq = {"true"}                    
                /> : null
                }                
                <AddGift addGift={ () => this.addGift(this.state.product) }/>
            </React.Fragment>
        )
    }
}

export default (withRouter(ProductDetails));