import React, { Component } from 'react';
import Layout from '../../../components/Layout/container';
import MenuList from './MenuList'
import { axiosInstanceMenuSerenade } from '../../../config/axiosInstance';
import { ToastContainer, toast } from 'react-toastify';
import LoadingCircle from '../../../components/Loader';
import { getSessionStorage, getCookie } from '../../../helpers';
import Headline from '../../UserForm/components/Headline';
import { withRouter } from 'react-router-dom';
import { routerNames } from '../../../config/config';
import heart from '../../../components/Layout/components/images/menu-heart.svg'
import 'react-toastify/dist/ReactToastify.min.css';

class Menu extends Component {
    state = {
        fetchingData : false,
        menuItems     : []
    }

    componentDidMount(){
        const currentItemIndex = getSessionStorage('currentItemIndex');
        const OccasionList = getSessionStorage('occasionList')
        if(!OccasionList){
            this.props.history.push(routerNames.OCCASION_LIST)
        }
        if(!currentItemIndex){
            this.props.history.push(routerNames.CHOOSE_GIFTS_PAGE)
        } else {
            this.handleMenuList();
        }
    }

    handleMenuList = () => {
        const currentItemIndex = getSessionStorage('currentItemIndex');
        if(currentItemIndex){
            let occasion = currentItemIndex.occasion.split(' ').join('-');
            if(occasion === "Valentine-Day"){
                // occasion = "gp/valentine";
                occasion = "valentine/flowers";
            }
            let fc_cn = getCookie('fc_cn');            
            occasion = occasion.toLowerCase() + '?rsource=serenade'
            if (fc_cn) occasion += '&fc_cn='+fc_cn
            this.setState({ fetchingData:true })
            axiosInstanceMenuSerenade.get(`${occasion}`)
            .then(res => {
                const responseData = res.data;
                if(responseData.status_code === 200){
                    this.setState({
                        fetchingData: false,
                        menuItems: res.data.data.results
                    })
                }else{
                    this.setState({ fetchingData:false })
                    toast("Could not load Items, Please try again");    
                }
            })
            .catch( e => {
                this.setState({ fetchingData:false })
                toast("Could not load Items, Please try again");
            })            
        }else{
            this.setState({ fetchingData:false })
            toast("Could not load Items, Please try again");
        }
    }

    render(){
        const currentItemIndex = getSessionStorage('currentItemIndex') || {};
        return(
            <React.Fragment>
                <LoadingCircle load={this.state.fetchingData}/>
                <ToastContainer />
                <Layout page={routerNames.CHOOSE_GIFTS_PAGE} serenade={true}/>
                <div className='menu-list-headings'>
                    <Headline 
                        title={`Make Your ${currentItemIndex.occasion} Special`}
                        subtitle="This Valentine send more than a gift"
                    />
                    <img src={heart} alt='heart'/>
                </div>
                <MenuList menuItems={this.state.menuItems}/>
            </React.Fragment>
        )
    }
}

export default (withRouter(Menu));