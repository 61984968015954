import React from 'react';
import Calendar from 'react-calendar';
import './index.css';
import { withRouter } from 'react-router-dom';
import tick from '../../../components/Layout/components/images/group.svg';
import gift from '../../../components/Layout/components/images/gift.svg';
import { routerNames } from '../../../config/config';
import { fetchToken, saveSessionStorage, getSessionStorage } from '../../../helpers';
import Modal from 'react-modal';
import SlidingPane from 'react-sliding-pane';
import { axiosInstance } from '../../../config/axiosInstance';
import { ToastContainer, toast } from 'react-toastify';
import LoadingCircle from '../../../components/Loader';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import '../../../index.css';
import { pushToDataLayer } from "../../../GS_GTM/gtmFunctions"

class Relative extends React.Component {
    constructor(props){
        super(props);
        const selectedOccasions = getSessionStorage("selectedOccasions")
        this.state = {
            relatives         : [],
            selectedOccasions : selectedOccasions ? selectedOccasions : [],
            openIndex         : -1,
            fetchingData      : false,
        }
    }

    componentDidMount() {
        Modal.setAppElement(this.el);
        const user = getSessionStorage('userInfo');
        if (!user) { this.props.history.push(routerNames.HOME) }       
        else {
            const relatives = getSessionStorage('relatives');
            const selectedOccasions = getSessionStorage('selectedOccasions');

            if(selectedOccasions){
                selectedOccasions.forEach((item) => {
                    const date = new Date(item.date.date);
                    item.date.date = date;
                })
                this.setState({ selectedOccasions : selectedOccasions })
            }
    
            if (relatives) { 
                this.setState({ relatives : relatives }) 
            } 
            else { 
                this.fetchRelationshipData() 
            }
        }
    }

    fetchRelationshipData = () => {
        this.setState({ fetchingData : true })
        axiosInstance.get(`relationshipData`,{
        headers: {
            'Auth'         : localStorage.getItem('Token'),
            'Content-Type' : 'application/json'
        }})
        .then(res => {
            if(res.data.data){
                const arr = []
                let data = res.data.data
                Object.keys(data).forEach((item,index) => {
                    const relation = item.split('-')[0]
                    const isMultiple = item.split('-')[1]
                    const obj = { id: index, relation: relation, occasions: data[item], isMultiple: isMultiple }
                    arr.push(obj)
                })

                const relatives = this.update_API_Relation_List(arr);
                this.setState({
                    relatives : relatives,
                    fetchingData: false
                })
                saveSessionStorage("relatives",relatives)
            }
            else if(res.data.statusCode==401){
                fetchToken("relatives")
            }
        })
        .catch( e => {
            this.setState({ fetchingData : false })
            toast("Could not receive data, Please check your internet connection");
        })
    }

    update_API_Relation_List = (arr) => {
        const savedRelatives = getSessionStorage("savedRelatives");
        let UpdatedList = [];
        if(savedRelatives){
            savedRelatives.forEach(item => {
                UpdatedList.push(item)
            })
            arr.forEach(item => {
                let saved = false
                savedRelatives.forEach(ele => {
                    if(item.id==ele.id){
                        saved = true
                    }
                })
                if(!saved){
                    UpdatedList.push(item)
                }
            })
        } else {
            UpdatedList = arr
        }

        return UpdatedList
    }
    
    hideOccasion = (index) => {
        let relatives = [...this.state.relatives];
        let k = -1;
        let openIndex = -1;
        let element = this.state.selectedOccasions[index]
        let element1 = this.state.selectedOccasions[index-1]
        relatives.forEach((item,ind) => {
            if(element.relative.id===item.id){
                k = ind;
            }
            if(element1 && element1.relative.id===item.id){
                openIndex = ind;
            }
        })
        this.setState({
            selectedOccasions: this.state.selectedOccasions.filter((_, i) => i !== index),
            relatives : this.state.relatives[k].id > 10 ? this.state.relatives.filter((_, i) => i !== k) : relatives,
            openIndex : openIndex
        },() => {
            saveSessionStorage('relatives',this.state.relatives)
            saveSessionStorage('selectedOccasions' , this.state.selectedOccasions)
        });
    }

    addOccasion = (relative,occasion,date,index) => {
        let obj = {relative : relative, occasion : occasion, date: {date : date, status: true}};
        let arr = [...this.state.selectedOccasions];
        let update = false;
        let item1 = {};
        let ind = -1;
        let redundantEntry = -1;
        arr.forEach((item,i) => {
            if(item.relative.relation===obj.relative.relation){
                if(item.name){
                    obj.name = item.name
                }
                if(item.relative.id===obj.relative.id){
                    item1 = Object.assign({}, item)
                    item1.occasion = obj.occasion
                    item1.date = obj.date
                    update = true
                    ind = i
                }
            }
            if((item.relative.id!==obj.relative.id) && 
                (item.occasion===null || item.date.date===null)
              ){
                redundantEntry = i;
            }
        })

        const arr1 = [...arr]

        if(redundantEntry!==-1){
            arr1.splice(redundantEntry,1)
        }

        if(update){
            arr1[ind] = item1;
        } else {
            arr1.push(obj)
        }

        this.setState({
            selectedOccasions : arr1,
            openIndex : index
        }, () => {
            let flag = true
            this.state.selectedOccasions.forEach(item => {
                if(item.occasion==null || item.date.date==null){
                    flag = false
                }
            })
            if(flag===true){
                saveSessionStorage('relatives',this.state.relatives)
                saveSessionStorage('selectedOccasions' , this.state.selectedOccasions)
            }
        })
    }

    addGift = (index,relative) => {
        const temp = {id:index*100+index, relation:relative.relation, occasions:relative.occasions, isMultiple: relative.isMultiple}
        let arr = [...this.state.relatives];
        arr.splice(index, 0, temp);
        this.setState({
            relatives : arr,
            openIndex: index
        }, () => {
            this.addOccasion(temp,null,null,index)
        })
    }

    handleNext = () => {
        const user = getSessionStorage('userInfo');
        const arr = [];
        this.state.selectedOccasions.forEach(item => {
            const obj = {
                relation : item.relative.id+'-'+item.relative.relation+'-'+item.relative.isMultiple,
                occasion : item.occasion,
                date     : item.date.date,
            }
            if(item.name){ obj.name = item.name }
            if(item.address){ obj.address = item.address.address }
            if(item.gift){ 
                obj.gift = {
                    nid           : item.gift.nid,
                    title         : item.gift.title,
                    price         : item.gift.price,
                    image         : item.gift.image,
                    doubleFlowers : item.doubleFlowers || item.gift.doubleFlowers,
                    addVase       : item.addVase || item.gift.addVase,
                    weightOfCake  : item.weightOfCake || item.gift.weightOfCake,
                    eggless       : item.eggless || item.gift.eggless,
                    messageOnCake : item.messageOnCake || item.gift.messageOnCake,
                    addFlowers    : item.addFlowers || item.gift.addFlowers
                }
            }
            arr.push(obj)
        })

        const data = {
            step : 1,
            id  : user.id,
            user_email : user.user_email,
            data : arr
        }

        this.updateOrderSchedular(data);
    }

    updateOrderSchedular = (data) => {
        this.setState({ fetchingData : true })

        axiosInstance.post(`updateOrderScheduler`, data, {
            headers: {
                'Auth'         : localStorage.getItem('Token'),
                'Content-Type' : 'application/json'
            }
        })
        .then(res => {
            this.setState({ fetchingData : false })
            if(res.data.statusCode===200){
                saveSessionStorage('relatives',this.state.relatives)
                saveSessionStorage('selectedOccasions' , this.state.selectedOccasions)
                let label = 'Next - '
                this.state.selectedOccasions.forEach(item => {
                    label = label+item.relative.relation+' - '
                })
                pushToDataLayer('OnRelationContinue',{},{},{
                    event: "clickEvent",
                    eventCategory: "Schedular",
                    eventAction: "select-relation",
                    eventLabel: label
                })
                this.props.history.push(routerNames.DETAILS,{
                    selectedOccasions : this.state.selectedOccasions
                })
            } else if(res.data.statusCode==401){
                fetchToken("submit",this.handleNext);
            } else {
                toast("Oops Something went wrong, Please try again");
            }
        })
        .catch( e => {
            this.setState({ fetchingData : false })
            toast("Could not process request, Please try again");
        })
    }

    closeSlider = (relative) => {
        let arr = [...this.state.selectedOccasions]
        let ind = -1;
        arr.forEach((item,i) => {
            if(item.relative.relation===relative.relation && item.relative.id===relative.id){
                ind = i
            }
        })
        arr.splice(ind,1)
        this.setState({ selectedOccasions : arr })
    }

    render(){
        let disable = true;
        const relative = this.state.relatives.map((relative,i) => {
            let showOccasion = false;
            let index = null;
            let occasion = null;
            let date = null;
            let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

            this.state.selectedOccasions.forEach((item,ind) => {
                if(relative.relation===item.relative.relation && relative.id===item.relative.id){
                    showOccasion = true
                    occasion = item.occasion
                    date = item.date.date
                    index = ind
                }
                if(!item.relative || !item.occasion || !item.date){
                    disable = true
                } else {
                    disable = false
                }
            })

            const occasions = relative.occasions.map(occ => {
                return(
                    <div
                        key={occ}
                        className={occasion===occ ? 'selected-occasion' : 'occasion'}
                        onClick={() => this.addOccasion(relative,occ,null,i)}
                    >
                        {occ}
                    </div>
                )
            })

            return(
                <div key={relative.relation+"_"+i} className='container relative-row'>
                    <div className='d-flex justify-content-between'>
                        <div className={showOccasion ? 'font-weight-bold' : ''}>{relative.relation}</div>
                        {
                            (showOccasion === false || occasion===null || date===null) ?
                                <div className='add' onClick={() => this.addOccasion(relative,null,null,i)}>+</div>
                            :
                                <div className='tick' onClick={() => this.addOccasion(relative,occasion,date,i)}><img src={tick} alt='tick'/></div>
                        }
                    </div>
                    {
                        showOccasion === true && this.state.openIndex===i ? 
                            <div>
                                <p className='mt-20 occassion-select'>Select Occassion</p>
                                <div className='d-flex overflow-auto scrollbar'>
                                    {occasions}
                                </div>
                                {
                                    occasion !== null && date===null &&
                                    <div>
                                        <SlidingPane
                                            isOpen={occasion !== null && date===null}
                                            from='bottom'
                                            className='slide-pane'
                                            ariaHideApp={false}
                                            onRequestClose={() => this.closeSlider(relative)}
                                        >
                                            <Calendar
                                                className='calendar'
                                                onChange={(date) => this.addOccasion(relative,occasion,date,i)}
                                                value={date===null ? new Date() : date}
                                                minDate={new Date()}
                                            />
                                        </SlidingPane>
                                    </div>
                                }
                            </div> 
                        : null
                    }

                    {
                        date != null &&
                        <div>
                            <div className='d-flex align-items-center mt-30'>
                                <img src={gift} alt='gift'/>
                                <div className='ml-3 mt-1'>{occasion}&nbsp;:</div>
                                <div className='font-weight-bold mt-1'>&nbsp;{date.getDate()}&nbsp;{months[date.getMonth()]},&nbsp;{date.getFullYear()}</div>
                            </div>
                            {
                                this.state.openIndex===i && 
                                <div className='d-flex justify-content-between mt-30'>
                                    <div className='w-75'>Want to send more gifts to {relative.relation}?</div>
                                    <div className='add-more w-25' onClick={() => this.addGift(i+1,relative)}>Add More</div>
                                </div>
                            }
                        </div>
                    }
                    {
                       showOccasion === true && this.state.openIndex===i &&
                       <div className='remove mt-15' onClick={() => this.hideOccasion(index)}>Remove</div> 
                    }          
                </div>
            )
        })

        const length = this.state.selectedOccasions.length

        return(
            <div ref={ref => this.el = ref}>
                <LoadingCircle load={this.state.fetchingData}/>
                <ToastContainer />
                <StepOne
                    relative={relative}
                    length={length}
                    disable={disable}
                    handleNext={this.handleNext}
                    selectedOccasions={this.state.selectedOccasions}
                />
            </div>
        )
    }
}

const StepOne = (props) => {
    const disable = props.disable
    return(
        <React.Fragment>
            <div className='mb-70'>
                { props.relative }
            </div>
            <div 
                id='bottom-btn'
                className={
                    !disable ? "align-items-center d-flex pl-3 pr-4 fixed-bottom fixed-button w-100"
                    : "fixed-bottom fixed-button w-100"
                }
            >
                { !disable && props.length > 0 &&<div className='text-center w-50'>{props.length} {props.length > 1 ? 'Deliveries' : 'Delivery'} Selected</div> }

                <div className='h-100 position-relative m-auto w-80'>
                    <button disabled={disable} onClick={props.handleNext} className={!disable ? 'btn-next btn-next-small w-100' : 'btn-next btn-next-disable w-100'}>Next</button>
                </div>
            </div>
        </React.Fragment>
    )
}

export default withRouter(Relative);