import React, { Component } from 'react';
import { routerNames } from '../../../config/config';
import Layout from '../../../components/Layout/container';
import Headline from '../../UserForm/components/Headline';
import Occasions from './Occasions';;

class SelectRelation extends Component {
    render(){
        return(
            <React.Fragment>
                <Layout serenade={true}/>
                <Headline 
                    title='Plan a week full of love'
                    subtitle='Choose your event for gifting, You can choose multiple'
                />
                <Occasions/>
            </React.Fragment>
        )
    }
}

export default SelectRelation;