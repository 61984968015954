export default {
    'OnPageLoad': {
      event: "clickEvent",
      eventCategory: "Schedular",
      eventAction: "Home",
      eventLabel: "Home"
    },
    'OnHomePageContinue': {
      event: "clickEvent",
      eventCategory: "Schedular",
      eventAction: "Home",
      eventLabel: "Continue"
    },
    'OnNameContinue': {
      event: "clickEvent",
      eventCategory: "Schedular",
      eventAction: "details",
      eventLabel: "Next"
    },
    'OnChooseGift': {
      event: "clickEvent",
      eventCategory: "Schedular",
      eventAction: "delivery-details",
      eventLabel: "Choose gift"
    },
    'OnAddGift': {
      event: "clickEvent",
      eventCategory: "Schedular",
      eventAction: "product-details",
      eventLabel: "Add"
    },
    'OnPlaceOrderContinue': {
      event: "clickEvent",
      eventCategory: "Schedular",
      eventAction: "direct-payment",
      eventLabel: "Next"
    },
    'OnOrderConfirmationPageLoad': {
      event: "clickEvent",
      eventCategory: "Schedular",
      eventAction: "Order Confirmation",
      eventLabel: "Success"
    },
    'OnClick': {
      event: "clickEvent",
      eventCategory: "<%-category%>",
      eventAction: "<%-action%>",
      eventLabel: "<%-label%>"
    },
    
  }
  