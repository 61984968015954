import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

const Banner = (props) => {
    return(
        <Carousel
            showArrows={false}
            showStatus={false}
            showThumbs={false}
            autoPlay
            infiniteLoop
        >
            {
                props.images && props.images.map((item,index) => {
                    return <div key={index}><img src={item} alt='pic'/></div>
                })
            }
        </Carousel>
    )
}

export default Banner
