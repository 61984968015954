import React, { Component } from 'react';
import { routerNames } from '../../../config/config';
import Layout from '../../../components/Layout/container';
import Headline from '../../Information/components/Headline';
import DetailsForm from './DetailsForm';
import { getSessionStorage } from '../../../helpers';

class RelativeDetailsForm extends Component {
    render(){
        const state = this.props.location.state
        const selectedOccasions = state ? state.selectedOccasions : getSessionStorage('selectedOccasions')
        return(
            <div className='height-full'>
                <Layout page={routerNames.SELECT_RELATION}/>
                <Headline title="Please enter receiver's name"/>
                <DetailsForm selectedOccasions={selectedOccasions}/>
            </div>
        )
    }
}

export default RelativeDetailsForm;