import React, { Component } from 'react';
import { routerNames } from '../../../config/config';
import Layout from '../../../components/Layout/container';
import Headline from '../../Information/components/Headline';
import AddressForm from './AddressForm';
import { getSessionStorage } from '../../../helpers';
import './index.css';

class DeliveryAddress extends Component {
    render(){
        const state = this.props.location.state
        const selectedOccasions = state ? state.selectedOccasions : getSessionStorage('selectedOccasions')
        return(
            <div className='height-full'>
                <Layout page={routerNames.DETAILS}/>
                <Headline title="Please enter receiver's address"/>
                <AddressForm selectedOccasions={selectedOccasions}/>
            </div>
        )
    }
}

export default DeliveryAddress;