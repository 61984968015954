import React, { Component } from 'react';
import { BASE_URL, routerNames } from '../../../config/config';
import Headroom from 'react-headroom';
import { withRouter } from 'react-router-dom'
import "./index.css";
import Logo from "./images/logo.svg";
import back from "./images/back.svg";
import {GetParams} from '../../../helpers/URLParms'

class Header extends Component {
    handleClickHeader = () => {
        window.location.replace('https://www.floweraura.com/valentine')
    }
    redirect = () => {
        const home = this.props.serenade ? routerNames.OCCASION_LIST : routerNames.HOME;
        let pageURL = this.props.page;
        if(pageURL === home){
            pageURL = (this.props.serenade ? routerNames.OCCASION_LIST : routerNames.HOME);
            this.props.history.push(pageURL)
        }else{
            this.props.history.push(this.props.page)
        }
    }
	render() {
        const fa_source = GetParams("fa_source", this.props.location.search)
        const page = this.props.match.path;
        const home = this.props.serenade ? routerNames.OCCASION_LIST : routerNames.HOME;
        const order_placed = this.props.serenade ? routerNames.ORDER_PLACED_SERENADE+'/:orderId' : routerNames.ORDER_PLACED+'/orderId';
		return (
            fa_source !== 'app' ? 
            <Headroom style={{zIndex: 99}}>
            	<header>
                    <div className="headerTop">
                        <div className='container'>
                            {(page===home || page===order_placed || page===routerNames.PAYMENT_DETAILS_SCHEDULER || page===routerNames.PAYMENT_DETAILS_SERENADE) ? null :
                                <div onClick={() => this.redirect()} className='float-left mt-20'>
                                    <img src={back} alt="back" />
                                </div>
                            }
                            <div className="logo_main_r d-md-flex d-block flex-row mx-md-auto mx-0">
                                <a className='navbar-brand' onClick={this.handleClickHeader}>
                                    <img src="https://imgcdn.floweraura.com/fa-logo/final-23-desk.svg" alt="Floweraura" />
                                </a>
                            </div>
                            <div className="header-right">
                                    <ul>
                                        <li className="need-help">
                                            <a className="desktop-view" href={BASE_URL+'/faq'}> Need Help </a>
                                            <a className="mobile-view" href="tel:+91-9650062220"> Need Help </a>
                                        </li>
                                    </ul>
                                </div>
                        </div>
                    </div>
            	</header>
            </Headroom>
            : null
		);
	}
}

export default withRouter(Header);