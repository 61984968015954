import LayoutComponent from '../components';
import { connect } from 'react-redux';


const mapDispatchToProps = dispatch => {
    return {
    	
    }
}

const mapStateToProps = state => {
	return {

	};
}

export default connect(mapStateToProps, mapDispatchToProps)(LayoutComponent)
