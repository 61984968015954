import ES6Promise from 'es6-promise';
import { BASE_URL, API_BASE_URL, MENU_LIST_API, PRODUCT_DETAILS_API, COUPON_CODE_API, MENU_LIST_API_SERENADE, CHECK_PIN_CODE, LARAVEL_API_URL } from './config';
import axios from 'axios';
ES6Promise.polyfill();

export const defaultAxiosInstance = axios.create({
    baseURL: BASE_URL
});

export const axiosInstance = axios.create({
    baseURL: API_BASE_URL
});

export const axiosInstanceMenu = axios.create({
    baseURL: MENU_LIST_API
});

export const axiosInstanceMenuSerenade = axios.create({
    baseURL: MENU_LIST_API_SERENADE
});

export const axiosInstanceProductDetails = axios.create({
    baseURL: PRODUCT_DETAILS_API
});

export const axiosInstanceAddToCart = axios.create({
    baseURL: BASE_URL
});

export const axiosInstancePincode = axios.create({
    baseURL: BASE_URL
});

export const axiosInstanceCoupon = axios.create({
    baseURL: COUPON_CODE_API,
});

export const axiosInstanceGetCity = axios.create({
    baseURL: CHECK_PIN_CODE,
});

export const axiosLaravelInstance = axios.create({
    baseURL: LARAVEL_API_URL,
});
