import React from 'react';
import './index.css';
import '../../../index.css';

const AddGift = (props) => {
    return(
        <div id = 'bottom-btn' className="fixed-bottom fixed-button w-100">
            <div className='h-100 position-relative m-auto w-80'>
                <button onClick={props.addGift} className='btn-next w-100 p-10'>ADD&nbsp;GIFT</button>
            </div>
        </div>
    )
}

export default AddGift;