import { axiosInstance } from '../config/axiosInstance';
import { HeaderOprKey } from '../config/config';
import { toast } from 'react-toastify';
import { host } from '../config/config';

export const isEmpty = value =>
  value === undefined ||
  value === null ||
  (typeof value === 'object' && Object.keys(value).length === 0) ||
  (typeof value === 'string' && value.trim().length === 0);

export const ucFirst = value => {
  return value[0].toUpperCase() + value.slice(1);
}

export const saveSessionStorage = (key,value) => {
  sessionStorage.setItem(key,JSON.stringify(value));
}

export const getSessionStorage = (key) => {
  return JSON.parse(sessionStorage.getItem(key));
}

export const removeSessionStorage = (key) => {
  sessionStorage.removeItem(key);
}

export const fetchToken = (pageName,callBack) => {
  axiosInstance.get(`tokenGenerate`, {
      headers: {
          'Headeroprkey': HeaderOprKey,
      },
  })
  .then(res => {
      if(res.data && res.data.token){
          localStorage.setItem("Token", res.data.token);
          if(pageName==='relatives'||pageName==='paymentDone'){
            window.location.reload();
          }
          else if(pageName='submit'){
            callBack();
          }
      } else {
          toast("Oops, Something went wrong please refresh...");
      }
  })
  .catch( e => {
      toast("Oops, Something went wrong please try again...");
  })
}

export const isProduction = () => {
  const HOST_NAME = host.HOST_NAME;
  const DOMAIN = process.env.REACT_APP_DOMAIN;
  const DOMAIN_LIST = DOMAIN.split("|");
  if (DOMAIN_LIST.includes(HOST_NAME)) {
    return true;
  }
  return false;
}

export const getCookie = function(cname){
  var name = cname + "=";
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ')
        c = c.substring(1);
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
  }
  return "";
}

export const WithOutClass = (props) => props.children;