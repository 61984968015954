import React, { Component } from 'react';
import Layout from '../../../components/Layout/container';
import UserForm from './UserForm';
import Headline from './Headline';
import './index.css';
import { withRouter } from 'react-router-dom';
import { routerNames } from '../../../config/config';

class Information extends Component {

    render() {
        return (
            <div className='height-full'>
                <Layout/>
                <Headline title='Hey, Let us know you better!'/>
                <UserForm/>
            </div>
        )
    }
}

export default withRouter(Information)
