import React from 'react';
import './index.css';

export const Navbar = (props) => {
    return(
        <div className='p-3'>
            <ul className='align-items-center d-flex justify-content-between list nav-items m-0'>
                <li 
                    onClick={() => props.handleMenuList('flower')} 
                    className={props.category === 'flower' ? 'selected-item' : ''}>Flowers
                </li>
                <li 
                    onClick={() => props.handleMenuList('cake')} 
                    className={props.category === 'cake' ? 'selected-item' : ''}>Cakes
                </li>
                <li 
                    onClick={() => props.handleMenuList('combo')} 
                    className={props.category === 'combo' ? 'selected-item' : ''}>Combos
                </li>
                {/* <li 
                    onClick={() => props.handleMenuList('gift')} 
                    className={props.category === 'gift' ? 'selected-item' : ''}>Gifts
                </li> */}
                <li 
                    onClick={() => props.handleMenuList('plant')} 
                    className={props.category === 'plant' ? 'selected-item' : ''}>Plants
                </li>
            </ul>
        </div>
    )
} 