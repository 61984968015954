import React, { Component } from 'react';
import Layout from '../../../components/Layout/container';
import ReceiverForm from './ReceiverForm';
import Headline from './Headline';
import './index.css';
import { withRouter } from 'react-router-dom';
import { routerNames } from '../../../config/config';

class ReceiverInfo extends Component {
    render() {
        return (
            <div className='parent-div'>
                <Layout page={routerNames.USER_INFO_SERENADE} serenade={true}/>
                <Headline 
                    title='Whom do you want to deliver ?'
                    subtitle="Add Receiver's details"
                />
                <ReceiverForm/>
            </div>
        )
    }
}

export default withRouter(ReceiverInfo)
