import { GetParams } from '../helpers/URLParms'
const customUrl = () => (window.location.hostname === 'localhost') ? 'https://www.bakewish.in' : window.location.origin;
const laravelCustomUrl = () => (window.location.hostname !== 'www.floweraura.com') ? 'https://bakewishapis.bakewish.in' : 'https://apis.floweraura.com';
const URL = customUrl();
const LARAVELURL = laravelCustomUrl();

export const BASE_URL               =  URL;
export const API_BASE_URL           = `https://opr.floweraura.com/api/Order_Scheduler/`;
// export const API_BASE_URL           = `https://devoprnew.bakewish.in/api/Order_Scheduler/`;
export const CART_URL               = `${URL}/cart`;
export const HOST_NAME              =  window.location.hostname;
export const HeaderOprKey           = 'FrdfHSFsdetrESRSDDCSDg9ytrewtwerR';
export const COUPON_CODE_API        =  LARAVELURL+'/api/floweraura/coupon/check';
export const LARAVEL_API_URL        =  LARAVELURL+'/api/floweraura/';
export const MENU_LIST_API_SERENADE =  'https://apis.floweraura.com/api/floweraura/';
export const PRODUCT_DETAILS_API    =  URL+'/api/v1/product/';
export const MENU_LIST_API          =  URL+'/api/v1/gift_scheduler_products/';
export const CHECK_PIN_CODE         =  URL+'/fac_mgmt/get_city_state_by_pincode';

const fa_source = GetParams("fa_source", window.location.search)
 export const routerNames = {

    //gift scheduler routes

    HOME                      : '/gifts/scheduler',
    SELECT_RELATION           : '/gifts/scheduler/select-relation',
    DETAILS                   : '/gifts/scheduler/details',
    DELIVERY_ADDRESS          : '/gifts/scheduler/delivery-address',
    DELIVERY_DETAILS          : '/gifts/scheduler/delivery-details',
    MENU                      : '/gifts/scheduler/menu',
    PRODUCT_DETAILS           : '/gifts/scheduler/product-details',
    ORDER_PLACED              : '/gifts/scheduler/order-placed',
    COMING_SOON               : '/gifts/scheduler/coming-soon',
    PAYMENT_DETAILS_SCHEDULER : '/gifts/scheduler/direct-payment',
 
    //serenade routes 

    USER_INFO_SERENADE       : fa_source === 'app' ? '/serenade/valentine/sender-info?fa_source='+fa_source : '/serenade/valentine/sender-info',
    OCCASION_LIST            : fa_source === 'app' ? '/serenade/valentine?fa_source='+fa_source : '/serenade/valentine',
    RECEIVER_NAME            : fa_source === 'app' ? '/serenade/valentine/receiver-info?fa_source='+fa_source : '/serenade/valentine/receiver-info',
    //RECEIVER_ADDRESS         : '/serenade/valentine/receiver-address',
    CHOOSE_GIFTS_PAGE        : fa_source === 'app' ? '/serenade/valentine/choose-gifts?fa_source='+fa_source : '/serenade/valentine/choose-gifts',
    SERENADE_MENU            : fa_source === 'app' ? '/serenade/valentine/menu?fa_source='+fa_source : '/serenade/valentine/menu',
    SERENADE_PRODUCT_DETAILS : '/serenade/valentine/product-details',
    ORDER_PLACED_SERENADE    : '/serenade/valentine/order-placed',
    PAYMENT_DETAILS_SERENADE : '/serenade/valentine/direct-payment',
}

export const host =  {
    HOST_NAME: window.location.hostname,
};

 